import React from "react";

export default function ResourcesNavbar() {
  return (
    <div className="border-b-2 border-gray-200 pb-2">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 ">
          <div className="px-4 py-2 rounded-t-2xl cursor-pointer transition-all duration-500 bg-secondary text-white">
            Resources
          </div>
        </div>
        {/* <div>
              <div className='flex gap-2 items-center cursor-pointer' onClick={() => setShowModal(true)}>
                <button>Add New</button>
                <FaPlus />
              </div>
            </div> */}
      </div>
    </div>
  );
}
