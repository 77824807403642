import React, { useEffect, useState } from "react";
import {
  FaEyeDropper,
  FaHandHoldingHeart,
  FaMedapps,
  FaRegClock,
} from "react-icons/fa";
import matureLogo from "../../assets/mature.png";

import existing_icon from "../../assets/existing_icon.png";
import idea_icon from "../../assets/idea_icon.png";

export default function Deadline() {
  const deadline = new Date("2023-07-15");
  const calculateRemainingTime = () => {
    const currentTime = new Date().getTime();
    const difference = deadline.getTime() - currentTime;

    if (difference <= 0) {
      // Countdown is over
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="border-b border-b-gray-300">
      <div className="max-w-7xl mx-auto py-10  grid grid-cols-6 gap-6 md:gap-10 items-center px-4">
        <div className="col-span-6 md:col-span-4">
          <p className="text-justify">
            How can we enhance the competitiveness of the Bangladesh RMG
            industry through scalable innovations and socially beneficial models
            which enhance the employability of women garment workers and
            accelerate the transition to a circular economy?
          </p>
        </div>
        <div className="col-span-3 md:col-span-1">
          <div className="flex flex-col">
            <div className="flex flex-col justify-center gap-2 items-center">
              <img src={idea_icon} alt="mature logo" className="w-12 h-12" />
              <p className="font-bold">Idea-Stage Solution</p>
              <p>Closed</p>
            </div>
          </div>
        </div>
        <div className="col-span-3 md:col-span-1">
          <div className="flex flex-col">
            <div className="flex flex-col justify-center gap-2 items-center">
              <img
                src={existing_icon}
                alt="mature logo"
                className="w-12 h-12"
              />
              <p className="font-bold">Existing Solution</p>
              <p>Open now</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
