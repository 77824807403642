import React from "react";
import join2 from "../../../assets/join2.png";

export default function JoinPhaseTwo() {
  return (
    <div className="flex flex-col gap-4 justify-center items-center">
      <img src={join2} alt="" />
      <p className="text-center text-[16px]">
        Receive up to BDT 650,000 in pollination grants to implement your
        impactful and viable business models.
      </p>
    </div>
  );
}
