import React from "react";
import Resources from "../Resources/Resources";
import MatureLayout from "../../shared/MatureLayout";

export default function MatureResources() {
  return (
    <MatureLayout>
      <Resources />
    </MatureLayout>
  );
}
