import React from "react";
import AdminDetails from "./Components/AdminDetails";
import AdminLayout from "../../../shared/AdminLayout";

export default function Admin() {
  return (
    <AdminLayout>
      <AdminDetails />
    </AdminLayout>
  );
}
