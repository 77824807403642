import React, { useEffect, useState } from "react";
import { FaCaretRight } from "react-icons/fa";
import { useFirestore } from "../../../hooks/useFirestore";

export default function ApplicationAdvancedSolution({
  setDetails,
  details,
  setPage,
}) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { addDocument, updateDocument, response } =
    useFirestore("entrepreneur");

  useEffect(() => {
    if (
      response.success &&
      details?.basicInfo?.fullName !== "" &&
      details?.basicInfo?.gender !== "" &&
      details?.basicInfo?.phoneNumber !== "" &&
      details?.basicInfo?.age !== ""
    ) {
      setPage(2);
    }
  }, [response.success]);

  const handleSave = () => {
    if (!response.isLoading) {
      updateDocument(details?.id, details);
      setSuccess("Your data saved Successfully");
      setError("");
    }
  };

  const handleNext = () => {
    if (
      details?.basicInfo?.fullName !== "" &&
      details?.basicInfo?.gender !== "" &&
      details?.basicInfo?.phoneNumber !== "" &&
      details?.basicInfo?.age !== "" &&
      !response.isLoading
    ) {
      updateDocument(details?.id, details);
    } else {
      setError("Please Fill all the required Field");
      setSuccess("");
    }
  };

  return (
    <div className="py-10 flex flex-col gap-8 z-10">
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label for="fCChallenge" className="font-bold">
            Current Challenges: Please describe one significant barrier that is
            stopping your solution work. (50 maximum word count) <span>*</span>
          </label>
          <textarea
            type="text"
            name="fCChallenge"
            id="fCChallenge"
            placeholder=""
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none h-[150px]"
            value={details?.advancingSolution?.currentChallenges}
            onChange={(e) =>
              setDetails({
                ...details,
                advancingSolution: {
                  ...details?.advancingSolution,
                  currentChallenges: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label for="fCChallenge" className="font-bold">
            Overcoming Challenges: Please describe one important action that
            needs to happen in the next three years to overcome the barrier you
            described above. (100 words max) <span>*</span>
          </label>
          <textarea
            type="text"
            name="fCChallenge"
            id="fCChallenge"
            placeholder=""
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none h-[150px]"
            value={details?.advancingSolution?.overcomingChallenges}
            onChange={(e) =>
              setDetails({
                ...details,
                advancingSolution: {
                  ...details?.advancingSolution,
                  overcomingChallenges: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-4">
          <label className="font-bold">
            Partners: Who are the partners that you most need to help take the
            action you described above (in the next three years)? Select all
            that apply. <span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <label>
              <input
                type="radio"
                name="focus"
                value="Investors"
                checked={details?.advancingSolution?.partners === "Investors"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      partners: e.target.value,
                    },
                  })
                }
              />{" "}
              Investors
            </label>
            <label>
              <input
                type="radio"
                name="focus"
                value="Private sector businesses"
                checked={
                  details?.advancingSolution?.partners ===
                  "Private sector businesses"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      partners: e.target.value,
                    },
                  })
                }
              />{" "}
              Private sector businesses
            </label>
            <label>
              <input
                type="radio"
                name="focus"
                value="Researchers / experts"
                checked={
                  details?.advancingSolution?.partners ===
                  "Researchers / experts"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      partners: e.target.value,
                    },
                  })
                }
              />{" "}
              Researchers / experts
            </label>
            <label>
              <input
                type="radio"
                name="focus"
                value="Government officials"
                checked={
                  details?.advancingSolution?.partners ===
                  "Government officials"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      partners: e.target.value,
                    },
                  })
                }
              />{" "}
              Government officials
            </label>
            <label>
              <input
                type="radio"
                name="focus"
                value="Regulators"
                checked={details?.advancingSolution?.partners === "Regulators"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      partners: e.target.value,
                    },
                  })
                }
              />{" "}
              Regulators
            </label>
            <label>
              <input
                type="radio"
                name="focus"
                value="Consumers"
                checked={details?.advancingSolution?.partners === "Consumers"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      partners: e.target.value,
                    },
                  })
                }
              />{" "}
              Consumers
            </label>
            <label>
              <input
                type="radio"
                name="focus"
                value="Distributors"
                checked={
                  details?.advancingSolution?.partners === "Distributors"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      partners: e.target.value,
                    },
                  })
                }
              />{" "}
              Distributors
            </label>
            <label>
              <input
                type="radio"
                name="focus"
                value="Manufacturers"
                checked={
                  details?.advancingSolution?.partners === "Manufacturers"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      partners: e.target.value,
                    },
                  })
                }
              />{" "}
              Manufacturers
            </label>
            <label>
              <input
                type="radio"
                name="focus"
                value="Marketers"
                checked={details?.advancingSolution?.partners === "Marketers"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      partners: e.target.value,
                    },
                  })
                }
              />{" "}
              Marketers
            </label>
            <label>
              <input
                type="radio"
                name="focus"
                value="Media"
                checked={details?.advancingSolution?.partners === "Media"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      partners: e.target.value,
                    },
                  })
                }
              />{" "}
              Media
            </label>
            <label>
              <input
                type="radio"
                name="focus"
                value="Other"
                checked={details?.advancingSolution?.partners === "Other"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      partners: e.target.value,
                    },
                  })
                }
              />{" "}
              Other
            </label>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-4">
          <label className="font-bold">
            What could being part of the Innovation Challenge unlock for you to
            advance your solution towards impact? Check all that apply:{" "}
            <span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <label>
              <input
                type="radio"
                name="challengeUnlock"
                value="Access to tailored mentorship and expertise"
                checked={
                  details?.advancingSolution?.challengeUnlock ===
                  "Access to tailored mentorship and expertise"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      challengeUnlock: e.target.value,
                    },
                  })
                }
              />{" "}
              What could being part of the Innovation Challenge unlock for you
              to advance your solution towards impact? Check all that apply:
            </label>
            <label>
              <input
                type="radio"
                name="challengeUnlock"
                value="Access to others with similar solution types (e.g., digital technology, financial innovation, behavior change)"
                checked={
                  details?.advancingSolution?.challengeUnlock ===
                  "Access to others with similar solution types (e.g., digital technology, financial innovation, behavior change)"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      challengeUnlock: e.target.value,
                    },
                  })
                }
              />{" "}
              Access to others with similar solution types (e.g., digital
              technology, financial innovation, behavior change)
            </label>
            <label>
              <input
                type="radio"
                name="challengeUnlock"
                value="Access to systems thinking curricula, tools, and experts"
                checked={
                  details?.advancingSolution?.challengeUnlock ===
                  "Access to systems thinking curricula, tools, and experts"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      challengeUnlock: e.target.value,
                    },
                  })
                }
              />{" "}
              Access to systems thinking curricula, tools, and experts
            </label>
            <label>
              <input
                type="radio"
                name="challengeUnlock"
                value="Access to prospective investors and implementing partners like national and city-based governments, RMG Factories, etc."
                checked={
                  details?.advancingSolution?.challengeUnlock ===
                  "Access to prospective investors and implementing partners like national and city-based governments, RMG Factories, etc."
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      challengeUnlock: e.target.value,
                    },
                  })
                }
              />{" "}
              Access to prospective investors and implementing partners like
              national and city-based governments, RMG Factories, etc.
            </label>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-4">
          <label className="font-bold">
            Access to prospective investors and implementing partners like
            national and city-based governments, RMG Factories, etc.{" "}
            <span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <label>
              <input
                type="radio"
                name="participateAccelerators"
                value="Yes"
                checked={
                  details?.advancingSolution?.participateAccelerators === "Yes"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      participateAccelerators: e.target.value,
                    },
                  })
                }
              />{" "}
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="participateAccelerators"
                value="No"
                checked={
                  details?.advancingSolution?.participateAccelerators === "No"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    advancingSolution: {
                      ...details?.advancingSolution,
                      participateAccelerators: e.target.value,
                    },
                  })
                }
              />{" "}
              No
            </label>
          </div>
        </div>
      </div>
      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}
      <div className="flex justify-between">
        <div
          className="flex gap-2 items-center justify-center"
          onClick={handleSave}
        >
          <p>Save</p>
          <FaCaretRight />
        </div>
        <div
          className="flex gap-2 items-center justify-center"
          onClick={handleNext}
        >
          <p>Submit</p>
          <FaCaretRight />
        </div>
      </div>
    </div>
  );
}
