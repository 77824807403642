import React, { useEffect, useState } from "react";
import Heading from "../../../shared/Heading";
import NewsEventsNavigation from "./NewsEventsNavigation";
import { newsAndEventsData } from "../data";
import { FaCaretRight } from "react-icons/fa";
import { motion } from "framer-motion";
import { useCollection } from "../../../hooks/useCollection";
import { Link } from "react-router-dom";

export default function AllNewsAndEvents() {
  let [newsAndEvents, setNewsAndEvents] = useState([]);
  let [category, setCategory] = useState("");

  const { document, isLoading } = useCollection("blogs");
  const { document: caseStudy } = useCollection("case_study");

  useEffect(() => {
    if (category === "") {
      setNewsAndEvents(document);
    } else {
      let filteredNews = document?.filter(
        (news) => news?.category === category
      );
      setNewsAndEvents(filteredNews);
    }
  }, [category, document]);
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className=" py-20"
    >
      <Heading firstHeading="News and" secondHeading=" Events" />
      <div className="pt-20 px-4">
        <div className="max-w-5xl mx-auto">
          <NewsEventsNavigation setCategory={setCategory} category={category} />
          {category !== "Case Study" && !newsAndEvents?.length && (
            <p className="pt-8 text-center">
              Sorry, but there are currently no data to share at this time.
              Please check back later for any new updates.
            </p>
          )}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 pt-20">
            {category !== "Case Study" &&
              newsAndEvents
                ?.sort((a, b) => b?.createdAt - a?.createdAt)
                ?.map((news) => (
                  <div>
                    <div className="mb-4">
                      <img src={news?.image} alt={news?.title} />
                    </div>
                    <div>
                      <p>
                        {news?.createdAt.toDate().toISOString().split("T")[0]}
                      </p>
                      <h1 className="text-2xl py-2">{news?.title}</h1>
                      <div className="flex items-center gap-2 pb-2">
                        {news?.tags?.map((tag) => (
                          <p className="border border-gray-500 text-xs px-4 py-1 rounded-full">
                            {tag}
                          </p>
                        ))}
                      </div>
                      <div>
                        <Link to={`/news-events/${news?.id}`}>
                          <button className="py-2 text-black rounded-tl-xl rounded-br-xl cursor-pointer  flex justify-center items-center font-bold">
                            READ MORE
                            <FaCaretRight />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          {category === "Case Study" && (
            <div>
              {/* <p>Case study will be available soon!</p> */}
              {caseStudy?.map((caseStudy, id) => {
                if (id % 2 === 0) {
                  return (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center  mt-20">
                      <div>
                        <img
                          src={caseStudy?.coverImage}
                          alt="Innovation 1"
                          className="rounded-2xl border-4 border-black"
                        />
                      </div>
                      <div className="col-span-2">
                        <h2 className="text-2xl font-bold pb-4">
                          {caseStudy?.title}
                        </h2>
                        <p className="pb-2">{caseStudy?.descripion}</p>
                        <a href={caseStudy?.pdf} target="_blank">
                          <div className="flex items-center">
                            <p className="font-bold">DOWNLOAD THE CASE STUDY</p>
                            <FaCaretRight />
                          </div>
                        </a>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center mt-20">
                      <div className="col-span-2">
                        <h2 className="text-2xl font-bold pb-4">
                          {caseStudy?.title}
                        </h2>
                        <p className="pb-2">{caseStudy?.descripion}</p>
                        <a href={caseStudy?.pdf} target="_blank">
                          <div className="flex items-center">
                            <p className="font-bold">DOWNLOAD THE CASE STUDY</p>
                            <FaCaretRight />
                          </div>
                        </a>
                      </div>
                      <div>
                        <img
                          src={caseStudy?.coverImage}
                          alt="Innovation 1"
                          className="rounded-2xl border-4 border-black"
                        />
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}
// Sorry, but there are currently no news articles or updates to
// share at this time. Please check back later for any new updates.
