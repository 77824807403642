import solution1 from "../../assets/solution1.png";
import solution2 from "../../assets/solution2.png";
import solution3 from "../../assets/solution3.png";
import solution4 from "../../assets/solution4.png";
import solution5 from "../../assets/solution5.png";
import solution6 from "../../assets/solution6.png";

export const solutionData = [
  {
    id: 1,
    img: solution1,
    title: "Material Innovation",
    question: "How can we introduce alternative materials into the RMG production to reduce cotton dependency, lower environmental impact and create alternative job options for women garment workers?",
    description: `Develop sustainable and new eco-friendly materials that serve as viable alternatives to traditional fabric or develop more efficient manufacturing methods that can be used to scale the usage of these new materials in the industry taking into account specificities of garment production processes. `,
    example: "Example of startups bringing eco-friendly material innovation:  ",
    links: {
      "BOLT THREADS": "https://boltthreads.com/",
      "ANANAS ANAM": "https://www.ananas-anam.com/",
      "GREEN NETTLE TEXTILE": "https://greennettletextiles.com/",
      "ALGAEING": "https://www.algaeing.com/",
      "ALT TEX": "https://thealttex.com/",
      "ORANGE FIBER": "http://www.orangefiber.it/",
      "RETHREAD AFRICA": "https://rethread.africa/"
    }
  },
  {
    id: 2,
    img: solution2,
    title: "Environment and Circular solution",
    question: "What innovative circular solutions can be proposed to effectively reduce the carbon footprint linked to energy usage, waste management, packaging, and water treatment of the RMG industry?",
    description: ` This initiative is centered around the exploration of sustainable and circular solutions that minimize the carbon footprint linked to energy usage, waste management, packaging, and water treatment. The primary focus is on finding solutions in several areas such as improved energy efficiency, improved water recovery, reduced discharge of chemical effluents, reduced generation of fabric waste, alternatives to single use plastics, just to name a few.`,
    example: "Example of startups building innovative environment and circular solutions:  ",
    links: {
      "CIRCULAR SYSTEMS": "http://www.circularsystems.com",
      "EVRNU": "http://www.evrnu.com",
      "BIORESTORE": "https://www.biorestore.eu/",
      "DYERECYCLE": "https://www.dyerecycle.com/",
      "IONCELL": "http://www.ioncell.fi/",
      "TEREFORM": "https://www.tereform.com/",
      "EON": "https://www.eongroup.co/"
    }
  },
  {
    id: 3,
    img: solution3,
    title: "Consumer and retail business models",
    question: "How can we explore innovative, customer-centric approaches in the consumer and retail domain that can enhance the overall experience and drive success in the RMG Industry?",
    description:
      `This area of focus centers around the development of innovative consumer and retail business models that encompass aspects such as pricing strategies, distribution channels, marketing tactics, and customer engagement. It also involves understanding consumer behavior, market trends, and designing retail experiences that drive sales. Women RMG workers in Bangladesh can contribute to product design, tailoring for diverse customer segments, quality control, inventory management, customer service, and even micro-entrepreneurship.  `,
    example: "Example of startups working with consumer and retail business models: ",
    links: {
      "Flyrobe": "http://www.flyrobe.com",
      "Twirl Store": "http://www.twirl.store"

    }
  },
  {
    id: 4,
    img: solution4,
    title: "Dyeing and Finishing",
    question: "How can we introduce sustainable eco-friendly practices in dyeing and finishing of the garments to reduce usage of hazardous toxic chemicals and its discharge into the environment?  ",
    description:
      `This solution area is centered around bringing innovative solutions in dyeing and finishing that can include but not limited to, Using eco-friendly dyes such as plant-based or non-toxic synthetic dyes; Implementing water-saving technologies and techniques, such as water recycling and reuse systems, to minimize water consumption; Utilizing energy-efficient equipment and technologies to reduce energy consumption and lower carbon footprint; Digital printing techniques, which require less water and energy; and Chemical management.`,
    example: "Example of startups building innovative dyeing and finishing solutions: ",
    links: {
      "KBCOLS SCIENCES": "https://www.kbcolssciences.com/",
      "ColoriFix": "http://www.colorifix.com",
      "CleanDye": "http://www.cleandye.com",
      "DYERECYCLE": "https://www.dyerecycle.com/"

    },
  },
  {
    id: 5,
    img: solution5,
    title: "Cross sector and/or CFC-based business model",
    question: "How can Common Facility Center (CFC) based business models leverage the abilities and knowledge of women garments workers across various sectors? ",
    description:
      `This approach combines elements or expertise from different industries or sectors to create innovative solutions or opportunities. In terms of women workers' skills, a cross-sector and/or Common Facility Center (CFC) based business models can involve leveraging their abilities and knowledge across various sectors. Women workers’ skills can be utilized in sectors such as textile manufacturing, fashion retail, apparel sourcing, home textiles, artisanal crafts, sustainable fashion initiatives or accessories production. By applying their expertise, women workers can contribute to the development of new products, improved production processes, or create synergies between industries through their attention to detail, craftsmanship, and understanding of modern customer preferences. `,
    example: "Example of startups building cross sector and/or CFC-based business models: ",
    links: {
      "REPACK": "http://www.repack.com",
      "THREDUP": "http://www.thredup.com"
    }
  },
  {
    id: 6,
    img: solution6,
    title: "Better process and Assistive technologies",
    question: "How can better process and assistive technologies focus on helping the women RMG workers to overcome barriers, improve productivity, and enhance their overall well-being in the workplace?",
    description: `'Better Process’ focuses on optimizing workflows, increasing efficiency, and minimizing waste. This can be achieved by incorporating advanced technologies, modular/digital solutions for transparency & traceability across the value chain automation or adopting lean manufacturing principles. ‘Assistive technology’ refers to devices, tools, or systems designed to assist individuals with disabilities or limitations in performing various tasks or accelerating workers’ efficiency. This context may include specialized sewing machines or tools accommodating different physical abilities, ergonomic workstations, assistive software, wearable devices or robotic arms that aid in communication, mobility or speedy execution of tasks. `,
    example: "Example of startups bringing better process & technologies: ",
    links: {
      "SEWBO ": "http://www.sewbo.com",
      "SXD": "https://www.sxd-ai.com/",
      "SYNFLUX": "https://synflux.io/",
      "REVERSE RESOURCES": "http://www.reverseresources.net/",
      "TEXTILE GENESIS": "https://textilegenesis.com/",
      "UNSPUN": "https://unspun.io/",
      "BIGTHINX": "http://www.bigthinx.com"
    }
  },
];
