import React from "react";
import Layout from "../../shared/Layout";
import ChallengeBackground from "./Components/ChallengeBackground";
import ScrollToTop from "../../shared/utils/ScrollToTop";

export default function ChallengeBg() {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <ChallengeBackground />
      </Layout>
    </>
  );
}
