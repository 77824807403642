import React from "react";
import MatureLayout from "../../shared/MatureLayout";
import CaseStudyDetails from "../caseStudy/Components/CaseStudyDetails";

export default function MatureCaseStudy() {
  return (
    <MatureLayout>
      <CaseStudyDetails />
    </MatureLayout>
  );
}
