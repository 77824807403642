import React, { useState } from "react";
import Heading from "../../../shared/Heading";
import join1 from "../../../assets/join1.png";
import JoinPhaseone from "./JoinPhaseone";
import JoinPhaseTwo from "./JoinPhaseTwo";
import JoinPhaseThree from "./JoinPhaseThree";
import JoinPhaseFour from "./JoinPhaseFour";
import JoinPhaseFive from "./JoinPhaseFive";

import { motion } from "framer-motion";

export default function WhyJoinProcess() {
  let [joinPhase, setJoinPhase] = useState(1);
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className=" pt-20"
    >
      <Heading firstHeading="Why" secondHeading="Join?" />
      <div className="w-full join bg mt-10 px-4">
        <div className="max-w-6xl mx-auto text-white py-20">
          <p className="text-[22px]">
            If selected for the Needle Innovation Challenge, you’ll gain access
            to the knowledge, pollination grant, partnership, and collaboration
            opportunities to bring your ideas into reality and implement your
            solutions at scale. Specifically, you will get:
          </p>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 py-8">
            <div className="flex flex-col gap-10">
              <div>
                <div
                  className="w-full md:w-[290px] flex items-center gap-4 cursor-pointer text-[22px]"
                  onClick={() => setJoinPhase(1)}
                >
                  <div
                    className={
                      joinPhase === 1
                        ? "text-red-500 text-5xl font-bold text-xl md:text-[85px]"
                        : "text-5xl font-bold text-gray-400/50 text-xl md:text-[85px]"
                    }
                  >
                    1
                  </div>
                  <div
                    className={
                      joinPhase === 1 ? "text-white" : "text-gray-300/75"
                    }
                  >
                    Access to residential bootcamps
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="w-full md:w-[290px] flex items-center gap-4 cursor-pointer text-[22px] md:ml-20"
                  onClick={() => setJoinPhase(2)}
                >
                  <div
                    className={
                      joinPhase === 2
                        ? "text-red-500 text-5xl font-bold text-xl md:text-[85px]"
                        : "text-5xl font-bold text-gray-400/50 text-xl md:text-[85px]"
                    }
                  >
                    2
                  </div>
                  <div
                    className={
                      joinPhase === 2 ? "text-white" : "text-gray-300/75"
                    }
                  >
                    Pollination Grant for implementation
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="w-full md:w-[290px] flex items-center gap-4 cursor-pointer text-[22px] md:ml-40"
                  onClick={() => setJoinPhase(3)}
                >
                  <div
                    className={
                      joinPhase === 3
                        ? "text-red-500 text-5xl font-bold text-xl md:text-[85px]"
                        : "text-5xl font-bold text-gray-400/50 text-xl md:text-[85px]"
                    }
                  >
                    3
                  </div>
                  <div
                    className={
                      joinPhase === 3 ? "text-white" : "text-gray-300/75"
                    }
                  >
                    Coaching and Mentoring
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="w-full md:w-[290px] flex items-center gap-4 cursor-pointer text-[22px] md:ml-20"
                  onClick={() => setJoinPhase(4)}
                >
                  <div
                    className={
                      joinPhase === 4
                        ? "text-red-500 text-5xl font-bold text-xl md:text-[85px]"
                        : "text-5xl font-bold text-gray-400/50 text-xl md:text-[85px]"
                    }
                  >
                    4
                  </div>
                  <div
                    className={
                      joinPhase === 4 ? "text-white" : "text-gray-300/75"
                    }
                  >
                    Access to network and opportunities
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="w-full md:w-[290px] flex items-center gap-4 cursor-pointer text-[22px]"
                  onClick={() => setJoinPhase(5)}
                >
                  <div
                    className={
                      joinPhase === 5
                        ? "text-red-500 text-5xl font-bold text-xl md:text-[85px]"
                        : "text-5xl font-bold text-gray-400/50 text-xl md:text-[85px]"
                    }
                  >
                    5
                  </div>
                  <div
                    className={
                      joinPhase === 5 ? "text-white" : "text-gray-300/75"
                    }
                  >
                    Media coverage and visibility
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center">
              {joinPhase === 1 && <JoinPhaseone />}
              {joinPhase === 2 && <JoinPhaseTwo />}
              {joinPhase === 3 && <JoinPhaseThree />}
              {joinPhase === 4 && <JoinPhaseFour />}
              {joinPhase === 5 && <JoinPhaseFive />}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
