import React from "react";
import AdminSidebar from "./AdminSidebar";
import AdminTopbar from "../AdminPanel/AdminProjectsComponent/AdminTopbar";

export default function AdminLayout({ children }) {
  return (
    <div className="min-h-screen w-full bg-[#EAEAEA]">
      <AdminTopbar />
      <div className="flex flex-col md:flex-row h-full">
        <AdminSidebar />
        {children}{" "}
      </div>
    </div>
  );
}
