import React, { useEffect, useState } from "react";
import { FaAngleRight, FaCaretRight, FaSave } from "react-icons/fa";
import { useFirestore } from "../../../hooks/useFirestore";
import { toast } from "react-toastify";
import Toast from "../../../shared/UI/Toast";

export default function ApplicationBasicInfo({ setDetails, details, setPage }) {
  const { addDocument, updateDocument, response } =
    useFirestore("entrepreneur");

  useEffect(() => {
    if (
      response.success &&
      details?.basicInfo?.fullName !== "" &&
      details?.basicInfo?.gender !== "" &&
      details?.basicInfo?.phoneNumber !== "" &&
      details?.basicInfo?.email !== "" &&
      details?.basicInfo?.age !== ""
    ) {
      setPage(2);
    }
  }, [response.success]);

  const handleSave = () => {
    if (!response.isLoading) {
      updateDocument(details?.id, details);
      toast.success("Your data saved Successfully");
    }
  };

  const handleNext = () => {
    if (
      details?.basicInfo?.fullName !== "" &&
      details?.basicInfo?.gender !== "" &&
      details?.basicInfo?.phoneNumber !== "" &&
      details?.basicInfo?.age !== "" &&
      details?.basicInfo?.email !== "" &&
      !response.isLoading
    ) {
      updateDocument(details?.id, details);
    } else {
      toast.error("Please Fill all the required Field");
    }
  };

  return (
    <div className="py-10 flex flex-col gap-8 z-10">
      <div className="w-full flex flex-col md:flex-row gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label for="fName" className="font-bold">
            Full Name <span>*</span>
          </label>
          <input
            type="text"
            name="fName"
            id="fName"
            placeholder="Full Name"
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
            value={details?.basicInfo?.fullName}
            onChange={(e) =>
              setDetails({
                ...details,
                basicInfo: { ...details?.basicInfo, fullName: e.target.value },
              })
            }
          />
        </div>
        <div className=" w-full flex flex-col gap-2">
          <label for="fName" className="font-bold">
            Email <span>*</span>
          </label>
          <input
            type="email"
            name="fName"
            id="fName"
            placeholder="Email"
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
            value={details?.basicInfo?.email}
            onChange={(e) =>
              setDetails({
                ...details,
                basicInfo: { ...details?.basicInfo, email: e.target.value },
              })
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label for="lName" className="font-bold">
            Phone Number <span>*</span>
          </label>
          <input
            type="tel"
            name="lName"
            id="lName"
            placeholder="Your Phone Number"
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
            value={details?.basicInfo?.phoneNumber}
            onChange={(e) =>
              setDetails({
                ...details,
                basicInfo: {
                  ...details?.basicInfo,
                  phoneNumber: e.target.value,
                },
              })
            }
          />
        </div>
        <div className=" w-full flex flex-col gap-2">
          <label for="fAge" className="font-bold">
            AGE? <span>*</span>
          </label>
          <input
            type="number"
            name="fAge"
            id="fAge"
            placeholder="Age"
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
            value={details?.basicInfo?.age}
            onChange={(e) =>
              setDetails({
                ...details,
                basicInfo: { ...details?.basicInfo, age: e.target.value },
              })
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label for="fName" className="font-bold">
            Gender <span>*</span>
          </label>
          <select
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
            value={details?.basicInfo?.gender}
            onChange={(e) =>
              setDetails({
                ...details,
                basicInfo: { ...details?.basicInfo, gender: e.target.value },
              })
            }
          >
            <option value="">Select your gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
      </div>
      <div className="flex justify-between">
        <div
          className="flex gap-2 items-center justify-center cursor-pointer bg-black px-4 py-2 rounded-md text-white"
          onClick={handleSave}
        >
          <p>Save</p>
          {/* <FaSave /> */}
        </div>
        <div
          className="flex gap-2 items-center justify-center cursor-pointer bg-secondary px-4 py-2 rounded-md text-white"
          onClick={handleNext}
        >
          <p>NEXT</p>
          <FaCaretRight />
        </div>
      </div>
      <Toast />
    </div>
  );
}
