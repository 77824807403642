import React, { useEffect, useState } from "react";
import { FaCaretLeft, FaCaretRight, FaSave } from "react-icons/fa";
import { useFirestore } from "../../../hooks/useFirestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { projectStorage } from "../../../firebase.config";
import { toast } from "react-toastify";
import Textarea from "../../../shared/UI/Textarea";
import Toast from "../../../shared/UI/Toast";

export default function ApplicationProfile({ setDetails, details, setPage }) {
  const { addDocument, updateDocument, response } =
    useFirestore("entrepreneur");
  const [isRoleOthers, setIsRoleOthers] = useState(false);

  const handleSave = () => {
    if (!response.isLoading) {
      updateDocument(details?.id, details);
      toast.success("Your data saved Successfully");
    }
  };

  const handlePrev = () => {
    setPage(1);
  };

  const handleNext = () => {
    if (
      details?.applicantProfile?.aboutTeam &&
      details?.applicantProfile?.acceleratorsParticipate &&
      details?.applicantProfile?.enterpriseFounded &&
      details?.applicantProfile?.enterpriseHeadquartered &&
      details?.applicantProfile?.enterpriseInvestment &&
      details?.applicantProfile?.enterpriseLocation &&
      details?.applicantProfile?.enterpriseName &&
      details?.applicantProfile?.enterprisePeople &&
      details?.applicantProfile?.enterpriseRegistered &&
      details?.applicantProfile?.role &&
      !response.isLoading
    ) {
      updateDocument(details?.id, details);
    } else {
      toast.error("Please Fill all the required Field");
    }
  };

  useEffect(() => {
    if (
      response.success &&
      details?.applicantProfile?.aboutTeam &&
      details?.applicantProfile?.acceleratorsParticipate &&
      details?.applicantProfile?.enterpriseFounded &&
      details?.applicantProfile?.enterpriseHeadquartered &&
      details?.applicantProfile?.enterpriseInvestment &&
      details?.applicantProfile?.enterpriseLocation &&
      details?.applicantProfile?.enterpriseName &&
      details?.applicantProfile?.enterprisePeople &&
      details?.applicantProfile?.enterpriseRegistered &&
      details?.applicantProfile?.role
    ) {
      setPage(3);
    }
  }, [response.success]);

  return (
    <div className="py-10 flex flex-col gap-8 z-10">
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label for="fUni" className="font-bold">
            Enterprise Name <span>*</span>
          </label>
          <input
            type="text"
            name="fUni"
            id="fUni"
            placeholder=""
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
            value={details?.applicantProfile?.enterpriseName}
            onChange={(e) =>
              setDetails({
                ...details,
                applicantProfile: {
                  ...details?.applicantProfile,
                  enterpriseName: e.target.value,
                },
              })
            }
          />
        </div>
        <div className=" w-full flex flex-col gap-2">
          <label for="fYear" className="font-bold">
            Year your enterprise was founded <span>*</span>
          </label>
          <input
            type="number"
            name="fYear"
            id="fYear"
            placeholder=""
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
            value={details?.applicantProfile?.enterpriseFounded}
            onChange={(e) =>
              setDetails({
                ...details,
                applicantProfile: {
                  ...details?.applicantProfile,
                  enterpriseFounded: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label for="fLocation" className="font-bold">
            In which country your enterprise is headquartered? <span>*</span>
          </label>
          <input
            type="text"
            name="fLocation"
            id="fLocation"
            placeholder="Country"
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
            value={details?.applicantProfile?.enterpriseHeadquartered}
            onChange={(e) =>
              setDetails({
                ...details,
                applicantProfile: {
                  ...details?.applicantProfile,
                  enterpriseHeadquartered: e.target.value,
                },
              })
            }
          />
        </div>

        <div className=" w-full flex flex-col gap-2">
          <label className="font-bold">
            Is your enterprise a registered entity? <span>*</span>
          </label>
          <div className="flex gap-4 items-center">
            <label>
              <input
                type="radio"
                name="subject"
                value="Yes"
                checked={
                  details?.applicantProfile?.enterpriseRegistered === "Yes"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      enterpriseRegistered: e.target.value,
                    },
                  })
                }
              />{" "}
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="subject"
                value="No"
                checked={
                  details?.applicantProfile?.enterpriseRegistered === "No"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      enterpriseRegistered: e.target.value,
                    },
                  })
                }
              />{" "}
              No
            </label>
          </div>
        </div>
      </div>
      {details?.applicantProfile?.enterpriseRegistered === "Yes" ? (
        <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
          <div className=" w-full flex flex-col gap-2">
            <label for="fRegistered" className="font-bold">
              If yes, when was your enterprise registered? <span>*</span>
            </label>
            <input
              type="date"
              name="fRegistered"
              id="fRegistered"
              placeholder=""
              className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
              value={details?.applicantProfile?.enterpriseRegisteredYear}
              onChange={(e) =>
                setDetails({
                  ...details,
                  applicantProfile: {
                    ...details?.applicantProfile,
                    enterpriseRegisteredYear: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
      ) : null}
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label for="fPlace" className="font-bold">
            In which area (specific location) your enterprise is currently
            operational? <span>*</span>
          </label>
          <input
            type="text"
            name="fPlace"
            id="fPlace"
            placeholder="Location"
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
            value={details?.applicantProfile?.enterpriseLocation}
            onChange={(e) =>
              setDetails({
                ...details,
                applicantProfile: {
                  ...details?.applicantProfile,
                  enterpriseLocation: e.target.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label for="fRegistered" className="font-bold">
            Have you received any investment or grant funds yet? <span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <label>
              <input
                type="radio"
                name="acceleratorsParticipate"
                value="Yes"
                checked={
                  details?.applicantProfile?.enterpriseInvestment === "Yes"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      enterpriseInvestment: e.target.value,
                    },
                  })
                }
              />{" "}
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="acceleratorsParticipate"
                value="No"
                checked={
                  details?.applicantProfile?.enterpriseInvestment === "No"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      enterpriseInvestment: e.target.value,
                    },
                  })
                }
              />{" "}
              No
            </label>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label className="font-bold">
            What is your role in your enterprise? <span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <label>
              <input
                type="radio"
                name="role"
                value="Founder/ Co-Founder"
                checked={
                  details?.applicantProfile?.role === "Founder/ Co-Founder"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      role: e.target.value,
                    },
                  })
                }
              />{" "}
              Founder/ Co-Founder
            </label>
            <label>
              <input
                type="radio"
                name="role"
                value="Managing Director"
                checked={
                  details?.applicantProfile?.role === "Managing Director"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      role: e.target.value,
                    },
                  })
                }
              />{" "}
              Managing Director
            </label>
            <label>
              <input
                type="radio"
                name="role"
                value="CEO/COO/CXO"
                checked={details?.applicantProfile?.role === "CEO/COO/CXO"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      role: e.target.value,
                    },
                  })
                }
              />{" "}
              CEO/COO/CXO
            </label>
            {isRoleOthers ||
            (!isRoleOthers && details?.applicantProfile?.role) ? (
              <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
                <div className=" w-full flex flex-col gap-2">
                  <input
                    type="text"
                    name="fPlace"
                    id="fPlace"
                    placeholder="Others"
                    className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
                    value={
                      details?.applicantProfile?.role !==
                        "Founder/ Co-Founder" &&
                      details?.applicantProfile?.role !== "Managing Director" &&
                      details?.applicantProfile?.role !== "CEO/COO/CXO"
                        ? details?.applicantProfile?.role
                        : ""
                    }
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        applicantProfile: {
                          ...details?.applicantProfile,
                          role: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              <label>
                <input
                  type="radio"
                  name="role"
                  value="others"
                  checked={details?.applicantProfile?.role === "others"}
                  onChange={(e) => {
                    setIsRoleOthers(true);
                    setDetails({
                      ...details,
                      applicantProfile: {
                        ...details?.applicantProfile,
                        role: "",
                      },
                    });
                  }}
                />{" "}
                Others
              </label>
            )}
          </div>
        </div>
        <div className=" w-full flex flex-col gap-2">
          <label className="font-bold">
            How many people do you have in your enterprise who are working on
            full time basis? <span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <label>
              <input
                type="radio"
                name="people"
                value="2-10"
                checked={details?.applicantProfile?.enterprisePeople === "2-10"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      enterprisePeople: e.target.value,
                    },
                  })
                }
              />{" "}
              2-10
            </label>
            <label>
              <input
                type="radio"
                name="people"
                value="11-20"
                checked={
                  details?.applicantProfile?.enterprisePeople === "11-20"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      enterprisePeople: e.target.value,
                    },
                  })
                }
              />{" "}
              11-20
            </label>
            <label>
              <input
                type="radio"
                name="people"
                value="21-50"
                checked={
                  details?.applicantProfile?.enterprisePeople === "21-50"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      enterprisePeople: e.target.value,
                    },
                  })
                }
              />{" "}
              21-50
            </label>
            <label>
              <input
                type="radio"
                name="people"
                value="50+"
                checked={details?.applicantProfile?.enterprisePeople === "50+"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      enterprisePeople: e.target.value,
                    },
                  })
                }
              />{" "}
              50+
            </label>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <Textarea
            link="fAboutTeam"
            label=" Tell us about you and your team. (100 words)"
            maximumLength={100}
            value={details?.applicantProfile?.aboutTeam}
            onChange={(e) =>
              setDetails({
                ...details,
                applicantProfile: {
                  ...details?.applicantProfile,
                  aboutTeam: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label className="font-bold">
            Last one! Have you ever participated in any accelerators or
            incubators? <span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <label>
              <input
                type="radio"
                name="acceleratorsParticipate"
                value="Yes"
                checked={
                  details?.applicantProfile?.acceleratorsParticipate === "Yes"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      acceleratorsParticipate: e.target.value,
                    },
                  })
                }
              />{" "}
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="acceleratorsParticipate"
                value="No"
                checked={
                  details?.applicantProfile?.acceleratorsParticipate === "No"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    applicantProfile: {
                      ...details?.applicantProfile,
                      acceleratorsParticipate: e.target.value,
                    },
                  })
                }
              />{" "}
              No
            </label>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div
          className="flex gap-2 items-center justify-center cursor-pointer bg-black px-4 py-2 rounded-md text-white"
          onClick={handleSave}
        >
          <p>Save</p>
          {/* <FaSave /> */}
        </div>
        <div className="flex gap-4">
          <div
            className="flex gap-2 items-center justify-center cursor-pointer bg-secondary px-4 py-2 rounded-md text-white"
            onClick={handlePrev}
          >
            <FaCaretLeft />
            <p>Prev</p>
          </div>
          <div
            className="flex gap-2 items-center justify-center cursor-pointer bg-secondary px-4 py-2 rounded-md text-white"
            onClick={handleNext}
          >
            <p>NEXT</p>
            <FaCaretRight />
          </div>
        </div>
      </div>
      <Toast />
    </div>
  );
}
