import React, { useEffect } from "react";
import AdminNavbar from "./AdminNavbar";
import IdeasStageSidebar from "../pages/IdeaStage/Components/IdeasStageSidebar";

export default function IdeaStageLayout({ children }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="min-h-screen w-full bg-[#EAEAEA]">
      <AdminNavbar />
      <div className="flex flex-col md:flex-row h-full">
        <IdeasStageSidebar />
        {children}
      </div>
    </div>
  );
}
