import React from "react";
import join4 from "../../../assets/join4.png";

export default function JoinPhaseFour() {
  return (
    <div className="flex flex-col gap-4 justify-center items-center">
      <img src={join4} alt="" />
      <p className="text-center text-[16px]">
        Gain access to a curated pool of investors, industry leaders and experts
        to forge collaborations, access capital support and remove barriers to
        scale their solutions.
      </p>
    </div>
  );
}
