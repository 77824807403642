import React from "react";
import { FaPlus } from "react-icons/fa";

export default function ProjectsNavbar({
  setShowModal,
  setProjectPhase,
  projectPhase,
}) {
  return (
    <div className="border-b-2 border-gray-200 pb-2">
      <div className="flex justify-between items-center">
        <div className="w-full flex flex-col md:flex-row gap-2 ">
          <div
            className={`px-4 py-2 rounded-t-2xl cursor-pointer transition-all duration-500 ${
              projectPhase === "all" ? "bg-secondary text-white " : ""
            }`}
            onClick={() => setProjectPhase("all")}
          >
            Applications
          </div>
          <div
            className={`px-4 py-2 rounded-t-2xl cursor-pointer transition-all duration-500 ${
              projectPhase === "Mature" ? "bg-secondary text-white " : ""
            }`}
            onClick={() => setProjectPhase("Mature")}
          >
            Mature Startup
          </div>
          <div
            className={`px-4 py-2 rounded-t-2xl cursor-pointer transition-all duration-500 ${
              projectPhase === "Idea" ? "bg-secondary text-white " : ""
            }`}
            onClick={() => setProjectPhase("Idea")}
          >
            Idea Stage Startup
          </div>
        </div>
        {/* <div>
          <div className='flex gap-2 items-center cursor-pointer' onClick={() => setShowModal(true)}>
            <button>Add New</button>
            <FaPlus />
          </div>
        </div> */}
      </div>
    </div>
  );
}
