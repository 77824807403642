import React from "react";
import {
  FaBloggerB,
  FaBook,
  FaQrcode,
  FaShieldAlt,
  FaSignOutAlt,
  FaWallet,
  FaWrench,
} from "react-icons/fa";
import { Link, NavLink, useNavigate } from "react-router-dom";

export default function AdminSidebar() {
  const navigate = useNavigate();
  const handleLogout = (e) => {
    localStorage.removeItem("needle_admin_loggedIn");
    navigate("/admin-login");
  };
  return (
    <div className="md:min-h-[calc(100vh-96px)] w-full md:w-[280px] bg-white">
      <div className="p-8 bg-primary text-white">
        <p className="text-md">
          <span className="font-bold">Needle</span> - Admin Application Panel
        </p>
      </div>

      <div className="flex flex-col ">
        <NavLink
          to="/admin/projects"
          className={({ isActive, isPending }) =>
            isActive ? "bg-secondary text-white " : ""
          }
        >
          <div className="flex gap-2 items-center px-8 py-2 cursor-pointer hover:bg-secondary hover:text-white transition-all duration-500 ">
            <FaShieldAlt />
            <p>Projects</p>
          </div>
        </NavLink>
        <NavLink
          to="/admin/case-study"
          className={({ isActive, isPending }) =>
            isActive ? "bg-secondary text-white " : ""
          }
        >
          <div className="flex gap-2 items-center px-8 py-2 cursor-pointer hover:bg-secondary hover:text-white transition-all duration-500 ">
            <FaBook />
            <p>Case Study</p>
          </div>
        </NavLink>
        <NavLink
          to="/admin/resources"
          className={({ isActive, isPending }) =>
            isActive ? "bg-secondary text-white " : ""
          }
        >
          <div className="flex gap-2 items-center px-8 py-2 cursor-pointer hover:bg-secondary hover:text-white transition-all duration-500 ">
            <FaQrcode />
            <p>Resources</p>
          </div>
        </NavLink>
        <NavLink
          to="/admin/blogs"
          className={({ isActive, isPending }) =>
            isActive ? "bg-secondary text-white " : ""
          }
        >
          <div className="flex gap-2 items-center px-8 py-2 cursor-pointer hover:bg-secondary hover:text-white transition-all duration-500 ">
            <FaBloggerB />
            <p>Blogs</p>
          </div>
        </NavLink>
        <div
          className="flex gap-2 items-center px-8 py-2 cursor-pointer hover:bg-secondary hover:text-white transition-all duration-500 "
          onClick={handleLogout}
        >
          <FaSignOutAlt />
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
}
