import React from "react";
import Heading from "../../../shared/Heading";
import { FaCaretRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Button from "../../../shared/UI/Button";

export default function TheChallenge() {
  return (
    <div className="">
      <div className="max-w-5xl mx-auto pt-10 pb-20 text-black">
        <Heading firstHeading="The" secondHeading="Challenge" />
        <p className="text-black mt-10">
          Given this backdrop, we present the challenge Bangladesh RMG Industry
          faces today:{" "}
          <span className="font-bold text-secondary">
            How can we enhance the competitiveness of the Bangladesh RMG
            industry through scalable innovations and socially beneficial models
            which enhance the employability of women garment workers and
            accelerate the transition to a circular economy?
          </span>
        </p>
        <div className="flex justify-center items-center mt-10">
          {/* <Link to="/signup"> */}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdlPYzuoPTwpWM_gnv6sbnkHOqWtzLjlo9nROrHd-O8Jsagbw/viewform"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Apply Now</Button>
          </a>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
}
