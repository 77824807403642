import React, { useState } from "react";
import Heading from "../../../shared/Heading";

import { motion } from "framer-motion";
import { FaCaretRight } from "react-icons/fa";
import TheChallenge from "./TheChallenge";

export default function ChallengeBackground() {
  let [showMore, setShowMore] = useState(false);
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <div className="max-w-5xl mx-auto py-20">
        <Heading firstHeading="Challenge" secondHeading="Background" />
        {!showMore && (
          <div className="font-ruda leading-8 tracking-wide text-justify px-4">
            <p className="pt-10">
              From humble beginnings four decades ago, Bangladesh's
              <span className="text-secondary font-bold">
                {" "}
                Ready-made Garment (RMG){" "}
              </span>
              sector has skyrocketed to become the world's second-largest
              garment exporter. Over the years, the sector has become the
              powerhouse of Bangladesh’s economy, generating 83% of national
              export and providing employment to 4.2mln people of which 60% are
              women. In its journey towards global dominance...
            </p>
            <div>
              <button
                className="mt-4 bg-white cursor-pointer text-black flex gap-2 underline justify-center items-center"
                onClick={() => setShowMore(true)}
              >
                SEE MORE
                <FaCaretRight />
              </button>
            </div>
          </div>
        )}
        {showMore && (
          <div className="font-ruda leading-8 tracking-wide text-justify px-4">
            <p className="pt-10 ">
              From humble beginnings four decades ago, Bangladesh's
              <span className="text-secondary font-bold">
                {" "}
                Ready-made Garment (RMG){" "}
              </span>
              sector has skyrocketed to become the world's second-largest
              garment exporter. Over the years, the sector has become the
              powerhouse of Bangladesh’s economy, generating 83% of national
              export and providing employment to 4.2mln people of which 60% are
              women. In its journey towards global dominance, the RMG industry
              has gone through and conquered a myriad of challenges. However,
              the industry is at the juncture where it needs to prepare for the
              next growth phase, and{" "}
              <span className="text-secondary  font-bold">
                the direst challenge is yet to be faced amidst the emergence of
                the fourth industrial revolution (4IR) and a global push towards
                circularity!
              </span>
            </p>
            <p className="pt-10">
              Embracing automation, and digital technology by capitalizing 4IR
              technologies is a natural progression for Bangladesh apparel
              industry to stay on par with the stiff global competition.
              However, it will blur the differences between the labor of people
              and the work of machines. It is predicted to create a{" "}
              <a
                href="https://www.thedailystar.net/business/news/60pc-rmg-workers-lose-jobs-2030-1737412"
                target="_blank"
                className="underline text-blue-500"
              >
                60% job loss by 2030
              </a>{" "}
              and women RMG workers will suffer the most as they largely occupy
              routinized jobs of the industry. Nonetheless, there are
              opportunities to be harnessed. Picture this: 4IR could elevate our
              exports by $30-40bln in future, contributing to the attainment of
              BGMEA’s target of $100bln by 2030. However, the industry needs a
              faster pace to innovate, diversify, and adapt to keep having a
              larger share in the global apparel exports while safeguarding the
              livelihood opportunities of its primary workforce - women, who
              have been the backbone of the industry!
            </p>
            <p className="pt-10">
              On the other side of the coin, the reshaping of the traditional
              operational methods of the industry cannot be future proof without
              transitioning to a circular system. The linear model of business
              “take-make-dispose” that has long been followed by the fashion
              industry made it one of the major polluting industries of the
              planet. This requires us to rethink the way our industry operates
              and reinforces our need to introduce circular business models and
              production methods to promote ecological integrity and social
              justice. Integration of circular practices and business models
              would potentially create an additional economy of{" "}
              <a
                href="https://www.bgmea.com.bd/uploads/newsletters/apparel-story-january-february-2023.pdf"
                target="_blank"
                className="underline text-blue-500"
              >
                USD 5 billion, save 840 million m3 of water, 3Mn tons of CO2-eq
              </a>{" "}
              and create a new hub of employment, amongst various other direct
              and indirect advantages. Additionally, the adaptation of new
              circularity policies may involve successful implementation of
              digitalization and technical advancements in the apparel
              industry. 
            </p>
            <p className="pt-10">
              It's crystal clear: the path forward hinges on two potent
              forces—technology and innovation. We must embrace them to achieve
              our growth aspirations. Diversify our products, enhance efficiency
              and productivity, upskill our workforce, strengthen backward
              integration, embrace green closed-loop production practices, and
              build a circular apparel future. Innovation is the key, stitching
              together economic, social, and environmental sustainability.
            </p>
            <p className="pt-10">
              Ready to reimagine the future of Bangladesh's RMG industry? It's
              time to thread innovation, sustainability, and excellence into its
              very fabric.
            </p>
          </div>
        )}
        <TheChallenge />
      </div>
    </motion.div>
  );
}
