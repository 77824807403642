import React from "react";
import IdeaStageLayout from "../../shared/IdeaStageLayout";
import Resources from "../Resources/Resources";

export default function IdeaStageResources() {
  return (
    <IdeaStageLayout>
      <Resources />
    </IdeaStageLayout>
  );
}
