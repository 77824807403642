import React, { useEffect, useState } from "react";

export default function Textarea({
  label,
  link,
  maximumLength,
  value,
  onChange,
}) {
  let [maxLength, setMaxLength] = useState(null);

  useEffect(() => {
    const words = value?.trim().split(/\s+/);

    if (words?.length > maximumLength) {
      setMaxLength(words?.join(" ").length);
    } else {
      setMaxLength(null);
    }
  }, [value, maximumLength]);

  return (
    <>
      <label for={link} className="font-bold">
        {label}
        <span>*</span>
      </label>
      <textarea
        type="text"
        name={link}
        id={link}
        placeholder=""
        maxlength={maxLength ? maxLength : ""}
        className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none h-[150px]"
        value={value}
        onChange={onChange}
      />
    </>
  );
}

export function SingleTextarea({ maximumLength, value, onChange }) {
  let [maxLength, setMaxLength] = useState(null);

  useEffect(() => {
    const words = value?.trim().split(/\s+/);

    if (words?.length > maximumLength) {
      setMaxLength(words?.join(" ").length);
    } else {
      setMaxLength(null);
    }
  }, [value, maximumLength]);

  return (
    <>
      <textarea
        type="text"
        placeholder=""
        maxlength={maxLength ? maxLength : ""}
        className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none h-[150px]"
        value={value}
        onChange={onChange}
      />
    </>
  );
}
