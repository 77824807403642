import React from "react";
import IdeaStageDetails from "./Components/IdeaStageDetails";
import IdeaStageLayout from "../../shared/IdeaStageLayout";

export default function IdeaStage() {
  return (
    <>
      <IdeaStageLayout>
        <IdeaStageDetails />
      </IdeaStageLayout>
    </>
  );
}
