import React from "react";
import { Link } from "react-router-dom";
import needle from "../../assets/logo2.png";
import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase.config";
import { firebaseErrorHandle } from "../../shared/utils/firebaseErrorHandle";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleForgotPassword = () => {
    if (!email) {
      setError("Please provide your email");
      setSuccess("");
      return;
    }
    setIsLoading(true);

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccess("Password reset email sent!");
        setError("");
        setIsLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = firebaseErrorHandle(error.message);
        // ..
        setSuccess("");
        setError(errorMessage);
        setIsLoading(false);
      });
  };
  return (
    <div className="min-h-screen text-white bg-primary p-10 flex flex-col justify-center items-center">
      <div className="w-full">
        <div
          className="bg-white max-w-2xl mx-auto rounded-xl text-black px-12 py-8"
          style={{ boxShadow: "12px 15px 30px rgba(234, 51, 82, 0.4)" }}
        >
          <div className="flex justify-center items-center border-b-2 border-b-gray-300  pb-4">
            <Link to="/">
              <img src={needle} alt="Needle" className="w-[80px] h-auto" />
            </Link>
          </div>
          <div className="py-4">
            <p>WELCOME BACK!</p>
          </div>
          <div className="pt-6">
            <div className=" w-full flex flex-col gap-2">
              <label for="fEmail" className="font-bold">
                EMAIL ADDRESS <span>*</span>
              </label>
              <input
                type="text"
                name="fEmail"
                id="fEmail"
                placeholder="Your Email"
                className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className=" py-8 flex gap-6 items-center">
            {isLoading ? (
              <button className="bg-secondary px-4 py-2 text-white rounded-tr-2xl rounded-bl-2xl">
                Processing
              </button>
            ) : (
              <button
                className="bg-secondary px-4 py-2 text-white rounded-tr-2xl rounded-bl-2xl"
                onClick={handleForgotPassword}
              >
                Submit
              </button>
            )}
          </div>
          {error && <p className="text-red-500">{error}</p>}
          {success && <p className="text-green-500">{success}</p>}
        </div>
        <p className="text-white text-center pt-20">
          Copyright © 2023 | Needle
        </p>
      </div>
      {/* <div className="fixed bottom-[50px] right-[50px]">
      <FaFacebookMessenger className="w-10 h-10 z-10" />
    </div> */}
    </div>
  );
}
