import React, { useEffect } from "react";
import ScrollToTop from "./utils/ScrollToTop";
import AdminNavbar from "./AdminNavbar";
import MaturestartupSidebar from "../pages/matureStartup/Components/MaturestartupSidebar";

export default function MatureLayout({ children }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div className="min-h-screen w-full bg-[#EAEAEA]">
        <AdminNavbar />
        <div className="flex flex-col md:flex-row h-full">
          <MaturestartupSidebar />
          {children}
        </div>
      </div>
    </>
  );
}
