import React, { useEffect, useState } from "react";
import { FaDownload, FaPlus } from "react-icons/fa";
import CaseStudyNavbar from "./CaseStudyNavbar";
import CaseStudyTable from "./CaseStudyTable";
import { useCollection } from "../../../../hooks/useCollection";
import { Link } from "react-router-dom";

export default function CaseStudyDetails() {
  const [casePhase, setCasePhase] = useState("all");
  const [projects, setProjects] = useState([]);

  const { document, isLoading } = useCollection("case_study");

  useEffect(() => {
    if (casePhase === "all") {
      setProjects(document);
    } else {
      let filteredProject = document?.filter(
        (project) => project?.stage === casePhase
      );
      setProjects(filteredProject);
    }
  }, [casePhase, document]);

  return (
    <div className="relative md:w-full py-20 mx-4">
      <div className="relative max-w-5xl mx-auto bg-white p-4 md:p-10 rounded-md z-10">
        <div className="pb-6 flex justify-between">
          <h2 className="font-bold text-xl">Case Study</h2>
          <Link to="/admin/add-case-study">
            <div className="flex items-center gap-2 cursor-pointer">
              <FaPlus />
              <p>Add New</p>
            </div>
          </Link>
        </div>
        <div className="p-4">
          <CaseStudyNavbar setCasePhase={setCasePhase} casePhase={casePhase} />
          <CaseStudyTable caseStudies={document} />
        </div>
      </div>
    </div>
  );
}
