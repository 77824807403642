import React, { useState } from "react";
import Layout from "../../shared/Layout";
import AllSolution from "./Components/AllSolution";
import ScrollToTop from "../../shared/utils/ScrollToTop";
import Modal from "../../shared/Modal";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsBook } from "react-icons/bs";

export default function SolutionAreas() {
  const [showModal, setShowModal] = useState(false);
  const [solutionObj, setSolutionObj] = useState({});
  return (
    <>
      <ScrollToTop />
      <Layout>
        <AllSolution
          setSolutionObj={setSolutionObj}
          setShowModal={setShowModal}
        />
      </Layout>
      {showModal && (
        <Modal setShowModal={setShowModal}>
          <div className="w-[700px] mx-auto rounded-md overflow-hidden max-h-[800px] overflow-y-scroll">
            <div className="bg-[#EA3352] text-white p-6 text-center text-[25px]">
              {solutionObj.title}
            </div>

            <div className="bg-white text-dark p-6">
              <p className=" text-justify font-ruda leading-8">
                <h6 className="font-bold">
                  <span
                    className="text-pink-600 text-3xl inline-block"
                    style={{
                      position: "relative",
                      top: "9px",
                      "margin-right": "0.5rem",
                    }}
                  >
                    {" "}
                    <AiOutlineQuestionCircle />{" "}
                  </span>
                  {solutionObj?.question}
                </h6>

                <span
                  className="text-pink-600 text-3xl inline-block"
                  style={{
                    position: "relative",
                    top: "9px",
                    "margin-right": "0.5rem",
                  }}
                >
                  {" "}
                  <BsBook />{" "}
                </span>
                {solutionObj?.description}

                <h3>{solutionObj?.example}</h3>

                <ul>
                  {Object.keys(solutionObj?.links).map((key, index) => (
                    <li>
                      <a
                        href={solutionObj?.links[key]}
                        className="text-blue-700"
                        target="_blank"
                      >
                        {key}
                      </a>
                    </li>
                  ))}
                </ul>
              </p>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
