import React from "react";
import { FaCaretRight } from "react-icons/fa";

export default function Button({ children, onClick }) {
  return (
    <button
      className="px-8 py-2 text-white bg-secondary rounded-tl-xl rounded-br-xl cursor-pointer border-2 border-primary  text-[18px] shadow-btn btn flex items-center border-none hover:bg-secondary"
      onClick={onClick}
    >
      {children}
      <FaCaretRight className="inine btn_arrow" />
    </button>
  );
}
