import React, { useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { projectProgress } from "../../../../shared/utils/ProjectProgress";

export default function ProjectsTable({ projects, showModal, setShowModal }) {
  return (
    <div className="my-8">
      <div className="w-full">
        <div className="max-w-full overflow-x-auto">
          <table className="w-full table-auto" id="table-to-xls">
            <thead>
              <tr className="text-left text-dark font-bold bg-[#D9D9D9]">
                <th className="w-2/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Projects
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Entrepreneurs
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  University
                </th>
                <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Progress
                </th>
                <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  State
                </th>
              </tr>
            </thead>
            <tbody>
              {projects?.map((project, id) => (
                <tr
                  className={`border-t border-[#ddd] text-left text-dark py-4 ${
                    id % 2 == 0 ? "" : "bg-[#D9D9D9]"
                  }`}
                >
                  <td className="py-6 px-8  text-base">
                    <p>{project?.applicantProfile?.enterpriseName}</p>
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.basicInfo?.fullName}
                  </td>
                  <td className="py-6 px-8  text-base">
                    <p>{project?.basicInfo?.universityName}</p>
                  </td>
                  <td className="py-6 px-8  text-base">
                    {projectProgress(project)}%
                  </td>
                  <td className="py-6 px-8  text-base">
                    <FaCheck className="ml-4" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
