import React from "react";
import Layout from "../../shared/Layout";
import ScrollToTop from "../../shared/utils/ScrollToTop";
import AllNewsAndEvents from "./Components/AllNewsAndEvents";

export default function NewsAndEvents() {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <AllNewsAndEvents />
      </Layout>
    </>
  );
}
