import React, { useEffect, useState } from "react";
import { useFirestore } from "../../../hooks/useFirestore";
import { FaCaretLeft, FaCaretRight, FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { projectStorage } from "../../../firebase.config";
import Textarea from "../../../shared/UI/Textarea";
import Toast from "../../../shared/UI/Toast";

export default function ApplicationSolution({ setDetails, details, setPage }) {
  const [fileProgress, setFileProgress] = useState("");
  const { updateDocument, response } = useFirestore("entrepreneur");

  useEffect(() => {
    if (
      response.success &&
      details?.Solution?.businessModel &&
      details?.Solution?.businessModel &&
      details?.Solution?.focusArea &&
      details?.Solution?.keyPartner &&
      details?.Solution?.pitchDeck &&
      details?.Solution?.problemSolution &&
      details?.Solution?.revenueModel &&
      details?.Solution?.solution &&
      details?.Solution?.stage
    ) {
      setPage(4);
    }
  }, [response.success]);

  const types = ["application/pdf"];

  const handleResume = (e) => {
    let selected = e.target.files[0];

    if (selected && !types.includes(selected?.type)) {
      toast.error("Please select a pdf file for pitch deck");
      return;
    }

    if (selected && selected?.size > 15100000) {
      toast.error("pdf file should be under 15 MB!");
      return;
    }

    const storageRef = ref(projectStorage, `files/${selected?.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setFileProgress(progress);
      },
      (error) => {
        toast.error(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setDetails({
            ...details,
            Solution: {
              ...details?.Solution,
              pitchDeck: downloadURL,
            },
          });
        });
      }
    );
  };

  const handleSave = () => {
    if (!response.isLoading) {
      updateDocument(details?.id, details);
      toast.success("Your data saved Successfully");
    }
  };

  const handlePrev = () => {
    setPage(2);
  };

  const handleNext = () => {
    if (
      details?.Solution?.businessModel &&
      details?.Solution?.businessModel &&
      details?.Solution?.focusArea &&
      details?.Solution?.keyPartner &&
      details?.Solution?.pitchDeck &&
      details?.Solution?.problemSolution &&
      details?.Solution?.revenueModel &&
      details?.Solution?.solution &&
      details?.Solution?.stage &&
      !response.isLoading
    ) {
      updateDocument(details?.id, details);
    } else {
      toast.error("Please Fill all the required Field");
    }
  };
  return (
    <div className="py-10 flex flex-col gap-8 z-10">
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-4">
          <label className="font-bold">
            Focus area <span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <label>
              <input
                type="radio"
                name="fFocus"
                value="Material Innovation (Fiber, yarn, alternative fabrics, etc.)"
                checked={
                  details?.Solution?.focusArea ===
                  "Material Innovation (Fiber, yarn, alternative fabrics, etc.)"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      focusArea: e.target.value,
                    },
                  })
                }
              />{" "}
              Material Innovation (Fiber, yarn, alternative fabrics, etc.)
            </label>
            <label>
              <input
                type="radio"
                name="fFocus"
                value="Environment and Circular Solutions (efficient use of energy and resources, re/up cycling, business model to manage plastic/textile waste, renewable packaging/alternative plastics, water management solutions, etc.)"
                checked={
                  details?.Solution?.focusArea ===
                  "Environment and Circular Solutions (efficient use of energy and resources, re/up cycling, business model to manage plastic/textile waste, renewable packaging/alternative plastics, water management solutions, etc.)"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      focusArea: e.target.value,
                    },
                  })
                }
              />{" "}
              Environment and Circular Solutions (efficient use of energy and
              resources, re/up cycling, business model to manage plastic/textile
              waste, renewable packaging/alternative plastics, water management
              solutions, etc.)
            </label>
            <label>
              <input
                type="radio"
                name="fFocus"
                value="Better process (Modular/digital solutions for transparency & traceability across the value chain, interconnected manufacturing machines, warehouse management, supply chain solutions, , etc.)"
                checked={
                  details?.Solution?.focusArea ===
                  "Better process (Modular/digital solutions for transparency & traceability across the value chain, interconnected manufacturing machines, warehouse management, supply chain solutions, , etc.)"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      focusArea: e.target.value,
                    },
                  })
                }
              />{" "}
              Better process (Modular/digital solutions for transparency &
              traceability across the value chain, interconnected manufacturing
              machines, warehouse management, supply chain solutions, , etc.)
            </label>
            <label>
              <input
                type="radio"
                name="fFocus"
                value="Dyeing & Finishing solutions (hybrid pigments, organic dye, waterless printing, etc.)"
                checked={
                  details?.Solution?.focusArea ===
                  "Dyeing & Finishing solutions (hybrid pigments, organic dye, waterless printing, etc.)"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      focusArea: e.target.value,
                    },
                  })
                }
              />{" "}
              Dyeing & Finishing solutions (hybrid pigments, organic dye,
              waterless printing, etc.)
            </label>
            <label>
              <input
                type="radio"
                name="fFocus"
                value="Consumer & Retail Models"
                checked={
                  details?.Solution?.focusArea === "Consumer & Retail Models"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      focusArea: e.target.value,
                    },
                  })
                }
              />{" "}
              Consumer & Retail Models
            </label>
            <label>
              <input
                type="radio"
                name="fFocus"
                value="Cross-sector/Common facility center-based business model"
                checked={
                  details?.Solution?.focusArea ===
                  "Cross-sector/Common facility center-based business model"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      focusArea: e.target.value,
                    },
                  })
                }
              />{" "}
              Cross-sector/Common facility center-based business model
            </label>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <Textarea
            link="fAboutTeam"
            label="What specific problem are your trying to solve? (150 words) [Please
              provide evidence of the size of the problem]"
            maximumLength={150}
            value={details?.Solution?.problemSolution}
            onChange={(e) =>
              setDetails({
                ...details,
                Solution: {
                  ...details?.Solution,
                  problemSolution: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
      {/* <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <Textarea
            link="fAboutTeam"
            label="Who is the most affected by this problem? Briefly describe your
            target market and how you judge your enterprise’s potential in the
            market? (150 words max)"
            maximumLength={150}
            value={details?.Solution?.problemAndPotential}
            onChange={(e) =>
              setDetails({
                ...details,
                Solution: {
                  ...details?.Solution,
                  problemAndPotential: e.target.value,
                },
              })
            }
          />
        </div>
      </div> */}
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <Textarea
            link="fAboutTeam"
            label=" Briefly articulate your solution. (100 words max)"
            maximumLength={100}
            value={details?.Solution?.solution}
            onChange={(e) =>
              setDetails({
                ...details,
                Solution: {
                  ...details?.Solution,
                  solution: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-4">
          <label className="font-bold">
            Indicate the current stage of “readiness” or “maturity status” of
            your solution <span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <label>
              <input
                type="radio"
                name="stage"
                value="Pilot/Early-stage"
                checked={details?.Solution?.stage === "Pilot/Early-stage"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      stage: e.target.value,
                    },
                  })
                }
              />{" "}
              Pilot/Early-stage
            </label>
            <label>
              <input
                type="radio"
                name="stage"
                value="Launched/Product-market fit"
                checked={
                  details?.Solution?.stage === "Launched/Product-market fit"
                }
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      stage: e.target.value,
                    },
                  })
                }
              />{" "}
              Launched/Product-market fit
            </label>
            <label>
              <input
                type="radio"
                name="stage"
                value="Growth"
                checked={details?.Solution?.stage === "Growth"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      stage: e.target.value,
                    },
                  })
                }
              />{" "}
              Growth
            </label>
            <label>
              <input
                type="radio"
                name="stage"
                value="Scale"
                checked={details?.Solution?.stage === "Scale"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      stage: e.target.value,
                    },
                  })
                }
              />{" "}
              Scale
            </label>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <Textarea
            link="fBusinessModel"
            label="Describe the business model of your enterprise. (150 words max)"
            maximumLength={150}
            value={details?.Solution?.businessModel}
            onChange={(e) =>
              setDetails({
                ...details,
                Solution: {
                  ...details?.Solution,
                  businessModel: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <Textarea
            link="fAboutTeam"
            label="How do you generate income with your solution to ensure financial
            sustainability (what is your revenue model? (100 words max)"
            maximumLength={100}
            value={details?.Solution?.revenueModel}
            onChange={(e) =>
              setDetails({
                ...details,
                Solution: {
                  ...details?.Solution,
                  revenueModel: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
      {/* <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <Textarea
            link="fAboutTeam"
            label="What is the value proposition of your solution? How does it enhance
            the competitiveness of RMG industry and expedite job creation in
            both blue-collar and white-collar segments? (200 words max)"
            maximumLength={200}
            value={details?.Solution?.competitiveness}
            onChange={(e) =>
              setDetails({
                ...details,
                Solution: {
                  ...details?.Solution,
                  competitiveness: e.target.value,
                },
              })
            }
          />
        </div>
      </div> */}
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-4">
          <label className="font-bold">
            Do you have any key partner in your mind who may help you
            implementing your solution? <span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <label>
              <input
                type="radio"
                name="focus"
                value="Yes"
                checked={details?.Solution?.keyPartner === "Yes"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      keyPartner: e.target.value,
                    },
                  })
                }
              />{" "}
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="focus"
                value="No"
                checked={details?.Solution?.keyPartner === "No"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    Solution: {
                      ...details?.Solution,
                      keyPartner: e.target.value,
                    },
                  })
                }
              />{" "}
              No
            </label>
          </div>
        </div>
      </div>
      {details?.Solution?.keyPartner === "Yes" ? (
        <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
          <div className=" w-full flex flex-col gap-2">
            <label for="fPartner" className="font-bold">
              If yes, please share the names &amp; role of your key partners
              (max 4 partners)
              <span>*</span>
            </label>
            <textarea
              type="text"
              name="fPartner"
              id="fPartner"
              placeholder=""
              className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none h-[150px]"
              value={details?.Solution?.partnerName}
              onChange={(e) =>
                setDetails({
                  ...details,
                  Solution: {
                    ...details?.Solution,
                    partnerName: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>
      ) : null}

      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <div className=" w-full flex flex-col gap-2">
            <label for="lName" className="font-bold">
              Please upload a detailed pitch deck of your solution with max 6
              slides covering the problem you are solving, market opportunity,
              your innovative solution, business and revenue model, your team
              and how does your solution work to benefit the RMG industry and
              its most important drivers – women!
              <span>*</span>
              <span className="text-secondary">
                (Get the pitch deck template in the resource section)
              </span>
              <p className="text-green-500">
                {details?.Solution?.pitchDeck ? (
                  <a
                    href={details?.Solution?.pitchDeck}
                    target="_blank"
                    className="underline"
                  >
                    Submited link
                  </a>
                ) : (
                  ""
                )}
              </p>
            </label>
            <input
              accept=".doc,.pdf"
              type="file"
              name="lName"
              id="lName"
              placeholder="Your Phone Number"
              className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
              onChange={handleResume}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div
          className="flex gap-2 items-center justify-center cursor-pointer bg-black px-4 py-2 rounded-md text-white"
          onClick={handleSave}
        >
          <p>Save</p>
          {/* <FaSave /> */}
        </div>
        <div className="flex gap-4">
          <div
            className="flex gap-2 items-center justify-center cursor-pointer bg-secondary px-4 py-2 rounded-md text-white"
            onClick={handlePrev}
          >
            <FaCaretLeft />
            <p>Prev</p>
          </div>
          <div
            className="flex gap-2 items-center justify-center cursor-pointer bg-secondary px-4 py-2 rounded-md text-white"
            onClick={handleNext}
          >
            <p>NEXT</p>
            <FaCaretRight />
          </div>
        </div>
      </div>
      <Toast />
    </div>
  );
}
