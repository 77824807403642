import React, { useState } from "react";
import Heading from "../../../shared/Heading";
import { FaCaretRight } from "react-icons/fa";

import { motion } from "framer-motion";
import Button from "../../../shared/UI/Button";

export default function Criteria({ setShowModal }) {
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className="pt-20"
    >
      <Heading firstHeading="Eligibility" secondHeading="Criteria" />
      <div className="bg eligibility mt-10 py-40 text-white font-ruda px-4">
        <div className="max-w-6xl mx-auto">
          <div>
            <p className="text-2xl font-bold pb-4">
              We are <span className="text-secondary">looking for</span>{" "}
              entrepreneurs, teams, or enterprises who have impactful, and
              scalable:
            </p>

            <p className="text-xl pb-2">
              a. existing solutions that are solving the challenge and/or may
              have an idea to expand the current business model to become more
              sustainable and circular; or
            </p>
            <p className="text-xl pb-2">
              b. ideas which are yet to be piloted on the ground, but concrete
              steps are taken to outline the concept (background research,
              interviews with stakeholders, business model, technical prototype,
              etc.)
            </p>
          </div>
          <div className="pt-10">
            <h2 className="text-2xl font-bold mb-4">
              You are eligible to apply if your solution meets all the following
              criteria:
            </h2>
            <ol className="list-decimal pl-6">
              <li className="text-xl pb-2">
                Your idea/business has an innovative and high impact technology,
                product, process, or business model innovation that can enhance
                the competitiveness of Bangladesh RMG industry.
              </li>
              <li className="text-xl pb-2">
                Your idea/business ensures future work prospects of women
                garment workers.
              </li>
              <li className="text-xl pb-2">
                Your idea/business has a clear for-profit model with potential
                for scale and potential replication/commercialization.
              </li>
              <li className="text-xl pb-2">
                Your idea/business has positive social and/or environmental
                impact.
              </li>
            </ol>
          </div>
          <div className="pt-6 flex justify-center md:justify-start">
            <Button onClick={() => setShowModal(true)}>
              Terms and Conditions
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
