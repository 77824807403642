export const projectProgress = (user) => {
  let progress;
  if (user?.availability?.attendBootcamp) {
    progress = Math.round((5 / 5) * 100);
    return progress;
  }
  if (
    user?.environmentalImpact?.SDGGoals &&
    user?.environmentalImpact?.businessModel &&
    user?.environmentalImpact?.workImpact
  ) {
    progress = Math.round((4 / 5) * 100);
    return progress;
  }
  if (
    user?.Solution?.focusArea &&
    user?.Solution?.pitchDeck &&
    user?.Solution?.problemAndPotential &&
    user?.Solution?.problemSolution &&
    user?.Solution?.solution &&
    user?.Solution?.stage &&
    user?.Solution?.businessModel &&
    user?.Solution?.revenueModel &&
    user?.Solution?.competitiveness
  ) {
    progress = Math.round((3 / 5) * 100);
    return progress;
  }
  if (
    user?.applicantProfile?.aboutTeam &&
    user?.applicantProfile?.acceleratorsParticipate &&
    user?.applicantProfile?.enterpriseFounded &&
    user?.applicantProfile?.enterpriseHeadquartered &&
    user?.applicantProfile?.enterpriseInvestment &&
    user?.applicantProfile?.enterpriseLocation &&
    user?.applicantProfile?.enterpriseName &&
    user?.applicantProfile?.enterprisePeople &&
    user?.applicantProfile?.enterpriseRegistered &&
    user?.applicantProfile?.role
  ) {
    progress = Math.round((2 / 5) * 100);
    return progress;
  }
  if (
    user?.basicInfo?.fullName &&
    user?.basicInfo?.age &&
    user?.basicInfo?.gender &&
    user?.basicInfo?.phoneNumber
  ) {
    progress = Math.round((1 / 5) * 100);
    return progress;
  } else {
    return 0;
  }
};
