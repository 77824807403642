import React, { useEffect, useState } from "react";
import { useFirestore } from "../../../hooks/useFirestore";
import { FaCaretLeft, FaCaretRight, FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import Toast from "../../../shared/UI/Toast";

export default function ApplicationAvailablity({
  setDetails,
  details,
  setPage,
}) {
  const { addDocument, updateDocument, response } =
    useFirestore("entrepreneur");

  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (response.success && details?.availability?.attendBootcamp) {
      setSuccess("Successfully completed your application!");
    }
  }, [response.success]);

  const handleSave = () => {
    if (!response.isLoading) {
      updateDocument(details?.id, details);
      toast.success("Your data saved Successfully");
    }
  };

  const handlePrev = () => {
    setPage(4);
  };

  const handleNext = () => {
    if (details?.availability?.attendBootcamp && !response.isLoading) {
      updateDocument(details?.id, details);
    } else {
      toast.error("Please Fill all the required Field");
    }
  };

  return (
    <div className="py-10 flex flex-col gap-8 z-10">
      <div className="w-full flex flex-col md:flex-row gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label className="font-bold">
            Will you be able to attend a 5-day residential bootcamp in Dhaka
            around November 2023? <span>*</span>
          </label>
          <div className="flex gap-4 items-center">
            <label>
              <input
                type="radio"
                name="subject"
                value="Yes"
                checked={details?.availability?.attendBootcamp === "Yes"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    availability: {
                      ...details?.availability,
                      attendBootcamp: e.target.value,
                    },
                  })
                }
              />{" "}
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="subject"
                value="No"
                checked={details?.availability?.attendBootcamp === "No"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    availability: {
                      ...details?.availability,
                      attendBootcamp: e.target.value,
                    },
                  })
                }
              />{" "}
              No
            </label>
          </div>
        </div>
      </div>
      {details?.availability?.attendBootcamp === "Yes" ? (
        <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
          <div className=" w-full flex flex-col gap-2">
            <label className="font-bold">
              The recommended amount of time commitment for participating in the
              innovation challenge is 15 hours per week. Can you or someone from
              your team / organization commit to participating? <span>*</span>
            </label>
            <div className="flex flex-col gap-2">
              <label>
                <input
                  type="radio"
                  name="fCommitment"
                  value="Yes - I or someone from my team/organization can commit 15 or more hours per week during the entire duration of the innovation challenge."
                  checked={
                    details?.availability?.commitment ===
                    "Yes - I or someone from my team/organization can commit 15 or more hours per week during the entire duration of the innovation challenge."
                  }
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      availability: {
                        ...details?.availability,
                        commitment: e.target.value,
                      },
                    })
                  }
                />{" "}
                Yes - I or someone from my team/organization can commit 15 or
                more hours per week during the entire duration of the innovation
                challenge.
              </label>
              <label>
                <input
                  type="radio"
                  name="fCommitment"
                  value="Yes - I or someone from my team/organization can commit time to participate, but less than 15 hours per week."
                  checked={
                    details?.availability?.commitment ===
                    "Yes - I or someone from my team/organization can commit time to participate, but less than 15 hours per week."
                  }
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      availability: {
                        ...details?.availability,
                        commitment: e.target.value,
                      },
                    })
                  }
                />{" "}
                Yes - I or someone from my team/organization can commit time to
                participate, but less than 15 hours per week.
              </label>
              <label>
                <input
                  type="radio"
                  name="fCommitment"
                  value="No - I or someone from my team/organization cannot commit any time to participating in the innovation challenge."
                  checked={
                    details?.availability?.commitment ===
                    "No - I or someone from my team/organization cannot commit any time to participating in the innovation challenge."
                  }
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      availability: {
                        ...details?.availability,
                        commitment: e.target.value,
                      },
                    })
                  }
                />{" "}
                No - I or someone from my team/organization cannot commit any
                time to participating in the innovation challenge.
              </label>
            </div>
          </div>
        </div>
      ) : null}

      {success && (
        <p className="text-green-500 font-bold text-center">{success}</p>
      )}
      <div className="flex justify-between">
        <div
          className="flex gap-2 items-center justify-center cursor-pointer bg-black px-4 py-2 rounded-md text-white"
          onClick={handleSave}
        >
          <p>Save</p>
          {/* <FaSave /> */}
        </div>
        <div className="flex gap-4">
          <div
            className="flex gap-2 items-center justify-center cursor-pointer bg-secondary px-4 py-2 rounded-md text-white"
            onClick={handlePrev}
          >
            <FaCaretLeft />
            <p>Prev</p>
          </div>
          <div
            className="flex gap-2 items-center justify-center cursor-pointer bg-secondary px-4 py-2 rounded-md text-white"
            onClick={handleNext}
          >
            <p>Submit</p>
            {/* <FaCaretRight /> */}
          </div>
        </div>
      </div>
      <Toast />
    </div>
  );
}
