import React from "react";
import circle from "../../assets/circle.png";
import AllResources from "./AllResources";

export default function Resources() {
  return (
    <div className="relative md:w-full py-20 mx-4">
      <AllResources />
      <img src={circle} className="absolute bottom-0 right-0 z-0" />
    </div>
  );
}
