import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export default function Navigation() {
  let [showNav, setShowNav] = useState(true);
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto relative">
        {!showNav ? (
          <div
            className="absolute top-4 right-4 md:hidden cursor-pointer  "
            onClick={() => setShowNav(true)}
          >
            <FaBars className="fill-secondary w-6 h-6" />
          </div>
        ) : null}
        <div
          className={`flex gap-4 flex-col md:flex-row items-center justify-between py-6 relative ${
            !showNav ? "hidden" : "flex"
          }`}
        >
          <div
            className="fill-secondary  absolute top-4 right-4 md:hidden cursor-pointer"
            onClick={() => setShowNav(false)}
          >
            {/* <FaBars /> */}
            <FaTimes className="fill-secondary" />
          </div>
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              isActive ? "font-bold border-b-2 border-black" : ""
            }
          >
            <p>About The Program</p>
          </NavLink>
          <NavLink
            to="/challenge-background"
            className={({ isActive, isPending }) =>
              isActive ? "font-bold  border-b-2 border-black" : ""
            }
          >
            <p>The Challenge</p>
          </NavLink>
          {/* <NavLink
          to="/the-challenge"
          className={({ isActive, isPending }) =>
            isActive ? "font-bold  border-b-2 border-black" : ""
          }
        >
          <p>The Challenge</p>
        </NavLink> */}
          <NavLink
            to="/solution-areas"
            className={({ isActive, isPending }) =>
              isActive ? "font-bold  border-b-2 border-black" : ""
            }
          >
            <p>Solution Areas</p>
          </NavLink>
          <NavLink
            to="/eligibility"
            className={({ isActive, isPending }) =>
              isActive ? "font-bold  border-b-2 border-black" : ""
            }
          >
            <p>Eligibility</p>
          </NavLink>
          <NavLink
            to="/why-join"
            className={({ isActive, isPending }) =>
              isActive ? "font-bold  border-b-2 border-black" : ""
            }
          >
            <p>Why Join</p>
          </NavLink>
          <NavLink
            to="/timeline"
            className={({ isActive, isPending }) =>
              isActive ? "font-bold  border-b-2 border-black" : ""
            }
          >
            <p>Timeline</p>
          </NavLink>
          <NavLink
            to="/partners"
            className={({ isActive, isPending }) =>
              isActive ? "font-bold  border-b-2 border-black" : ""
            }
          >
            <p>Partners</p>
          </NavLink>
          <NavLink
            to="/news-events"
            className={({ isActive, isPending }) =>
              isActive ? "font-bold border-b-2 border-black" : ""
            }
          >
            <p>News and Events</p>
          </NavLink>
          <NavLink
            to="/contact-us"
            className={({ isActive, isPending }) =>
              isActive ? "font-bold  border-b-2 border-black" : ""
            }
          >
            <p>Contact</p>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
