import whyJoin1 from "../../../assets/whyjoin1.jpg";
import whyJoin2 from "../../../assets/whyjoin2.jpg";
import whyJoin3 from "../../../assets/whyjoin3.jpg";
import whyJoin4 from "../../../assets/whyjoin4.jpg";
import whyJoin5 from "../../../assets/whyjoin5.jpg";

export default function WhyJoin() {
  return (
    <div className="py-28 bg-[#212121] why_join px-10 relative ">
      <div className="max-w-7xl mx-auto text-black">
        <h2 className="text-2xl md:text-3xl text-center font-bold  pb-6 text-white">
          Why Join?
        </h2>
        <p className="font-bold text-white text-center">
          An open call for new and existing impactful solutions to enhance the
          competitiveness of employability of women garment workers.
        </p>
      </div>

      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8 pt-10 card_container">
        {whyJoinData.map((card) => (
          <div className="card col-span-1" key={card?.id}>
            <div className="front p-2 bg-white">
              <img
                src={card?.img}
                alt=""
                className="rounded-lg w-full h-[190px] object-cover"
              />
              <p className="text-[16px] pt-4 text-center font-bold">
                {card?.heading}
              </p>
            </div>
            <div className="back">
              <p className="font-bold text-xl p-2 text-center text-[14px]">
                {card?.details}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

let whyJoinData = [
  {
    id: 1,
    heading: "Access to Residential Bootcamp",
    details:
      "Get access to customized curriculum and develop your business model.",
    img: whyJoin1,
  },
  {
    id: 2,
    heading: "Pollination Grant for Implementation",
    details: "Avail up to 650,000 BDT of funding for R&D.",
    img: whyJoin2,
  },
  {
    id: 3,
    heading: "Coaching and Mentoring",
    details: "Receive in-person mentorship by industry experts.",
    img: whyJoin3,
  },
  {
    id: 4,
    heading: "Access to Investor Network",
    details: "Gain access to wide network of RMG investors.",
    img: whyJoin4,
  },
  {
    id: 5,
    heading: "Prototyping and Piloting Opportunity",
    details:
      "Unleash your innovation into the market through funded prototyping and piloting opportunity",
    img: whyJoin5,
  },
];
