import React from "react";
import MatureStartupProgrss from "./MatureStartupProgrss";
import circle from "../../../assets/circle.png";
import MatureApplications from "./MatureApplications";

export default function MatureStartupDetails() {
  return (
    <div className="relative md:w-full py-20 mx-4">
      <MatureStartupProgrss />
      <MatureApplications />
      <img src={circle} className="absolute bottom-0 right-0 z-0" alt="" />
    </div>
  );
}
