import React from "react";

export default function NewsEventsNavigation({ setCategory, category }) {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center w-fit mx-auto pb-4 border-b border-gray-500">
      <p
        className={
          category === ""
            ? "bg-secondary text-white rounded-t-xl px-2 md:px-10 py-2 cursor-pointer"
            : "text-black rounded-t-xl px-10 py-2 cursor-pointer font-semibold"
        }
        onClick={() => setCategory("")}
      >
        All Blogs
      </p>
      <p
        className={
          category === "News"
            ? "bg-secondary text-white rounded-t-xl px-2 md:px-10 py-2 cursor-pointer"
            : "text-black rounded-t-xl px-10 py-2 cursor-pointer font-semibold"
        }
        onClick={() => setCategory("News")}
      >
        News
      </p>
      <p
        className={
          category === "Events"
            ? "bg-secondary text-white rounded-t-xl px-2 md:px-10 py-2 cursor-pointer"
            : "text-black rounded-t-xl px-10 py-2 cursor-pointer font-semibold"
        }
        onClick={() => setCategory("Events")}
      >
        Events
      </p>
      <p
        className={
          category === "Announcements"
            ? "bg-secondary text-white rounded-t-xl px-2 md:px-10 py-2 cursor-pointer"
            : "text-black rounded-t-xl px-2 md:px-10 py-2 cursor-pointer font-semibold"
        }
        onClick={() => setCategory("Announcements")}
      >
        Announcements
      </p>
      <p
        className={
          category === "Case Study"
            ? "bg-secondary text-white rounded-t-xl px-2 md:px-10 py-2 cursor-pointer"
            : "text-black rounded-t-xl px-2 md:px-10 py-2 cursor-pointer font-semibold"
        }
        onClick={() => setCategory("Case Study")}
      >
        Case Study
      </p>
    </div>
  );
}
