import React from "react";
import { FaCheck, FaLink } from "react-icons/fa";

export default function AdminProjectMatureTable({
  projects,
  showModal,
  setShowModal,
}) {
  const progressTracker = (user) => {
    let progress;
    if (user?.availability?.commitment && user?.availability?.attendBootcamp) {
      progress = Math.round((5 / 5) * 100);
      return progress;
    }
    if (
      user?.environmentalImpact?.businessModel &&
      user?.environmentalImpact?.challengeToSolve &&
      user?.environmentalImpact?.enablesFuture &&
      user?.environmentalImpact?.environmentalGoal &&
      user?.environmentalImpact?.stakeholders?.length &&
      user?.environmentalImpact?.workImpact
    ) {
      progress = Math.round((4 / 5) * 100);
      return progress;
    }
    if (
      user?.Solution?.businessModel &&
      user?.Solution?.businessModel &&
      user?.Solution?.competitiveness &&
      user?.Solution?.focusArea &&
      user?.Solution?.keyPartner &&
      user?.Solution?.pitchDeck &&
      user?.Solution?.problemAndPotential &&
      user?.Solution?.problemSolution &&
      user?.Solution?.revenueModel &&
      user?.Solution?.solution &&
      user?.Solution?.stage
    ) {
      progress = Math.round((3 / 5) * 100);
      return progress;
    }
    if (
      user?.applicantProfile?.enterpriseName &&
      user?.applicantProfile?.enterpriseFounded &&
      user?.applicantProfile?.enterpriseHeadquartered &&
      user?.applicantProfile?.enterpriseLocation &&
      user?.applicantProfile?.enterpriseInvestment &&
      user?.applicantProfile?.role &&
      user?.applicantProfile?.enterprisePeople &&
      user?.applicantProfile?.aboutTeam &&
      user?.applicantProfile?.acceleratorsParticipate
    ) {
      progress = Math.round((2 / 5) * 100);
      return progress;
    }
    if (
      user?.basicInfo?.fullName &&
      user?.basicInfo?.age &&
      user?.basicInfo?.gender &&
      user?.basicInfo?.phoneNumber
    ) {
      progress = Math.round((1 / 5) * 100);
      return progress;
    } else {
      return 0;
    }
  };
  return (
    <div className="my-8">
      <div className="w-full">
        <div className="max-w-full overflow-x-auto">
          <table className="w-full table-auto" id="table-to-xls">
            <thead>
              <tr className="text-left text-dark font-bold bg-[#D9D9D9]">
                <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Progress
                </th>
                <th className="w-2/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Projects
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Entrepreneurs
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Email
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Gender
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Phone
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  EnterpriseName (Profile)
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  EnterpriseFounded (Profile)
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  EnterpriseHeadquartered (Profile)
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  EnterpriseRegistered (Profile)
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  EnterpriseRegisteredYear (Profile)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  enterpriseLocation (Profile)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  EnterpriseInvestment (Profile)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Role (Profile)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  EnterprisePeople (Profile)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  About Team (Profile)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  AcceleratorsParticipate (Profile)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Focus Area (Solution)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  problem Solution (Solution)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  problem And Potential (Solution)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  solution (Solution)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Current Stage (Solution)
                </th>

                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Business Model (Solution)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Revenue Model (Solution)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  proposition competitiveness (Solution)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  have Key partner (Solution)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Partner Name (Solution)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Pitch Deck (Solution)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  SDGGoals (Environmental)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  BusinessModel (Environmental)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  ChallengeToSolve (Environmental)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  EnvironmentalGoal (Environmental)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  SocialImpact (Environmental)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Stakeholders (Environmental)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  WorkImpact (Environmental)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  AttendBootcamp (Availability)
                </th>
                <th className="w-2/6 min-w-[500px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Commitment (Availability)
                </th>
              </tr>
            </thead>
            <tbody>
              {projects?.map((project, id) => (
                <tr
                  className={`border-t border-[#ddd] text-left text-dark py-4 ${
                    id % 2 === 0 ? "" : "bg-[#D9D9D9]"
                  }`}
                >
                  <td className="py-6 px-8  text-base">
                    {progressTracker(project)}%
                  </td>
                  <td className="py-6 px-8  text-base">
                    <p>{project?.applicantProfile?.enterpriseName}</p>
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.basicInfo?.fullName}
                  </td>
                  <td className="py-6 px-8  text-base">{project?.email}</td>
                  <td className="py-6 px-8  text-base">
                    {project?.basicInfo?.gender}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.basicInfo?.phoneNumber}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.applicantProfile?.enterpriseName}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.applicantProfile?.enterpriseFounded}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.applicantProfile?.enterpriseHeadquartered}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.applicantProfile?.enterpriseRegistered}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.applicantProfile?.enterpriseRegisteredYear}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.applicantProfile?.enterpriseLocation}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.applicantProfile?.enterpriseInvestment?.substring(
                      0,
                      20
                    )}
                    ...
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.applicantProfile?.role}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.applicantProfile?.enterprisePeople}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.applicantProfile?.aboutTeam}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.applicantProfile?.acceleratorsParticipate}
                  </td>

                  <td className="py-6 px-8  text-base">
                    {project?.Solution?.focusArea}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.Solution?.problemSolution}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.Solution?.problemAndPotential}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.Solution?.solution}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.Solution?.stage}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.Solution?.businessModel}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.Solution?.revenueModel}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.Solution?.competitiveness}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.Solution?.keyPartner}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.Solution?.partnerName}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {/* <a href= target="_blank">
                      <FaLink />
                    </a> */}
                    {project?.Solution?.pitchDeck}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.environmentalImpact?.SDGGoals}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.environmentalImpact?.businessModel}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.environmentalImpact?.challengeToSolve}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.environmentalImpact?.environmentalGoal}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.environmentalImpact?.socialImpact}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.environmentalImpact?.stakeholders?.map(
                      (holder) => (
                        <div>
                          <p>
                            <span className="font-bold">Name: </span>
                            {holder?.name}
                          </p>
                          <p>
                            <span className="font-bold">Benefits: </span>
                            {holder?.needAndBenefits}
                          </p>
                        </div>
                      )
                    )}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.environmentalImpact?.workImpact}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.availability?.attendBootcamp}
                  </td>
                  <td className="py-6 px-8  text-base">
                    {project?.availability?.commitment}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
