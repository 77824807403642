import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState, useRef } from "react";
import { projectStorage } from "../../../firebase.config";
import { useFirestore } from "../../../hooks/useFirestore";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../../shared/AdminLayout";
import RichTextEditor from "./Components/RichTextEditor";

import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import { blogSample } from "./blogSample";

export default function AdminAddNewBlog() {
  // eslint-disable-next-line no-mixed-operators
  const emailEditorRef = useRef(null);

  let [data, setData] = useState("");

  let [blog, setBlog] = useState({
    title: "",
    description: "",
    category: "",
    tags: [],
    image: "",
    eventType: "",
    eventVanue: "",
    eventData: "",
  });

  const [fileProgress, setFileProgress] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [tag, setTag] = useState("");
  const [tagErr, setTagErr] = useState("");

  const [blogContent, setBlogContent] = useState("");

  const types = ["image/png", "image/jpg", "image/jpeg"];

  const handleFile = (e) => {
    let selected = e.target.files[0];

    if (selected && !types.includes(selected.type)) {
      setError("Please select a file (pdf ) for case study");
      return;
    }
    const storageRef = ref(projectStorage, `files/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setFileProgress(progress);
      },
      (error) => {
        setError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setBlog({ ...blog, image: downloadURL });
        });
      }
    );
  };

  const { addDocument, response } = useFirestore("blogs");
  const navigate = useNavigate();

  const handleTag = () => {
    if (blog.tags?.includes(tag)) {
      setTagErr("Already added this tag");
      return;
    }
    setBlog({ ...blog, tags: [...blog?.tags, tag] });
    setTag("");
  };

  useEffect(() => {
    if (response?.success) {
      setSuccess("Successfully added blog.");
      setError("");
      setTimeout(() => {
        navigate("/admin/blogs");
      }, 1000);
    }
  }, [response?.success]);

  const handleSubmit = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { design, html } = data;

      if (
        !response.isLoading &&
        blog?.title &&
        blog?.image &&
        blog?.category &&
        html
      ) {
        addDocument({ ...blog, description: html });
      } else {
        setError("please Fill all the required field.");
        setSuccess("");
      }
    });
  };

  let onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
  };

  const onLoad: EmailEditorProps["onLoad"] = (unlayer) => {
    console.log("onLoad", unlayer);
    unlayer.addEventListener("design:loaded", onDesignLoad);
    unlayer.loadDesign(blogSample);
  };

  const onReady: EmailEditorProps["onReady"] = (unlayer) => {
    console.log("onReady", unlayer);
  };

  return (
    <AdminLayout>
      <div className="relative md:w-full py-20 mx-4">
        <div className="relative max-w-7xl mx-auto bg-white p-4 md:p-10 rounded-md z-10">
          <div className="bg-white md:w-full p-10 rounded-md">
            <div>
              <h2 className="text-3xl font-bold text-center pb-2 border-b-2 border-gray-200">
                Add Blog
              </h2>
              <p className="pt-6">Fill in the details to get started</p>
              <div>
                <div className="pt-6">
                  <div className=" w-full flex flex-col gap-2">
                    <label for="fHeadline" className="font-bold">
                      HEADLINE <span className="text-secondary">*</span>
                    </label>
                    <input
                      type="text"
                      name="fHeadline"
                      id="fHeadline"
                      value={blog?.title}
                      onChange={(e) =>
                        setBlog({ ...blog, title: e.target.value })
                      }
                      placeholder="Write Article Heading"
                      className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-6 my-6">
                  <div className=" w-full flex flex-col gap-2">
                    <label for="fName" className="font-bold">
                      UPLOAD BLOG IMAGE{" "}
                      <span className="text-secondary">*</span>
                    </label>
                    <input
                      type="file"
                      name="fName"
                      id="fName"
                      onChange={handleFile}
                      placeholder="Choose a Photo"
                      className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-6 md:gap-20 my-6">
                  <div className=" w-full flex flex-col gap-2">
                    <label for="fName" className="font-bold">
                      CATEGORY <span className="text-secondary">*</span>
                    </label>
                    <select
                      className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                      value={blog?.category}
                      onChange={(e) =>
                        setBlog({ ...blog, category: e.target.value })
                      }
                    >
                      <option value="">Choose your category</option>
                      <option value="News">News</option>
                      <option value="Events">Events</option>
                      <option value="Announcements">Announcements</option>
                    </select>
                  </div>
                </div>
                {blog?.category === "Events" ? (
                  <>
                    <div className="w-full flex flex-col md:flex-row gap-6 md:gap-20 my-6">
                      <div className=" w-full flex flex-col gap-2">
                        <div className=" w-full flex flex-col gap-2">
                          <label for="fName" className="font-bold">
                            EVENT TYPE <span className="text-secondary">*</span>
                          </label>
                          <select
                            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                            value={blog?.eventType}
                            onChange={(e) =>
                              setBlog({ ...blog, eventType: e.target.value })
                            }
                          >
                            <option value="">Choose event type</option>
                            <option value="News">Online</option>
                            <option value="Events">Offline</option>
                          </select>
                        </div>
                      </div>
                      <div className=" w-full flex flex-col gap-2">
                        <div className=" w-full flex flex-col gap-2">
                          <label for="eVanue" className="font-bold">
                            EVENT VANUE{" "}
                            <span className="text-secondary">*</span>
                          </label>
                          <input
                            type="text"
                            name="eVanue"
                            id="eVanue"
                            value={blog?.eventVanue}
                            onChange={(e) =>
                              setBlog({ ...blog, eventVanue: e.target.value })
                            }
                            placeholder="Write Article Heading"
                            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-6 md:gap-20 my-6">
                      <div className=" w-full flex flex-col gap-2">
                        <div className=" w-full flex flex-col gap-2">
                          <label for="fName" className="font-bold">
                            EVENT DATE <span className="text-secondary">*</span>
                          </label>
                          <input
                            type="date"
                            name="eVanue"
                            id="eVanue"
                            value={blog?.eventData}
                            onChange={(e) =>
                              setBlog({ ...blog, eventData: e.target.value })
                            }
                            placeholder="Write Article Heading"
                            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="w-full flex flex-col  gap-6 my-6">
                  <div className="flex gap-4">
                    {blog?.tags?.length
                      ? blog?.tags?.map((tag, id) => (
                          <p className="bg-gray-500 px-4 py-2 text-white rounded-md">
                            {tag}
                          </p>
                        ))
                      : null}
                  </div>
                  <div className=" w-full flex flex-col gap-2">
                    <label for="fTags" className="font-bold">
                      TAGS <span className="text-secondary">*</span>
                    </label>
                    <input
                      type="text"
                      name="fTags"
                      id="fTags"
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                      placeholder="Write Article Heading"
                      className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                    />
                  </div>
                  <button
                    className="px-8 w-[100px] py-2 text-white bg-secondary rounded-tl-xl rounded-br-xl cursor-pointer border-2 border-primary  text-[18px] shadow-btn btn flex items-center border-none hover:bg-secondary"
                    onClick={handleTag}
                  >
                    Add
                  </button>
                  {tagErr && <p className="text-red-500">{tagErr}</p>}
                </div>

                {/* Editor */}
                <div className="">
                  <EmailEditor
                    ref={emailEditorRef}
                    onLoad={onLoad}
                    onReady={onReady}
                  />

                  {/* <div className=" w-full flex flex-col gap-2">
                    <label for="fHeadline" className="font-bold">
                      DESCRIPTION <span className="text-secondary">*</span>
                    </label>
                    <textarea
                      className="h-[160px] bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                      value={blog?.descripion}
                      onChange={(e) =>
                        setBlog({
                          ...blog,
                          descripion: e.target.value,
                        })
                      }
                      placeholder="Describe Your Article"
                    />
                  </div> */}
                </div>
              </div>
              {error && <p className="text-red-500">{error}</p>}
              {success && <p className="text-green-500">{success}</p>}
              <button
                className="bg-secondary rounded-tr-2xl rounded-bl-2xl px-4 py-2 text-white mt-4"
                onClick={handleSubmit}
              >
                PUBLISH
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
