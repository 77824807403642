// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "@firebase/auth";
import { getFirestore, Timestamp } from "firebase/firestore"
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCtY992wzH54ORS6nFRkyGzslI4i2sCUNU",
  authDomain: "innovation-fund-c6e2e.firebaseapp.com",
  projectId: "innovation-fund-c6e2e",
  storageBucket: "innovation-fund-c6e2e.appspot.com",
  messagingSenderId: "455575171141",
  appId: "1:455575171141:web:08e77f6677ce929b5f332c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const timeStamp = Timestamp;
export const projectStorage = getStorage();