import React, { useState } from "react";
import ScrollToTop from "../../shared/utils/ScrollToTop";
import Layout from "../../shared/Layout";
import Criteria from "./Components/Criteria";
import Modal from "../../shared/Modal";
import TermAndCondition from "./Components/TermAndCondition";

export default function Eligibility() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ScrollToTop />
      <Layout>
        <Criteria setShowModal={setShowModal} />
      </Layout>
      {showModal && (
        <Modal setShowModal={setShowModal}>
          <TermAndCondition />
        </Modal>
      )}
    </>
  );
}
