import React from "react";
import { FaCaretDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import Needlelogo from "../../assets/logo2.png";

export default function AdminTopbar() {
  return (
    <div className="bg-white px-8 flex justify-between items-center py-4">
      <Link to="/">
        <img src={Needlelogo} alt="Needle" className="w-[80px] h-auto" />
      </Link>
      <div className="flex gap-2 items-center">
        {/* <img src={profile} alt="" /> */}
        <p>Admin</p>
        <FaCaretDown />
      </div>
    </div>
  );
}
