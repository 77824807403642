import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { projectStorage } from "../../../firebase.config";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "../../../hooks/useFirestore";
import AdminLayout from "../../../shared/AdminLayout";

export default function AdminAddResource() {
  let [resource, setResource] = useState({
    title: "",
    descripion: "",
    pdf: "",
  });

  const [fileProgress, setFileProgress] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const types = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const handleFile = (e) => {
    let selected = e.target.files[0];

    if (selected && !types.includes(selected.type)) {
      setError("Please select a file (pdf, pptx ) for case study");
      return;
    }
    const storageRef = ref(projectStorage, `files/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setFileProgress(progress);
      },
      (error) => {
        setError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setResource({ ...resource, pdf: downloadURL });
        });
      }
    );
  };

  const { addDocument, response } = useFirestore("resources");
  const navigate = useNavigate();

  useEffect(() => {
    if (response?.success) {
      setSuccess("Successfully added casae study.");
      setError("");
      setTimeout(() => {
        navigate("/admin/resources");
      }, 1000);
    }
  }, [response?.success]);

  const handleSubmit = () => {
    if (
      !response.isLoading &&
      resource?.title &&
      resource?.descripion &&
      resource?.pdf
    ) {
      addDocument(resource);
    } else {
      setError("please Fill all the required field.");
      setSuccess("");
    }
  };

  return (
    <AdminLayout>
      <div className="relative md:w-full py-20 mx-4">
        <div className="relative max-w-5xl mx-auto bg-white p-4 md:p-10 rounded-md z-10">
          <div className="bg-white md:w-[900px] p-10 rounded-md">
            <div>
              <h2 className="text-3xl font-bold text-center pb-2 border-b-2 border-gray-200">
                Add Resource
              </h2>
              <p className="pt-6">Fill in the details to get started</p>
              <div>
                <div className="pt-6">
                  <div className=" w-full flex flex-col gap-2">
                    <label for="fHeadline" className="font-bold">
                      HEADLINE <span className="text-secondary">*</span>
                    </label>
                    <input
                      type="text"
                      name="fHeadline"
                      id="fHeadline"
                      value={resource?.title}
                      onChange={(e) =>
                        setResource({ ...resource, title: e.target.value })
                      }
                      placeholder="Write Article Heading"
                      className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-6 my-6">
                  <div className=" w-full flex flex-col gap-2">
                    <label for="fName" className="font-bold">
                      UPLOAD CASE STUDY (PDF format){" "}
                      <span className="text-secondary">*</span>
                    </label>
                    <input
                      type="file"
                      name="fName"
                      id="fName"
                      onChange={handleFile}
                      placeholder="Choose a Photo"
                      className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="">
                  <div className=" w-full flex flex-col gap-2">
                    <label for="fHeadline" className="font-bold">
                      DESCRIPTION <span className="text-secondary">*</span>
                    </label>
                    <textarea
                      className="h-[160px] bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                      value={resource?.descripion}
                      onChange={(e) =>
                        setResource({
                          ...resource,
                          descripion: e.target.value,
                        })
                      }
                      placeholder="Describe Your Article"
                    />
                  </div>
                </div>
              </div>
              {error && <p className="text-red-500">{error}</p>}
              {success && <p className="text-green-500">{success}</p>}
              <button
                className="bg-secondary rounded-tr-2xl rounded-bl-2xl px-4 py-2 text-white mt-4"
                onClick={handleSubmit}
              >
                PUBLISH
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
