import React from "react";

export default function ProcessNavigation({ category, setCategory }) {
  return (
    <div className="flex justify-center items-center pt-10 w-fit mx-auto pb-4 border-b border-gray-500">
      <p
        className={
          category === "Idea Stage Solution"
            ? "bg-secondary text-white rounded-t-xl text-sm px-4 md:px-10 py-2 cursor-pointer"
            : "text-black rounded-t-xl text-sm px-4 md:px-10 py-2 cursor-pointer font-semibold"
        }
        onClick={() => setCategory("Idea Stage Solution")}
      >
        Idea Stage Solution
      </p>
      <p
        className={
          category === "Existing Solution"
            ? "bg-secondary text-white rounded-t-xl text-sm px-4 md:px-10 py-2 cursor-pointer"
            : "text-black rounded-t-xl text-sm px-4 md:px-10 py-2 cursor-pointer font-semibold"
        }
        onClick={() => setCategory("Existing Solution")}
      >
        Existing Solution
      </p>
    </div>
  );
}
