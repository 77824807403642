import React from "react";
import Heading from "../../../shared/Heading";
import partner1 from "../../../assets/partner1.png";
import partner2 from "../../../assets/partner2.png";
import partner3 from "../../../assets/partner3.png";
import partner4 from "../../../assets/partner4.png";
import partner5 from "../../../assets/partner5.png";
import partner6 from "../../../assets/partner6.png";
import partner7 from "../../../assets/partner7.png";
import partner8 from "../../../assets/SBL_Logo.png";
import { motion } from "framer-motion";

export default function AllPertners() {
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className="py-20"
    >
      {/* <Heading firstHeading="Our" secondHeading="Partners" /> */}

      <div className=" py-10 px-4">
        <h1 className="text-secondary font-bold text-3xl text-center mb-10">
          {/* Proud Partner of */}
          PROGRAMME
        </h1>

        <div className="max-w-5xl mx-auto mb-10">
          <div className="relative group  bg-white overflow-hidden p-2  md:p-0 md:py-10 rounded-md  shadow-md  flex flex-col justify-center items-center h-full">
            <img src={partner1} alt="Partner" className="w-[220px] h-auto" />
            <div className=" text-black group-hover:flex justify-center items-center p-4 text-center	">
              Needle Innovation Challenge is a part of Oporajita- Collective
              Impact on Future of Work in Bangladesh is a collaborative
              initiative among 14 organizations to equip women garment workers
              in Bangladesh for a future defined by automation and
              digitalization. Initiated by H&amp;M Foundation, and supported by
              Sweden and The Asia Foundation, the initiative follows the
              Collective Impact approach, and aims to holistically address
              challenges to women’s future employment opportunities and
              livelihoods – and achieve transformative change for the industry
              and the people working within. The initiative covers multiple
              aspects within thematic areas that impact the lives of women
              garment workers, such as providing future-fit skill training,
              cultivating an enabling environment that supports their future
              aspiration and fostering collaboration in an RMG sector that is
              inclusive of their choices.
            </div>
          </div>
        </div>

        <h1 className="text-secondary font-bold text-3xl text-center mb-10">
          {/* Organized by */}
          RUN BY
        </h1>

        <div className="max-w-5xl mx-auto mb-10">
          <div className="relative group  bg-white overflow-hidden p-2  md:p-0 md:py-10 rounded-md  shadow-md  flex flex-col justify-center items-center h-full">
            <a href="https://buildbangladesh.com.bd/" target="_blank">
              <img src={partner2} alt="Partner" className="w-[220px] h-auto" />
            </a>
            <div className=" text-black group-hover:flex justify-center items-center p-4 text-center">
              Build Bangladesh is driving the Needle Innovation Challenge to
              find innovative idea and existing startups to expedite the
              competitiveness of the Bangladesh RMG industry, enhance the
              employability of women garment workers and accelerate the
              industry’s transition to a circular economy.
            </div>
          </div>
        </div>

        <h1 className="text-secondary font-bold text-3xl text-center mb-10">
          {/* Supported by */}
          SUPPORTED BY
        </h1>

        <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2  gap-10 md:gap-28  my-10">
          <div className="relative group bg-white p-2 md:p-0  rounded-md shadow-md flex justify-center items-center overflow-hidden h-[250px]">
            <img src={partner3} alt="Partner" className="w-[220px] h-auto" />
            <a href="https://hmfoundation.com/" target="_blank">
              <div className="group-hover:absolute hidden top-0 right-0 bottom-0 left-0 bg-secondary/75 text-center text-white group-hover:flex justify-center items-center p-4">
                H&amp;M Foundation is the initiating funder of Oporajita:
                Collective Impact on Future of Work in Bangladesh
              </div>
            </a>
          </div>

          <div className="relative group  bg-white overflow-hidden p-2 md:p-0  rounded-md  shadow-md  flex justify-center items-center h-[250px]">
            <img src={partner4} alt="Partner" className="w-[280px] h-auto" />
            <a href="https://asiafoundation.org/" target="_blank">
              <div className="group-hover:absolute hidden top-0 right-0 bottom-0 left-0 bg-secondary/75 text-center text-white group-hover:flex justify-center items-center p-4">
                The Asia Foundation operates as the backbone organization for
                Oporajita, acting as a facilitator to guide the collective
                strategy of the initiative.
              </div>
            </a>
          </div>
        </div>

        <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2  gap-10 md:gap-28  my-10">
          <div className="relative group  bg-white overflow-hidden p-2 md:p-0  rounded-md  shadow-md flex justify-center items-center  h-[250px]">
            <img src={partner6} alt="Partner" className="w-[280px] h-auto" />
            <div className="group-hover:absolute hidden top-0 right-0 bottom-0 left-0 bg-secondary/75 text-center text-white group-hover:flex justify-center items-center p-4 ">
              The Bangladesh Garment Manufacturers and Exporters Association
              (BGMEA) is the peak trade association in the country representing
              the readymade garment industry.
            </div>
          </div>

          <div className="relative group  bg-white overflow-hidden p-2 md:p-0  rounded-md  shadow-md  flex justify-center items-center h-[250px]">
            <img src={partner8} alt="Partner" className="w-[280px] h-auto" />
            <a href="https://www.startupbangladesh.vc/" target="_blank">
              <div className="group-hover:absolute hidden top-0 right-0 bottom-0 left-0 bg-secondary/75 text-center text-white group-hover:flex justify-center items-center p-4">
                Startup Bangladesh Limited is the first and only flagship
                venture capital fund of ICT Division sponsored by the Bangladesh
                Government.
              </div>
            </a>
          </div>
        </div>

        <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2  gap-10 md:gap-28  my-10">
          <div className="relative group  bg-white overflow-hidden p-2 md:p-0 md:py-6 rounded-md  shadow-md  flex justify-center items-center   h-[250px]">
            <img src={partner5} alt="Partner" className="w-[220px] h-auto" />
            <a href="https://www.circularapparel.co/" target="_blank">
              <div className="group-hover:absolute hidden top-0 right-0 bottom-0 left-0 bg-secondary/75 text-center text-white group-hover:flex justify-center items-center p-4">
                The Circular Apparel Innovation Factory (CAIF) is a pioneering
                initiative by Intellecap with the objective of driving the
                clothing &amp; textile sector to become circular. CAIF is also
                one of the 14 partners Oporajita- Collective Impact on Future of
                Work in Bangladesh
              </div>
            </a>
          </div>

          <div className="relative group  bg-white overflow-hidden p-2 md:p-0  rounded-md  shadow-md flex justify-center items-center  h-[250px]">
            <img src={partner7} alt="Partner" className="w-[200px] h-auto" />
            <div className="group-hover:absolute hidden top-0 right-0 bottom-0 left-0 bg-secondary/75 text-center text-white group-hover:flex justify-center items-center p-4 ">
              Bangladesh Women Chamber of Commerce and Industry is the peak
              association of women entrepreneurs and business leaders.
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
