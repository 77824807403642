import React from "react";
import Layout from "../../shared/Layout";
import WhyJoinProcess from "./Components/WhyJoinProcess";
import ScrollToTop from "../../shared/utils/ScrollToTop";

export default function WhyJoin() {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <WhyJoinProcess />
      </Layout>
    </>
  );
}
