import React from "react";
import Layout from "../../shared/Layout";
import ContactForm from "./Components/ContactForm";
import ScrollToTop from "../../shared/utils/ScrollToTop";

export default function Contactus() {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <ContactForm />
      </Layout>
    </>
  );
}
