import React, { useEffect, useState } from "react";
import { useCollection } from "../../../../hooks/useCollection";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import AdminBlogNavbar from "./AdminBlogNavbar";
import AdminBlogtable from "./AdminBlogtable";

export default function AdminBlogDetails() {
  const [blogPhase, setBlogPhase] = useState("");
  const [allBlogs, setAllBlogs] = useState([]);

  const { document, isLoading } = useCollection("blogs");

  useEffect(() => {
    if (blogPhase === "") {
      setAllBlogs(document);
    } else {
      let filteredProject = document?.filter(
        (project) => project?.category === blogPhase
      );
      setAllBlogs(filteredProject);
    }
  }, [blogPhase, document]);

  return (
    <div className="relative md:w-full py-20 mx-4">
      <div className="relative max-w-5xl mx-auto bg-white p-4 md:p-10 rounded-md z-10">
        <div className="pb-6 flex justify-between">
          <h2 className="font-bold text-xl">Resources</h2>
          <Link to="/admin/add-blog">
            <div className="flex items-center gap-2 cursor-pointer">
              <FaPlus />
              <p>Add Blog</p>
            </div>
          </Link>
        </div>
        <div className="p-4">
          <AdminBlogNavbar setBlogPhase={setBlogPhase} blogPhase={blogPhase} />
          <AdminBlogtable blogs={allBlogs} />
        </div>
      </div>
    </div>
  );
}
