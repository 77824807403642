import React, { useEffect, useState } from "react";
import { FaEyeSlash, FaFacebookMessenger, FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authDetail, loginUser } from "../../features/auth/authSlice";
import { motion } from "framer-motion";
import { useCollection } from "../../hooks/useCollection";
import needle from "../../assets/logo2.png";
import { firebaseErrorHandle } from "../../shared/utils/firebaseErrorHandle";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase.config";

export default function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);

  const {
    email: logedInUser,
    isLoading,
    isError,
    error,
  } = useSelector(authDetail);
  const { document, isLoading: documentLoading } =
    useCollection("entrepreneur");

  const navigate = useNavigate();

  useEffect(() => {
    const filteredUser = document?.filter(
      (user) => user?.email === logedInUser
    );

    if (filteredUser[0]?.stage === "Mature") {
      navigate("/mature-startup");
    } else if (filteredUser[0]?.stage === "Idea") {
      navigate("/idea-startup");
    }
  }, [logedInUser, document]);

  const dispatch = useDispatch();

  const handleSingnin = (e) => {
    dispatch(loginUser({ email, password }));
  };

  return (
    <div className="min-h-screen text-white bg-primary p-10 flex flex-col justify-center items-center">
      <div className="w-full">
        <div
          className="bg-white max-w-2xl mx-auto rounded-xl text-black px-12 py-8"
          style={{ boxShadow: "12px 15px 30px rgba(234, 51, 82, 0.4)" }}
        >
          <div className="flex justify-center items-center border-b-2 border-b-gray-300  pb-4">
            <Link to="/">
              <img src={needle} alt="Needle" className="w-[80px] h-auto" />
            </Link>
          </div>
          <div className="py-4">
            <p>WELCOME BACK!</p>
          </div>
          <div className="pt-6">
            <div className=" w-full flex flex-col gap-2">
              <label for="fEmail" className="font-bold">
                EMAIL ADDRESS <span>*</span>
              </label>
              <input
                type="text"
                name="fEmail"
                id="fEmail"
                placeholder="Your Email"
                className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="pt-6">
            <div className=" w-full flex flex-col gap-2">
              <label for="fPass" className="font-bold">
                PASSWORD (min. 8 characters) <span>*</span>
              </label>
              <div className="w-full relative">
                <input
                  type={showPass ? "text" : "password"}
                  name="fPass"
                  id="fPass"
                  placeholder="Your Password"
                  className="w-full bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {showPass ? (
                  <FaEye
                    className="absolute top-1/2 right-[15px] -translate-y-1/2"
                    onClick={() => setShowPass(false)}
                  />
                ) : (
                  <FaEyeSlash
                    className="absolute top-1/2 right-[15px] -translate-y-1/2"
                    onClick={() => setShowPass(true)}
                  />
                )}
              </div>
            </div>
          </div>
          <div class="flex items-center mt-6">
            <input id="agree" type="checkbox" value="" class="w-4 h-4 " />
            <label
              for="agree"
              class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 font-ruda"
            >
              Remember Me
            </label>
          </div>

          <div className=" py-8 flex gap-6 items-center">
            {isLoading ? (
              <button className="bg-secondary px-4 py-2 text-white rounded-tr-2xl rounded-bl-2xl">
                Processing
              </button>
            ) : (
              <button
                className="bg-secondary px-4 py-2 text-white rounded-tr-2xl rounded-bl-2xl"
                onClick={handleSingnin}
              >
                SIGN IN
              </button>
            )}

            <div className="font-ruda">
              <p>
                Don’t have an account yet? Sign Up{" "}
                <Link to="/signup">
                  <span className="text-secondary underline underline-offset-1">
                    here
                  </span>
                </Link>
                <p>
                  Forgot Password? Click{" "}
                  <Link to="/forgot-password">
                    <span className="text-secondary underline underline-offset-1">
                      here
                    </span>
                  </Link>
                </p>
              </p>
              {/* <p className="text-secondary underline underline-offset-1">
                Forgot Password?
              </p> */}
            </div>
          </div>
          {isError && (
            <p className="text-red-500">{firebaseErrorHandle(error)}</p>
          )}
        </div>
        <p className="text-white text-center pt-20">
          Copyright © 2023 | Needle
        </p>
      </div>
      {/* <div className="fixed bottom-[50px] right-[50px]">
        <FaFacebookMessenger className="w-10 h-10 z-10" />
      </div> */}
    </div>
  );
}
