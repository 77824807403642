import React from "react";

export default function ApplicationNavbar({ setPage, page }) {
  return (
    <div className="border-b-2 border-gray-200 pb-2">
      <div className="flex flex-col md:flex-row gap-2 ">
        <div
          className={`text-black  px-4 py-2 rounded-t-2xl cursor-pointer transition-all duration-500 ${
            page === 1 ? "bg-secondary text-white " : ""
          }`}
          // onClick={() => setPage(1)}
        >
          Basic Information
        </div>
        <div
          className={`text-black  px-4 py-2 rounded-t-2xl cursor-pointer transition-all duration-500 ${
            page === 2 ? "bg-secondary text-white " : ""
          }`}
          // onClick={() => setPage(2)}
        >
          Startup Profile
        </div>
        <div
          className={`text-black  px-4 py-2 rounded-t-2xl cursor-pointer transition-all duration-500 ${
            page === 3 ? "bg-secondary text-white " : ""
          }`}
          // onClick={() => setPage(3)}
        >
          Solution
        </div>
        <div
          className={`text-black  px-4 py-2 rounded-t-2xl cursor-pointer transition-all duration-500 ${
            page === 4 ? "bg-secondary text-white " : ""
          }`}
          // onClick={() => setPage(4)}
        >
          Impact
        </div>
        <div
          className={`text-black  px-4 py-2 rounded-t-2xl cursor-pointer transition-all duration-500 ${
            page === 5 ? "bg-secondary text-white " : ""
          }`}
          // onClick={() => setPage(5)}
        >
          Availability
        </div>
      </div>
    </div>
  );
}
