import React from "react";

export default function Heading({ firstHeading, secondHeading, color }) {
  return (
    <>
      <h2 className="text-2xl md:text-5xl text-center font-bold  pb-6">
        {firstHeading}
        <span className="text-secondary"> {secondHeading}</span>
      </h2>
      <div
        className={`max-w-xs mx-auto p-0.5 ${
          color ? `bg-${color}` : "bg-gray-800"
        }`}
      ></div>
    </>
  );
}
