import React, { useEffect, useState } from "react";
import NavbarChallenge from "./TopComponents/NavbarChallenge";
import Navbar from "./Navbar";
import Hero from "./TopComponents/Hero";
import Deadline from "./TopComponents/Deadline";
import Navigation from "./TopComponents/Navigation";
import Footer from "./TopComponents/Footer";
import MessengerCustomerChat from "react-messenger-customer-chat";

export default function Layout({ children }) {
  let [height, setHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 900;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);

    // if ((winScroll > heightToHideFrom) && (winScroll < 5600)) {
    //   setIsVisible(true);
    // } else if (winScroll > 5600) {
    //   setIsVisible(false);
    // } else {
    //   setIsVisible(false);
    // }
  };

  return (
    <div className="relative">
      <Navbar height={height} />
      <Hero />
      <Deadline />
      <div
        className={`${height > 900 ? "fixed top-0 left-0 right-0 z-10 " : ""}`}
      >
        <NavbarChallenge />
        <Navigation />
      </div>
      <div className={`${height > 900 ? "pt-40" : ""}`}>{children}</div>
      <Footer />
      <MessengerCustomerChat
        pageId="105721572543850"
        appId="1195222537812658"
        themeColor="#ED174E"
        loggedInGreeting="Hi! Welcome to Needle Innovation Challenge"
      />
    </div>
  );
}
