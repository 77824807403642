import { Navigate } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { authDetail } from "../features/auth/authSlice";

const ProtectedRoute = ({ children }) => {
  const { email } = useSelector(authDetail);

  return email ? children : <Navigate to="/signup"></Navigate>;
};

export default ProtectedRoute;
