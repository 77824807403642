import React from "react";
import { FaLink } from "react-icons/fa";

export default function ResourcesTable({ resources }) {
  return (
    <div className="my-8">
      <div className="w-full">
        <div className="max-w-full overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="text-left text-dark font-bold bg-[#D9D9D9]">
                <th className="w-3/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Title
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Publish Date
                </th>
                <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Resource
                </th>
              </tr>
            </thead>
            <tbody>
              {resources?.map((resource, id) => (
                <tr
                  className={`border-t border-[#ddd] text-left text-dark py-4 ${
                    id % 2 == 0 ? "" : "bg-[#D9D9D9]"
                  }`}
                >
                  <td className="py-6 px-8  text-base">
                    <p>{resource?.title}</p>
                  </td>
                  <td className="py-6 px-8  text-base">
                    {resource?.createdAt.toDate().toISOString().split("T")[0]}
                  </td>
                  <td className="py-6 px-8  text-base">
                    <a href={resource?.pdf} target="_blank">
                      <FaLink className="ml-4" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
