import React, { useState } from "react";
import Heading from "../../../shared/Heading";
// import solution1 from "../../../assets/solution1.png";
// import { FaCaretRight } from "react-icons/fa";
import { solutionData } from "../data";
// import Modal from "../../../shared/Modal";
import { motion } from "framer-motion";
import Button from "../../../shared/UI/Button";

export default function AllSolution({ setSolutionObj, setShowModal }) {
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className="pt-20"
    >
      <Heading firstHeading="Solution" secondHeading="Areas" />
      <p className="max-w-7xl mx-auto my-10 px-4">
        The proposed solution should directly address at least one of the
        following areas:
      </p>
      <div className="bg solution py-20 px-4">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-10">
          {solutionData?.map((solution) => (
            <div
              key={solution.id}
              className="bg-white rounded-lg overflow-hidden text-center "
            >
              <div className="relative w-full">
                <img
                  // loading="lazy"
                  src={solution.img}
                  className="relative w-full h-auto "
                  alt={solution.title}
                />
                <div className="absolute bottom-[20px] left-0 text-center  w-full">
                  <p className="text-white text-[20px] text-center px-6 w-full">
                    {solution.title}
                  </p>
                </div>
              </div>
              <div className="flex  justify-center items-center py-8">
                <Button
                  onClick={() => {
                    setSolutionObj(solution);
                    setShowModal(true);
                  }}
                >
                  Read more
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}
