import React from "react";
import join3 from "../../../assets/join3.png";

export default function JoinPhaseThree() {
  return (
    <div className="flex flex-col gap-4 justify-center items-center">
      <img src={join3} alt="" />
      <p className="text-center text-[16px]">
        Access tailored guidance and support from subject-matter experts
        throughout the implementation phase.
      </p>
    </div>
  );
}
