import { Navigate } from "react-router-dom";
import React from "react";

const AdminProtectedRoute = ({ children }) => {
  let loggedIn = localStorage.getItem("needle_admin_loggedIn");

  return loggedIn === "true" ? (
    children
  ) : (
    <Navigate to="/admin-login"></Navigate>
  );
};

export default AdminProtectedRoute;
