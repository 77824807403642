import React, { useEffect, useState } from "react";
import { useFirestore } from "../../../hooks/useFirestore";
import { FaCaretLeft, FaCaretRight, FaSave } from "react-icons/fa";
import Textarea, { SingleTextarea } from "../../../shared/UI/Textarea";
import { toast } from "react-toastify";
import Toast from "../../../shared/UI/Toast";

export default function ApplicationEnvironmental({
  setDetails,
  details,
  setPage,
}) {
  const [stackHolderErr, setStackHolderErr] = useState("");
  const [stackHolderAdded, setStackHolderAdded] = useState("");
  const { addDocument, updateDocument, response } =
    useFirestore("entrepreneur");

  const [stackHolder, setStackHolder] = useState({
    name: "",
    needAndBenefits: "",
  });

  const handleStackHolder = (e) => {
    if (stackHolder.name === "" || stackHolder.needAndBenefits === "") {
      setStackHolderErr("Please Fill all the required stackholders field");
    } else {
      setDetails({
        ...details,
        environmentalImpact: {
          ...details?.environmentalImpact,
          stakeholders: [
            ...details?.environmentalImpact?.stakeholders,
            stackHolder,
          ],
        },
      });
      setStackHolderAdded("Added your stakeholder");
      setStackHolder({
        name: "",
        needAndBenefits: "",
      });
    }
  };

  useEffect(() => {
    if (
      response.success &&
      details?.environmentalImpact?.environmentalGoal &&
      details?.environmentalImpact?.SDGGoals &&
      details?.environmentalImpact?.socialImpact &&
      details?.environmentalImpact?.stakeholders?.length &&
      details?.environmentalImpact?.workImpact
    ) {
      setPage(5);
    }
  }, [response.success]);

  const handleBusinessModelCheckBox = (e) => {
    if (
      !details?.environmentalImpact?.businessModel?.includes(e.target.value)
    ) {
      setDetails({
        ...details,
        environmentalImpact: {
          ...details?.environmentalImpact,
          businessModel: [
            ...details?.environmentalImpact?.businessModel,
            e.target.value,
          ],
        },
      });
    } else {
      let filteredBusinessModel =
        details?.environmentalImpact?.businessModel?.filter(
          (business) => business !== e.target.value
        );
      setDetails({
        ...details,
        environmentalImpact: {
          ...details?.environmentalImpact,
          businessModel: filteredBusinessModel,
        },
      });
    }
  };

  const handleEnvironMentalCheckBox = (e) => {
    if (
      !details?.environmentalImpact?.environmentalGoal?.includes(e.target.value)
    ) {
      setDetails({
        ...details,
        environmentalImpact: {
          ...details?.environmentalImpact,
          environmentalGoal: [
            ...details?.environmentalImpact?.environmentalGoal,
            e.target.value,
          ],
        },
      });
    } else {
      let filteredEnvironmentalGoal =
        details?.environmentalImpact?.environmentalGoal?.filter(
          (goal) => goal !== e.target.value
        );
      setDetails({
        ...details,
        environmentalImpact: {
          ...details?.environmentalImpact,
          environmentalGoal: filteredEnvironmentalGoal,
        },
      });
    }
  };

  const handleSave = () => {
    if (!response.isLoading) {
      updateDocument(details?.id, details);
      toast.success("Your data saved Successfully");
    }
  };

  const handlePrev = () => {
    setPage(3);
  };

  const handleNext = () => {
    if (
      details?.environmentalImpact?.environmentalGoal &&
      details?.environmentalImpact?.SDGGoals &&
      details?.environmentalImpact?.socialImpact &&
      details?.environmentalImpact?.stakeholders?.length &&
      details?.environmentalImpact?.workImpact &&
      !response.isLoading
    ) {
      updateDocument(details?.id, details);
    } else {
      toast.error("Please Fill all the required Field");
    }
  };

  return (
    <div className="py-10 flex flex-col gap-8 z-10">
      <div className="w-full flex flex-col md:flex-row gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label className="font-bold">
            Does your solution positively impact the future work prospects of
            women garment workers? <span>*</span>
          </label>
          <div className="flex gap-4 items-center">
            <label>
              <input
                type="radio"
                name="subject"
                value="Yes"
                checked={details?.environmentalImpact?.workImpact === "Yes"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    environmentalImpact: {
                      ...details?.environmentalImpact,
                      workImpact: e.target.value,
                    },
                  })
                }
              />{" "}
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="subject"
                value="No"
                checked={details?.environmentalImpact?.workImpact === "No"}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    environmentalImpact: {
                      ...details?.environmentalImpact,
                      workImpact: e.target.value,
                    },
                  })
                }
              />{" "}
              No
            </label>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label className="font-bold">
            Does your solution have any of the following high impact business
            models? Please check all that apply. <span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="Products/services are specifically designed to serve or be accessible to low income/ underserved/  disadvantaged consumers"
                checked={details?.environmentalImpact?.businessModel?.includes(
                  "Products/services are specifically designed to serve or be accessible to low income/ underserved/  disadvantaged consumers"
                )}
                onChange={handleBusinessModelCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                Products/services are specifically designed to serve or be
                accessible to low income/ underserved/ disadvantaged consumers
              </label>
            </div>
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="Products/services are specifically designed to conserve the environment or reduce carbon emissions"
                checked={details?.environmentalImpact?.businessModel?.includes(
                  "Products/services are specifically designed to conserve the environment or reduce carbon emissions"
                )}
                onChange={handleBusinessModelCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                Products/services are specifically designed to conserve the
                environment or reduce carbon emissions
              </label>
            </div>
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="Inputs for products/services are primarily sourced from low income/ underserved/ disadvantaged communities"
                checked={details?.environmentalImpact?.businessModel?.includes(
                  "Inputs for products/services are primarily sourced from low income/ underserved/ disadvantaged communities"
                )}
                onChange={handleBusinessModelCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                Inputs for products/services are primarily sourced from low
                income/ underserved/ disadvantaged communities
              </label>
            </div>
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="Majority of employees are women and/or at least one founder is a woman"
                checked={details?.environmentalImpact?.businessModel?.includes(
                  "Majority of employees are women and/or at least one founder is a woman"
                )}
                onChange={handleBusinessModelCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                Majority of employees are women and/or at least one founder is a
                woman
              </label>
            </div>
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="None of the above"
                checked={details?.environmentalImpact?.businessModel?.includes(
                  "None of the above"
                )}
                onChange={handleBusinessModelCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                None of the above
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label className="font-bold">
            Does your company's product, process, or business model innovation
            work to achieve one or more of the five key environmental/circular
            impact goals? If yes, please check all that apply<span>*</span>
          </label>
          <div className="flex flex-col gap-2">
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="Adopting alternative, sustainable inputs and material in manufacturing of textiles at scale"
                checked={details?.environmentalImpact?.environmentalGoal?.includes(
                  "Adopting alternative, sustainable inputs and material in manufacturing of textiles at scale"
                )}
                onChange={handleEnvironMentalCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                Adopting alternative, sustainable inputs and material in
                manufacturing of textiles at scale
              </label>
            </div>
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="Improving the recycling/upcycling of textile & packaging waste"
                checked={details?.environmentalImpact?.environmentalGoal?.includes(
                  "Improving the recycling/upcycling of textile & packaging waste"
                )}
                onChange={handleEnvironMentalCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                Improving the recycling/upcycling of textile & packaging waste
              </label>
            </div>
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="Boosting production through renewable inputs as source of energy or manufacturing"
                checked={details?.environmentalImpact?.environmentalGoal?.includes(
                  "Boosting production through renewable inputs as source of energy or manufacturing"
                )}
                onChange={handleEnvironMentalCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                Boosting production through renewable inputs as source of energy
                or manufacturing
              </label>
            </div>
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="Reduction of carbon emissions in RMG manufacturing process"
                checked={details?.environmentalImpact?.environmentalGoal?.includes(
                  "Reduction of carbon emissions in RMG manufacturing process"
                )}
                onChange={handleEnvironMentalCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                Reduction of carbon emissions in RMG manufacturing process
              </label>
            </div>
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="Driving positive social impact and improving livelihoods of those working at various points in the supply chain"
                checked={details?.environmentalImpact?.environmentalGoal?.includes(
                  "Driving positive social impact and improving livelihoods of those working at various points in the supply chain"
                )}
                onChange={handleEnvironMentalCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                Driving positive social impact and improving livelihoods of
                those working at various points in the supply chain
              </label>
            </div>
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="Maximising the utilization of clothing & textile (reuse, refurbish or rental models)"
                checked={details?.environmentalImpact?.environmentalGoal?.includes(
                  "Maximising the utilization of clothing & textile (reuse, refurbish or rental models)"
                )}
                onChange={handleEnvironMentalCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                Maximising the utilization of clothing & textile (reuse,
                refurbish or rental models)
              </label>
            </div>
            <div class="flex items-center mt-2">
              <input
                type="checkbox"
                value="None of the above"
                checked={details?.environmentalImpact?.environmentalGoal?.includes(
                  "None of the above"
                )}
                onChange={handleEnvironMentalCheckBox}
                class="w-4 h-4 "
              />
              <label class="ml-2 text-sm font-medium text-gray-900  font-ruda">
                None of the above
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <Textarea
            link="fnewsHeadline"
            label="What social and/or environment challenges are you trying to solve?
            (100 words max)"
            maximumLength={100}
            value={details?.environmentalImpact?.challengeToSolve}
            onChange={(e) =>
              setDetails({
                ...details,
                environmentalImpact: {
                  ...details?.environmentalImpact,
                  challengeToSolve: e.target.value,
                },
              })
            }
          />
        </div>
      </div> */}
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label for="fstack" className="font-bold">
            Name up to three types of stakeholders whose lives will be made
            better by your solution (for e.g. garment workers, manufacturers,
            etc.) What are their needs and how will your solution benefit them?
            (50 maximum word count for each box below)
            <span>*</span>
          </label>
          <div className="bg-gray-100 flex flex-col gap-4 p-2">
            <div>
              {details?.environmentalImpact?.stakeholders?.map((holder, id) => (
                <ol className="list-disc pl-4" key={id}>
                  <li>
                    <h1 className="font-bold">{holder?.name}</h1>
                    <p>{holder?.needAndBenefits}</p>
                  </li>
                </ol>
              ))}
            </div>
            <input
              type="text"
              name="fstack"
              id="fstack"
              placeholder="Stack holder name"
              className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none"
              value={stackHolder?.name}
              onChange={(e) =>
                setStackHolder({
                  ...stackHolder,
                  name: e.target.value,
                })
              }
            />

            <SingleTextarea
              maximumLength={50}
              value={stackHolder?.needAndBenefits}
              onChange={(e) =>
                setStackHolder({
                  ...stackHolder,
                  needAndBenefits: e.target.value,
                })
              }
            />

            <button
              className="px-8 w-[100px] py-2 text-white bg-secondary rounded-tl-xl rounded-br-xl cursor-pointer border-2 border-primary  text-[18px] shadow-btn btn flex items-center border-none hover:bg-secondary"
              onClick={handleStackHolder}
            >
              Add
            </button>
            {stackHolderErr && <p className="text-red-500">{stackHolderErr}</p>}
            {stackHolderAdded && (
              <p className="text-green-500">{stackHolderAdded}</p>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <Textarea
            link="fRegistered"
            label="What is the social and/or environmental impact your solution has created
            thus far? (for e.g. social: no. of jobs created, no. of lives improved,
            etc; environment: improvement in energy efficiency, quantity of waste
            recycled, reduction in chemical effluent, etc) (100 words max)"
            maximumLength={100}
            value={details?.environmentalImpact?.socialImpact}
            onChange={(e) =>
              setDetails({
                ...details,
                environmentalImpact: {
                  ...details?.environmentalImpact,
                  socialImpact: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
      {/* <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <Textarea
            link="fnewsHeadline"
            label="Imagine that in 2030 your solution has been implemented to its full
            potential. Explain the most important impact caused by your solution
            with a news headline. (15 words max)"
            maximumLength={15}
            value={details?.environmentalImpact?.newsHeadline}
            onChange={(e) =>
              setDetails({
                ...details,
                environmentalImpact: {
                  ...details?.environmentalImpact,
                  newsHeadline: e.target.value,
                },
              })
            }
          />
        </div>
      </div> */}
      {/* <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <Textarea
            link="fEnablesFuture"
            label=" Please elaborate on the result you described above. Explain how your
            solution enables this future. (100 words max)"
            maximumLength={100}
            value={details?.environmentalImpact?.enablesFuture}
            onChange={(e) =>
              setDetails({
                ...details,
                environmentalImpact: {
                  ...details?.environmentalImpact,
                  enablesFuture: e.target.value,
                },
              })
            }
          />
        </div>
      </div> */}
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-20">
        <div className=" w-full flex flex-col gap-2">
          <label for="fRegistered" className="font-bold">
            Please list which SDGs you are addressing through your solution (SDG
            goals:
            <a
              href="https://www.un.org/sustainabledevelopment/sustainabledevelopment-goals/"
              target="_blank"
              className="underline text-blue-700"
            >
              https://www.un.org/sustainabledevelopment/sustainabledevelopment-goals/
            </a>
            ) <span>*</span>
          </label>
          <textarea
            type="text"
            name="fRegistered"
            id="fRegistered"
            placeholder=""
            className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-1 focus:outline-none h-[150px]"
            value={details?.environmentalImpact?.SDGGoals}
            onChange={(e) =>
              setDetails({
                ...details,
                environmentalImpact: {
                  ...details?.environmentalImpact,
                  SDGGoals: e.target.value,
                },
              })
            }
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div
          className="flex gap-2 items-center justify-center cursor-pointer bg-black px-4 py-2 rounded-md text-white"
          onClick={handleSave}
        >
          <p>Save</p>
          {/* <FaSave /> */}
        </div>
        <div className="flex gap-4">
          <div
            className="flex gap-2 items-center justify-center cursor-pointer bg-secondary px-4 py-2 rounded-md text-white"
            onClick={handlePrev}
          >
            <FaCaretLeft />
            <p>Prev</p>
          </div>
          <div
            className="flex gap-2 items-center justify-center cursor-pointer bg-secondary px-4 py-2 rounded-md text-white"
            onClick={handleNext}
          >
            <p>NEXT</p>
            <FaCaretRight />
          </div>
        </div>
      </div>
      <Toast />
    </div>
  );
}
