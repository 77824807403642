import React from "react";
import Needlelogo from "../assets/logo2.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authDetail, logout } from "../features/auth/authSlice";
import { signOut } from "firebase/auth";
import { auth } from "../firebase.config";
import { useCollection } from "../hooks/useCollection";
import NavigateUser from "./utils/NavigateUser";

export default function Navbar() {
  const {
    email,
    isLoading,
    isError,
    error: authError,
  } = useSelector(authDetail);

  const handleLogout = (e) => {
    signOut(auth).then((res) => {
      logout();
      window.location.reload();
    });
  };

  const { document, isLoading: documentLoading } =
    useCollection("entrepreneur");

  const navigate = useNavigate();
  const handleDashboard = () => {
    navigate(NavigateUser(document, email));
  };

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-4">
        {/* <div className="flex flex-col md:flex-row justify-between items-center"> */}
        <div className="flex flex-col md:flex-row justify-center items-center">
          <Link to="/">
            <img src={Needlelogo} alt="Needle" className="w-[100px] h-auto" />
          </Link>
          {/* {email ? (
            <div className="flex items-center gap-4">
              <p className="cursor-pointer" onClick={handleDashboard}>
                Dashboard
              </p>
              <p className="cursor-pointer" onClick={handleLogout}>
                Sign out
              </p>
            </div>
          ) : (
            <div className="flex items-center gap-8">
              <Link to="/signup">
                <p className="cursor-pointer">Create an account</p>
              </Link>
              <Link to="/signin">
                <p className="cursor-pointer">Sign in</p>
              </Link>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}
