import React, { useState } from "react";
import Needlelogo from "../../assets/logo2.png";
import {
  FaCaretRight,
  FaFacebookSquare,
  FaLinkedinIn,
  FaShareAlt,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Button from "../UI/Button";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { authDetail } from "../../features/auth/authSlice";
import { useCollection } from "../../hooks/useCollection";
import NavigateUser from "../utils/NavigateUser";
import Facebook from "../SocialMedia/Facebook";

export default function NavbarChallenge({ height }) {
  let [showIcon, setShowIcon] = useState(false);

  const { email } = useSelector(authDetail);
  const { document, isLoading: documentLoading } =
    useCollection("entrepreneur");

  const navigate = useNavigate();
  const handleApply = () => {
    navigate(NavigateUser(document, email));
  };

  return (
    <div className={`bg-white border-b border-b-gray-300 `}>
      <div className="max-w-7xl mx-auto py-4 flex flex-col md:flex-row items-center justify-between">
        <div className="flex items-center gap-4">
          <Link to="/">
            <img
              loading="lazy"
              src={Needlelogo}
              alt="Needle"
              className="w-[100px] h-auto"
            />
          </Link>
        </div>
        {/* <h1 className="text-xl font-bold ">Innovation Challenge</h1> */}
        <div className="flex items-center gap-4">
          {!showIcon && (
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => setShowIcon(true)}
            >
              <FaShareAlt />
              <p>Share</p>
            </div>
          )}

          {showIcon && (
            <div
              initial={{ y: "100%" }}
              animate={{ y: "0%" }}
              transition={{ duration: 1, ease: "easeOut" }}
              className="flex gap-2 items-center"
            >
              <motion.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 0.6, ease: "easeOut" }}
              >
                {/* <FaFacebookSquare /> */}
                <Facebook
                  url={`https://needlechallenge.com/`}
                  qoute="Needle Website"
                />
              </motion.div>
              {/* <motion.div
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: 1, ease: "easeOut" }}
              >
                <FaLinkedinIn />
              </motion.div> */}
            </div>
          )}

          <div>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdlPYzuoPTwpWM_gnv6sbnkHOqWtzLjlo9nROrHd-O8Jsagbw/viewform"
              target="_blank"
              rel="noreferrer"
            >
              <Button
              // onClick={() => handleApply()}
              >
                Participate
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
