import { signOut } from "firebase/auth";
import React from "react";
import {
  FaBook,
  FaQrcode,
  FaShieldAlt,
  FaSignOutAlt,
  FaWallet,
  FaWrench,
} from "react-icons/fa";
import { logout } from "../../../features/auth/authSlice";
import { auth } from "../../../firebase.config";
import { Link, NavLink } from "react-router-dom";

export default function MaturestartupSidebar() {
  const handleLogout = (e) => {
    signOut(auth).then((res) => {
      logout();
      window.location.reload();
    });
  };
  return (
    <div className="md:min-h-[calc(100vh-96px)] w-full md:w-[280px] bg-white">
      <div className="p-8 bg-primary text-white">
        <p className="text-md">
          <span className="font-bold">Needle</span> - Existing Solution
          Application Panel
        </p>
      </div>

      <div className="flex flex-col ">
        <NavLink
          to="/mature-startup"
          className={({ isActive, isPending }) =>
            isActive ? "bg-secondary text-white " : ""
          }
        >
          <div className="flex gap-2 items-center px-8 py-2 cursor-pointer hover:bg-secondary hover:text-white transition-all duration-500 ">
            <FaWallet />
            <p>Workspace</p>
          </div>
        </NavLink>
        <NavLink
          to="/mature/case-study"
          className={({ isActive, isPending }) =>
            isActive ? "bg-secondary text-white " : ""
          }
        >
          <div className="flex gap-2 items-center px-8 py-2 cursor-pointer hover:bg-secondary hover:text-white transition-all duration-500 ">
            <FaBook />
            <p>Case Study</p>
          </div>
        </NavLink>
        <NavLink
          to="/mature/resources"
          className={({ isActive, isPending }) =>
            isActive ? "bg-secondary text-white " : ""
          }
        >
          <div className="flex gap-2 items-center px-8 py-2 cursor-pointer hover:bg-secondary hover:text-white transition-all duration-500 ">
            <FaQrcode />
            <p>Resources</p>
          </div>
        </NavLink>
        <div
          className="flex gap-2 items-center px-8 py-2 cursor-pointer hover:bg-secondary hover:text-white transition-all duration-500 "
          onClick={handleLogout}
        >
          <FaSignOutAlt />
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
}
