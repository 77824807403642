import React from "react";

export default function TermAndCondition() {
  return (
    <div className="w-[900px] my-10 py-8 px-6 md:px-20 bg-white text-black rounded-md max-h-[600px] overflow-y-scroll">
      <h2 className=" text-4xl text-center font-bold">
        <span className="text-secondary">Terms</span> and Conditions
      </h2>
      <div className="mt-4 p-px bg-gray-500 max-w-[300px] mx-auto"></div>
      <div className="py-10">
        <ol className="list-decimal pl-6 flex flex-col gap-2 font-ruda ">
          <li>
            No fee, donation, or purchase is required to enter the Needle
            Innovation Challenge (hereinafter “Program” or “Challenge”).
          </li>
          <li>
            Program Entities are not responsible for lost, late, incomplete, or
            misdirected entries that may occur in connection with the Program.
          </li>
          <li>
            The Program is open to entrepreneurs, teams, and enterprises
            (hereinafter “Entrant(s)” or “entity’ or “you/your”) who may or may
            not be legally registered but has a for-profit impact focused
            business model.
          </li>
          <li>
            If more than one entity is part of a submission, a lead entity for
            the submission must be identified, who will create an online profile
            and enter the Program, provide all Submission Materials that are
            part of the Program, and is potentially chosen to participate in the
            bootcamps. All communications from the Program Entities will be done
            with this entity.
          </li>
          <li>
            Entrants may submit multiple, different, submissions meeting the
            Challenge Terms and Conditions.
          </li>
          <li>
            All Submission Materials and communications shall be in English and
            directly address the Program challenge statement to be eligible.
          </li>
          <li>
            By submitting Submission Materials at any time in connection with
            any aspect of the Program, you grant the Program Entities a Creative
            Commons License for use of all such materials and communications.
            Each entrant expressly consents for the benefit of the Program
            Entities in their sole discretion to use the name and trademark, if
            any, of the Entrant, and the name of the authorized representative
            of the Entrant, the country location, photo or likeness, and/or the
            background of the Entrant and that of the authorized representative
            and key team members, and the Submission Materials, in whole or in
            part, for promotional, educational or charitable purposes in
            connection with the Program.
          </li>
          <li>
            By providing Submission Materials, the Entrant represents and
            warrants that the Submission Materials are original, not copied in
            whole or in part, and exclusively owned and gratuitously provided
            without any restriction or limitation on use.
          </li>
          <li>
            Each Entrant bears sole responsibility for confirming that it can
            participate fully in the Program, including participation in the
            residential bootcamps.
          </li>
          <li>
            Decisions of the Judging Panel and any of the Program Entities on
            the selection of the entrants shall be in their sole discretion and
            binding on each entrant. The Entrants do not have the right to
            dispute any decision or ruling of any Judging Panel or any of the
            Program Entities and agree not to do so.
          </li>
          <li>
            By signing up for the Program or sending Submission Materials, you
            expressly acknowledge and agree to the collection and use of the
            personal information in those materials. The personal data requested
            is necessary to enter the Program, but Entrants may request each
            Program Entity to delete their personal data (and thereby terminate
            their participation in the Program) at any time.
          </li>
          <li>
            Nothing in the Challenge Terms and Conditions, the Program website,
            in any Program materials, or in any other statements made in
            connection with the Program grants to you or any Entrant the right
            to use or license any intellectual property of the Program Entities.
            You shall not use the name, trademarks, service marks, logos or any
            copyrighted material of the program entities without the written
            consent of the Program Entities, which any Program Entity may
            withhold in its sole discretion.
          </li>
          <li>
            Participation and/or entry in the Needle Innovation Challenge
            constitutes each entrant’s representation and warranty that it fully
            and unconditionally agrees to be bound by, and is in full compliance
            with, the Challenge Terms and Conditions.
          </li>
        </ol>
      </div>
    </div>
  );
}
