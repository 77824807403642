import React, { useState } from "react";
import Slider from "react-slick";

import { FaCaretRight } from "react-icons/fa";
import { processDataFirst, processDataSecond } from "../../Process/data";
import ProcessNavigation from "../../Process/Components/ProcessNavigation";

export default function HomeTimeline() {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [category, setCategory] = useState("Idea Stage Solution");

  return (
    <div className="pt-20">
      <h1 className="text-center text-3xl font-bold text-black">Timeline</h1>
      <div className="pb-2">
        <ProcessNavigation category={category} setCategory={setCategory} />
      </div>

      <div className="py-20  timeline">
        <Slider {...settings}>
          {category === "Existing Solution" &&
            processDataFirst?.map((process) => (
              <div className="col-span-1 text-center text-white">
                <div className="border-b border-b-gray-200 p-4">
                  <div className="w-20 h-20 mx-auto bg-white rounded-full overflow-hidden flex justify-center items-center">
                    <img
                      src={process?.icon}
                      alt=""
                      className=" object-contain"
                    />
                  </div>
                  <h1 className="py-2 font-bold">{process?.title}</h1>
                  <p>{process?.date}</p>
                </div>
                <div className="p-4">
                  <p>{process?.details}</p>
                </div>
              </div>
            ))}
          {category === "Idea Stage Solution" &&
            processDataSecond?.map((process) => (
              <div className="col-span-1 text-center text-white">
                <div className="border-b border-b-gray-200 p-4">
                  <div className="w-20 h-20 mx-auto bg-white rounded-full overflow-hidden flex justify-center items-center">
                    <img
                      src={process?.icon}
                      alt=""
                      className=" object-contain"
                    />
                  </div>
                  <h1 className="py-2 font-bold">{process?.title}</h1>
                  <p>{process?.date}</p>
                </div>
                <div className="p-4">
                  <p>{process?.details}</p>
                </div>
              </div>
            ))}
        </Slider>

        <div className="flex justify-center">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdlPYzuoPTwpWM_gnv6sbnkHOqWtzLjlo9nROrHd-O8Jsagbw/viewform"
            target="_blank"
            rel="noreferrer"
          >
            <button className="px-8 py-2 text-secondary bg-white rounded-tl-xl rounded-br-xl cursor-pointer border-2 border-primary  text-[18px] shadow-btn btn flex items-center border-none hover:text-white hover:bg-secondary">
              Participate Now!
              <FaCaretRight className="inine btn_arrow" />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
