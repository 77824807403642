import React from "react";
import AdminLayout from "../../../shared/AdminLayout";
import CaseStudyDetails from "./Components/CaseStudyDetails";

export default function AdminCaseStudy() {
  return (
    <AdminLayout>
      <CaseStudyDetails />
    </AdminLayout>
  );
}
