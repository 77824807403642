import React from "react";
import AdminNavbar from "../../shared/AdminNavbar";
import MatureStartupDetails from "./Components/MatureStartupDetails";
import ScrollToTop from "../../shared/utils/ScrollToTop";
import MaturestartupSidebar from "./Components/MaturestartupSidebar";
import MatureLayout from "../../shared/MatureLayout";

export default function MatureStartup() {
  return (
    <MatureLayout>
      <MatureStartupDetails />
    </MatureLayout>
  );
}
