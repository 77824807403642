import React from "react";
import AdminLayout from "../../../shared/AdminLayout";
import AdminBlogDetails from "./Components/AdminBlogDetails";

export default function AdminBlogs() {
  return (
    <AdminLayout>
      <AdminBlogDetails />
    </AdminLayout>
  );
}
