import React from "react";
import { motion } from "framer-motion";

export default function ContactForm() {
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className="bg contact py-40"
    >
      <div className="max-w-5xl mx-auto grid gap-4 grid-cols-1 md:grid-cols-2 text-white px-4">
        <div className="flex flex-col gap-6">
          <div>
            <h2 className="text-2xl md:text-5xl font-bold">CONTACT US</h2>
            <p className="font-thin font-ruda pt-2">
              Send us a message describing your questions or any concerns.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-bold">Email</h2>
            <p className="font-ruda font-thin">info@buildbangladesh.org.bd</p>
          </div>
          <div>
            <h2 className="text-2xl font-bold">Phone</h2>
            <p className="font-ruda font-thin">+88 01911-294278</p>
          </div>
        </div>
        <div>
          <div className="w-full flex flex-col md:flex-row gap-6">
            <div className=" w-full flex flex-col gap-2">
              <label for="fName" className="font-bold">
                FIRST NAME <span>*</span>
              </label>
              <input
                type="text"
                name="fName"
                id="fName"
                placeholder="First Name"
                className="w-full bg-transparent border border-white rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
              />
            </div>
            <div className="w-full flex flex-col gap-2">
              <label for="lName" className="font-bold">
                LAST NAME
              </label>
              <input
                type="text"
                name="lName"
                id="lName"
                placeholder="Last Name"
                className="w-full bg-transparent border border-white rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
              />
            </div>
          </div>
          <div className=" w-full flex flex-col gap-2 pt-6">
            <label for="fEmail" className="font-bold">
              EMAIL ADDRESS
            </label>
            <input
              type="EMAIL"
              name="fEmail"
              id="fEmail"
              placeholder="Your Email"
              className="w-full bg-transparent border border-white rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
            />
          </div>
          <div className=" w-full flex flex-col gap-2 py-6">
            <label for="fEmail" className="font-bold">
              YOUR QUERY
            </label>
            <textarea
              className="w-full h-[160px] bg-transparent border border-white rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
              placeholder="Describe Your Article"
            />
          </div>
          <button className="px-8 py-2 bg-secondary rounded-tl-xl rounded-br-xl cursor-pointer">
            Sent
          </button>
        </div>
      </div>
    </motion.div>
  );
}
