import React from "react";
import Layout from "../../shared/Layout";
import AllPertners from "./Components/AllPertners";
import ScrollToTop from "../../shared/utils/ScrollToTop";

export default function Partners() {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <AllPertners />
      </Layout>
    </>
  );
}
