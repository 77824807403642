import React from "react";
import Needlelogo from "../assets/logo2.png";
import { FaCaretDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { authDetail } from "../features/auth/authSlice";
import { Link } from "react-router-dom";

export default function AdminNavbar() {
  const { email, displayName } = useSelector(authDetail);
  return (
    <div className="bg-white px-8 flex justify-between items-center py-4">
      <Link to="/">
        {/* <h1 className="text-3xl font-extrabold">Needle</h1> */}
        <img src={Needlelogo} alt="Needle" className="w-[80px] h-auto" />
      </Link>
      <div>
        <h1 className="text-xl font-bold ">Innovation Challenge</h1>
      </div>
      <div className="flex gap-2 items-center">
        {/* <img src={profile} alt="" /> */}
        <p>{displayName}</p>
        {/* <FaCaretDown /> */}
      </div>
    </div>
  );
}
