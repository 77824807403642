import React from "react";

export default function Modal({ children, setShowModal }) {
  return (
    <>
      <div
        // x-show="modalOpen"
        // x-transition
        className="fixed top-0 left-0 flex h-full min-h-screen w-full items-center justify-center bg-black bg-opacity-75 px-4 z-50"
        onClick={() => setShowModal(false)}
      >
        {children}
      </div>
    </>
  );
}
