import React from "react";
import circle from "../../../assets/circle.png";
import InnovationChallenge from "./InnovationChallenge";

export default function CaseStudyDetails() {
  return (
    <div className="relative md:w-full py-20 mx-4">
      <InnovationChallenge />
      <img src={circle} className="absolute bottom-0 right-0 z-0" />
    </div>
  );
}
