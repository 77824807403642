import React, { useEffect, useState } from "react";
import Layout from "../../shared/Layout";
import ScrollToTop from "../../shared/utils/ScrollToTop";
import { Link, useParams } from "react-router-dom";
import { useCollection } from "../../hooks/useCollection";
import Facebook from "../../shared/SocialMedia/Facebook";
import Linkedin from "../../shared/SocialMedia/Linkedin";
import Twitter from "../../shared/SocialMedia/Twitter";
import { FaCaretRight } from "react-icons/fa";

export default function SingleNewsAndEvents() {
  const { id } = useParams();
  const { document } = useCollection("blogs");

  const [newsObj, setNewsObj] = useState({});

  useEffect(() => {
    let filteredNews = document?.filter((news) => news?.id === id);
    setNewsObj(filteredNews[0]);
  }, [id, document]);
  return (
    <>
      <ScrollToTop />
      <Layout>
        <div className="py-20 bg-white">
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="col-span-2">
                <p className="pb-2">
                  {" "}
                  {newsObj?.createdAt?.toDate().toISOString().split("T")[0]}
                </p>
                <h1 className="text-3xl pb-2 font-semibold">
                  {newsObj?.title}
                </h1>
                <img
                  src={newsObj?.image}
                  alt={newsObj?.title}
                  className="pb-4"
                />
                <div className="flex items-center gap-2 pb-2">
                  {newsObj?.tags?.map((tag) => (
                    <p className="border border-gray-500 text-xs px-4 py-1 rounded-full">
                      {tag}
                    </p>
                  ))}
                </div>
                <div className="flex items-center space-x-4 py-8">
                  <Facebook
                    url={`https://needlechallenge.com/${newsObj?.title}`}
                    qoute={newsObj?.title}
                  />
                  <Linkedin
                    url={`https://needlechallenge.com/${newsObj?.title}`}
                    qoute={newsObj?.title}
                  />
                  <Twitter
                    url={`https://needlechallenge.com/${newsObj?.title}`}
                    qoute={newsObj?.title}
                  />
                </div>
                <div
                  style={{
                    lineHeight: "1.6",
                    marginTop: "2rem",
                    textAlign: "justify",
                  }}
                >
                  <div
                    style={{ textAlign: "justify", width: "100%" }}
                    className="blog_description"
                    dangerouslySetInnerHTML={{ __html: newsObj?.description }}
                  />
                </div>
              </div>
              <div className=" w-full flex flex-col gap-6  mt-[75px]">
                {document?.slice(0, 4)?.map((news) => (
                  <div className="flex gap-4">
                    <div className="w-full">
                      <img src={news?.image} alt="" />
                    </div>
                    <div className="w-full">
                      <p>
                        {news?.createdAt?.toDate().toISOString().split("T")[0]}
                      </p>
                      <h2>{news?.title}</h2>
                      <div className="flex items-center gap-2 pb-2">
                        {news?.tags?.slice(0, 2)?.map((tag) => (
                          <p className="border border-gray-500 text-xs px-4 py-1 rounded-full">
                            {tag}
                          </p>
                        ))}
                      </div>
                      <div>
                        <Link to={`/news-events/${news?.id}`}>
                          <button className="py-2 text-black rounded-tl-xl rounded-br-xl cursor-pointer  flex justify-center items-center font-bold">
                            READ MORE
                            <FaCaretRight />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
