import React from "react";
import AdminLayout from "../../../shared/AdminLayout";
import AdminResourcesDetails from "./Components/AdminResourcesDetails";

export default function AdminResources() {
  return (
    <AdminLayout>
      <AdminResourcesDetails />
    </AdminLayout>
  );
}
