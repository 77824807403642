import React, { useEffect, useState } from "react";
import ApplicationNavbar from "./ApplicationNavbar";
import ApplicationBasicInfo from "./ApplicationBasicInfo";
import { useCollection } from "../../../hooks/useCollection";
import { useSelector } from "react-redux";
import { authDetail } from "../../../features/auth/authSlice";
import ApplicationStartupDetails from "./ApplicationStartupDetails";
import ApplicationProfile from "./ApplicationProfile";
import ApplicationSolution from "./ApplicationSolution";
import ApplicationEnvironmental from "./ApplicationEnvironmental";
import ApplicationAvailablity from "./ApplicationAvailablity";
import ApplicationAdvancedSolution from "./ApplicationAdvancedSolution";

export default function MatureApplications() {
  const {
    email,
    isLoading,
    isError,
    error: authError,
  } = useSelector(authDetail);

  let [page, setPage] = useState(1);

  const { document, isLoading: DocumentLoading } =
    useCollection("entrepreneur");
  const [user, setUser] = useState({});

  useEffect(() => {
    let filteredUser = document?.filter((user) => user?.email === email);
    setUser(filteredUser[0]);
  }, [document, email]);

  let [details, setDetails] = useState({
    stage: "",
    basicInfo: {
      fullName: "",
      gender: "",
      phoneNumber: "",
      age: "",
      email: "",
    },
    applicantProfile: {
      enterpriseName: "",
      enterpriseFounded: "",
      enterpriseHeadquartered: "",
      enterpriseRegistered: "",
      enterpriseRegisteredYear: "",
      enterpriseLocation: "",
      enterpriseInvestment: "",
      role: "",
      enterprisePeople: "",
      aboutTeam: "",
      acceleratorsParticipate: "",
    },
    Solution: {
      focusArea: "",
      problemSolution: "",
      problemAndPotential: "",
      solution: "",
      stage: "",
      businessModel: "",
      revenueModel: "",
      competitiveness: "",
      keyPartner: "",
      partnerName: "",
      pitchDeck: "",
    },
    environmentalImpact: {
      workImpact: "",
      businessModel: "",
      environmentalGoal: "",
      socialImpact: "",
      SDGGoals: "",
      challengeToSolve: "",
      stakeholders: [],
      newsHeadline: "",
      enablesFuture: "",
    },
    availability: {
      attendBootcamp: "",
      commitment: "",
    },
  });

  useEffect(() => {
    if (user?.email) {
      // setDetails(user);
      setDetails({
        ...user,
        basicInfo: {
          ...user?.basicInfo,
          fullName: user?.name,
          email: user?.email,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    let localStorageDetails = JSON.parse(
      localStorage.getItem("application_details")
    );
    // if (localStorageDetails) {
    //   setDetails(localStorageDetails);
    // }
  }, []);

  console.log("details", details, user);

  return (
    <div className="relative max-w-5xl mx-auto bg-white p-10 rounded-md z-10">
      <h2 className="font-bold pb-4">APPLICATION MODULES</h2>
      <ApplicationNavbar setPage={setPage} page={page} />
      {page === 1 && (
        <ApplicationBasicInfo
          setDetails={setDetails}
          details={details}
          setPage={setPage}
        />
      )}
      {page === 2 && (
        <ApplicationProfile
          setDetails={setDetails}
          details={details}
          setPage={setPage}
        />
      )}
      {page === 3 && (
        <ApplicationSolution
          setDetails={setDetails}
          details={details}
          setPage={setPage}
        />
      )}
      {page === 4 && (
        <ApplicationEnvironmental
          setDetails={setDetails}
          details={details}
          setPage={setPage}
        />
      )}
      {page === 5 && (
        <ApplicationAvailablity
          setDetails={setDetails}
          details={details}
          setPage={setPage}
        />
      )}
      {page === 6 && (
        <ApplicationAdvancedSolution
          setDetails={setDetails}
          details={details}
          setPage={setPage}
        />
      )}
    </div>
  );
}
