import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Signup from "./pages/signup/Signup";
import Signin from "./pages/signin/Signin";
import MatureStartup from "./pages/matureStartup/MatureStartup";
import CaseStudy from "./pages/caseStudy/CaseStudy";
import AdminProjects from "./AdminPanel/AdminProjects";
import { AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { authDetail, setUser, toggleUser } from "./features/auth/authSlice";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase.config";
import { useEffect, useState } from "react";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import Program from "./pages/Program/Program";
import ChallengeBg from "./pages/ChallengeBg/ChallengeBg";
import SolutionAreas from "./pages/SolutionAreas/SolutionAreas";
import WhyJoin from "./pages/WhyJoin/WhyJoin";
import Partners from "./pages/Partners/Partners";
import Contactus from "./pages/Contactus/Contactus";
import Process from "./pages/Process/Process";
import Eligibility from "./pages/Eligibility/Eligibility";
import NewsAndEvents from "./pages/NewsAndEvents/NewsAndEvents";
import IdeaStage from "./pages/IdeaStage/IdeaStage";
import { useCollection } from "./hooks/useCollection";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import Admin from "./pages/Admin/Projects/Admin";
import AdminProtectedRoute from "./ProtectedRoute/AdminProtectedRoute";
import AdminCaseStudy from "./pages/Admin/AdminCaseStudy/AdminCaseStudy";
import AdminAddCaseStudy from "./pages/Admin/AdminCaseStudy/AdminAddCaseStudy";
import AdminResources from "./pages/Admin/AdminResources.jsx/AdminResources";
import AdminAddResource from "./pages/Admin/AdminResources.jsx/AdminAddResource";
import MatureCaseStudy from "./pages/matureStartup/MatureCaseStudy";
import MatureResources from "./pages/matureStartup/MatureResources";
import IdeaStageCaseStudy from "./pages/IdeaStage/IdeaStageCaseStudy";
import IdeaStageResources from "./pages/IdeaStage/IdeaStageResources";
import AdminBlogs from "./pages/Admin/AdminBlogs/AdminBlogs";
import AdminAddNewBlog from "./pages/Admin/AdminBlogs/AdminAddNewBlog";
import SingleNewsAndEvents from "./pages/NewsAndEvents/SingleNewsAndEvents";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./pages/signin/ForgotPassword";

function App() {
  const location = useLocation();
  const {
    email,
    isLoading,
    isError,
    error: authError,
  } = useSelector(authDetail);
  const dispatch = useDispatch();
  const [isAuthReady, setIsAuthReady] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(
          setUser({ email: user?.email, displayName: user?.displayName })
        );
        setIsAuthReady(true);
      } else {
        dispatch(toggleUser());
        setIsAuthReady(true);
      }
    });
  }, [email]);

  const { document } = useCollection("needle_admin");

  useEffect(() => {
    if (document.length !== 0)
      localStorage.setItem("needle_admin", JSON.stringify(document));
  }, [document]);

  return (
    <div className="font-quantico overflow-x-hidden">
      <AnimatePresence initial={false}>
        <Helmet>
          <title>Needle</title>
          <meta
            name="description"
            content="Needle Innovation Challenge is a part of Oporajita- Collective Impact on Future of Work in Bangladesh. Oporajita is a collaborative initiative among 14 organizations, initiated by H&M Foundation and supported by Sweden and The Asia Foundation to equip women garment workers in Bangladesh for a future defined by automation and digitalization."
          />
          <meta
            name="keywords"
            content="Needle Innovation Challenge, Needle Challenge, Women Garment workers, RMG industry , Circular Innovation, Circularity , Automation in RMG , local RMG solutions, 4IR in RMG, apparel Industry"
          />
        </Helmet>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Program />} />
          <Route path="/challenge-background" element={<ChallengeBg />} />
          <Route path="/solution-areas" element={<SolutionAreas />} />
          <Route path="/why-join" element={<WhyJoin />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/timeline" element={<Process />} />
          <Route path="/eligibility" element={<Eligibility />} />
          <Route path="/news-events" element={<NewsAndEvents />} />
          <Route path="/news-events/:id" element={<SingleNewsAndEvents />} />

          {/* admin Login */}
          <Route path="/admin-login" element={<AdminLogin />} />

          {/* Admin Route */}
          <Route
            path="/admin/projects"
            element={
              <AdminProtectedRoute>
                <Admin />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="/admin/case-study"
            element={
              <AdminProtectedRoute>
                <AdminCaseStudy />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="/admin/add-case-study"
            element={
              <AdminProtectedRoute>
                <AdminAddCaseStudy />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="/admin/resources"
            element={
              <AdminProtectedRoute>
                <AdminResources />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="/admin/add-resource"
            element={
              <AdminProtectedRoute>
                <AdminAddResource />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="/admin/blogs"
            element={
              <AdminProtectedRoute>
                <AdminBlogs />
              </AdminProtectedRoute>
            }
          />
          <Route
            path="/admin/add-blog"
            element={
              <AdminProtectedRoute>
                <AdminAddNewBlog />
              </AdminProtectedRoute>
            }
          />

          {isAuthReady && (
            <>
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/signin" element={<Signin />} />
            </>
          )}
          {!isLoading && (
            <>
              <Route
                path="/mature-startup"
                element={
                  <ProtectedRoute>
                    <MatureStartup />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/idea-startup"
                element={
                  <ProtectedRoute>
                    <IdeaStage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mature/case-study"
                element={
                  <ProtectedRoute>
                    <MatureCaseStudy />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mature/resources"
                element={
                  <ProtectedRoute>
                    <MatureResources />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/idea/case-study"
                element={
                  <ProtectedRoute>
                    <IdeaStageCaseStudy />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/idea/resources"
                element={
                  <ProtectedRoute>
                    <IdeaStageResources />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/case-study"
                element={
                  <ProtectedRoute>
                    <CaseStudy />
                  </ProtectedRoute>
                }
              /> */}
              {/* <Route
                path="/admin-projects"
                element={
                  <ProtectedRoute>
                    <AdminProjects />{" "}
                  </ProtectedRoute>
                }
              /> */}
            </>
          )}

          {/* Admin */}
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
