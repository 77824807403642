import { deleteDoc, doc } from "firebase/firestore";
import React from "react";
import { FaLink, FaTrash } from "react-icons/fa";
import { db } from "../../../../firebase.config";

export default function AdminBlogtable({ blogs }) {
  const handleDeleteBlog = async (id) => {
    await deleteDoc(doc(db, "blogs", id));
  };
  return (
    <div className="my-8">
      <div className="w-full">
        <div className="max-w-full overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="text-left text-dark font-bold bg-[#D9D9D9]">
                <th className="w-3/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Title
                </th>
                <th className="w-2/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Publish Date
                </th>
                <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Image
                </th>
                <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:pl-8">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {!blogs?.length ? <p>No data found!</p> : null}
              {blogs?.map((blog, id) => (
                <tr
                  className={`border-t border-[#ddd] text-left text-dark py-4 ${
                    id % 2 == 0 ? "" : "bg-[#D9D9D9]"
                  }`}
                >
                  <td className="py-6 px-8  text-base">
                    <p>{blog?.title}</p>
                  </td>
                  <td className="py-6 px-8  text-base">
                    {blog?.createdAt.toDate().toISOString().split("T")[0]}
                  </td>
                  <td className="py-6 px-8  text-base">
                    <a href={blog?.image} target="_blank">
                      <FaLink className="ml-4" />
                    </a>
                  </td>
                  <td className="py-6 px-8  text-base">
                    <FaTrash
                      className="ml-4 cursor-pointer"
                      onClick={() => handleDeleteBlog(blog?.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
