import React from "react";
import { FaAngleDoubleDown, FaCaretRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Button from "../UI/Button";
import NavigateUser from "../utils/NavigateUser";
import { useSelector } from "react-redux";
import { authDetail } from "../../features/auth/authSlice";
import { useCollection } from "../../hooks/useCollection";

export default function Hero() {
  const scrollToBottom = () => {
    window.scrollTo({
      top: 920,
      left: 0,
      behavior: "smooth",
    });
  };
  const { email } = useSelector(authDetail);
  const { document, isLoading: documentLoading } =
    useCollection("entrepreneur");

  const navigate = useNavigate();
  const handleApply = () => {
    navigate(NavigateUser(document, email));
  };
  return (
    <div className="bg hero py-40 relative">
      <div className="flex flex-col justify-center items-center text-white px-4">
        <h1 className="text-2xl md:text-[55px] font-bold md:mb-12">
          Welcome to
        </h1>
        <h1 className="text-2xl md:text-[55px] text-center font-bold mb-12">
          Needle Innovation Challenge
        </h1>
        <p className="font-quantico max-w-xl text-center mb-8 text-[18px]">
          Launch your startup into the world to build a circular and sustainable
          apparel future for Bangladesh! 
        </p>

        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdlPYzuoPTwpWM_gnv6sbnkHOqWtzLjlo9nROrHd-O8Jsagbw/viewform"
          target="_blank"
          rel="noreferrer"
        >
          <Button
          // onClick={handleApply}
          >
            Apply Now
          </Button>
        </a>
      </div>

      <FaAngleDoubleDown
        className="absolute bottom-[30px] left-1/2 -translate-x-1/2 fill-white cursor-pointer"
        onClick={scrollToBottom}
      />
    </div>
  );
}
