import { useEffect, useRef, useState } from "react"
import { collection, limit, onSnapshot , query, orderBy} from 'firebase/firestore'
import { db } from '../firebase.config'

export const useCollection = (collectionName, limitData, orderByName) => {
    const [document, setDocument] = useState([])
    let [isLoading, setIsLoading] = useState(false)

    const limitDocument = useRef(limitData).current
    const orderByDocument = useRef(orderByName).current
    
    useEffect(() => {
        let ref = collection(db, collectionName)
        if(limitDocument) {
            ref = query(ref, limit(limitDocument))
        }
        
        if(orderByDocument) {
            ref = query(ref, orderBy(...orderByDocument))
        }

        setIsLoading(true)

        const unSubscribe = onSnapshot(ref, (snapshot) => {
            let result = []
            snapshot.docs.forEach(doc => {
                result.push({ ...doc.data(), id: doc.id })
            })
            setDocument(result)
            setIsLoading(false)
        })

        return () => unSubscribe()
    }, [collectionName, limitDocument, orderByDocument])

    return { document, isLoading }
}