import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaFacebookMessenger } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { authDetail, createUser } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useCollection } from "../../hooks/useCollection";
import Modal from "../../shared/Modal";
import TermAndCondition from "../Eligibility/Components/TermAndCondition";
import needle from "../../assets/logo2.png";
import ScrollToTop from "../../shared/utils/ScrollToTop";
import { firebaseErrorHandle } from "../../shared/utils/firebaseErrorHandle";

export default function Signup() {
  const [userDetail, setUserDetail] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    stage: "",
    neddUpdate: false,
  });
  const [showPass, setShowPass] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const {
    email: logedInUser,
    displayName: name,
    isLoading,
    isError,
    error: authError,
  } = useSelector(authDetail);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const { document, isLoading: documentLoading } =
    useCollection("entrepreneur");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const filteredUser = document?.filter(
      (user) => user?.email === logedInUser
    );

    if (logedInUser && userDetail?.stage === "Mature") {
      navigate("/mature-startup");
    } else if (logedInUser && userDetail?.stage === "Idea") {
      navigate("/idea-startup");
    } else if (filteredUser[0]?.stage === "Mature") {
      navigate("/mature-startup");
    } else if (filteredUser[0]?.stage === "Idea") {
      navigate("/idea-startup");
    }
  }, [logedInUser, document]);

  const { email, password, firstName, lastName, stage, neddUpdate } =
    userDetail;
  let displayName = firstName + " " + lastName;
  const handleSubmit = (e) => {
    if (
      !email ||
      !password ||
      !firstName ||
      !lastName ||
      !stage ||
      !neddUpdate
    ) {
      return;
    }
    dispatch(
      createUser({ email, password, displayName, stage: stage, neddUpdate })
    );
  };
  return (
    <>
      <div className="min-h-screen text-white bg-primary p-4 md:p-10 flex flex-col justify-center items-center">
        <div className="w-full">
          <div
            className="bg-white max-w-2xl mx-auto rounded-xl text-black px-12 py-8"
            style={{ boxShadow: "12px 15px 30px rgba(247, 37, 133, 0.4)" }}
          >
            <div className="flex justify-center items-center border-b-2 border-b-gray-300  pb-4">
              <Link to="/">
                <img src={needle} alt="Needle" className="w-[80px] h-auto" />
              </Link>
            </div>
            <div className="py-4">
              <p>Create your account to get started</p>
              <p className="text-sm">
                Already a member. Sign In{" "}
                <Link to="/signin">
                  <span className="text-secondary underline underline-offset-1">
                    here
                  </span>
                </Link>
              </p>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-6 md:gap-20">
              <div className=" w-full flex flex-col gap-2">
                <label for="fName" className="font-bold">
                  FIRST NAME <span>*</span>
                </label>
                <input
                  type="text"
                  name="fName"
                  id="fName"
                  placeholder="First Name"
                  className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                  value={userDetail.firstName}
                  onChange={(e) =>
                    setUserDetail({ ...userDetail, firstName: e.target.value })
                  }
                />
              </div>
              <div className=" w-full flex flex-col gap-2">
                <label for="lName" className="font-bold">
                  LAST NAME <span>*</span>
                </label>
                <input
                  type="text"
                  name="lName"
                  id="lName"
                  placeholder="Last Name"
                  className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                  value={userDetail.lastName}
                  onChange={(e) =>
                    setUserDetail({ ...userDetail, lastName: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="pt-6">
              <div className=" w-full flex flex-col gap-2">
                <label for="fEmail" className="font-bold">
                  EMAIL ADDRESS <span>*</span>
                </label>
                <input
                  type="text"
                  name="fEmail"
                  id="fEmail"
                  placeholder="Your Email"
                  className="bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                  value={userDetail.email}
                  onChange={(e) =>
                    setUserDetail({ ...userDetail, email: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="pt-6">
              <div className=" w-full flex flex-col gap-2">
                <label for="fPass" className="font-bold">
                  PASSWORD (min. 8 characters) <span>*</span>
                </label>
                <div className="w-full relative">
                  <input
                    type={showPass ? "text" : "password"}
                    name="fPass"
                    id="fPass"
                    placeholder="Your Password"
                    className="w-full bg-white border border-gray-500 rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none"
                    value={userDetail.password}
                    onChange={(e) =>
                      setUserDetail({ ...userDetail, password: e.target.value })
                    }
                  />
                  {showPass ? (
                    <FaEye
                      className="absolute top-1/2 right-[15px] -translate-y-1/2"
                      onClick={() => setShowPass(false)}
                    />
                  ) : (
                    <FaEyeSlash
                      className="absolute top-1/2 right-[15px] -translate-y-1/2"
                      onClick={() => setShowPass(true)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="pt-6">
              <div className=" w-full flex flex-col gap-2">
                <label className="font-bold">
                  MY SOLUTION is... <span>*</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="subject"
                    value="Mature"
                    onChange={(e) =>
                      setUserDetail({ ...userDetail, stage: e.target.value })
                    }
                  />{" "}
                  Existing Solution (1.5+ years of business traction)
                </label>
                {/* <label>
                  <input
                    type="radio"
                    name="subject"
                    value="Idea"
                    onChange={(e) =>
                      setUserDetail({ ...userDetail, stage: e.target.value })
                    }
                  />{" "}
                  Idea Stage Solution (individual entrepreneurs or teams with
                  new ideas)
                </label> */}
              </div>
            </div>
            <div className="pt-6">
              <div className=" w-full flex flex-col gap-2">
                <label className="font-bold">
                  WOULD YOU LIKE US TO LET YOU KNOW ABOUT ANY UPDATES, NEWS,
                  EVENTS & OPPORTUNITIES? <span>*</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="update"
                    value="Yes"
                    onChange={(e) =>
                      setUserDetail({
                        ...userDetail,
                        neddUpdate: e.target.value,
                      })
                    }
                  />{" "}
                  Yes, I definitely want to be up to date.
                </label>
                <label>
                  <input
                    type="radio"
                    name="update"
                    value="No"
                    onChange={(e) =>
                      setUserDetail({
                        ...userDetail,
                        neddUpdate: e.target.value,
                      })
                    }
                  />{" "}
                  Not for now, Thank You.
                </label>
              </div>
            </div>
            <p className="py-8 text-xs">
              By creating your account, you agree to our{" "}
              <span
                className="text-secondary underline underline-offset-1 cursor-pointer"
                onClick={() => setShowModal(true)}
              >
                Terms and Conditions
              </span>{" "}
              {/* &{" "}
            <span className="text-secondary underline underline-offset-1">
              Privacy Policy
            </span> */}
            </p>
            {isError && (
              <p className="text-red-500 pb-2">
                {firebaseErrorHandle(authError)}
              </p>
            )}

            {isLoading ? (
              <button className="bg-secondary px-4 py-2 text-white rounded-tr-2xl rounded-bl-2xl">
                Processing
              </button>
            ) : (
              <button
                className="bg-secondary px-4 py-2 text-white rounded-tr-2xl rounded-bl-2xl"
                onClick={handleSubmit}
              >
                LETS GO
              </button>
            )}
          </div>
          <p className="text-white text-center pt-20">
            Copyright © 2023 | Needle
          </p>
        </div>
        {/* <div className="fixed bottom-[50px] right-[50px]">
        <FaFacebookMessenger className="w-10 h-10 z-10" />
      </div> */}

        {showModal && (
          <Modal setShowModal={setShowModal}>
            <TermAndCondition />
          </Modal>
        )}
      </div>
    </>
  );
}
