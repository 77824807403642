import React, { useEffect, useRef, useState } from "react";
import ProjectsNavbar from "./ProjectsNavbar";
import ProjectsTable from "./ProjectsTable";
import { useCollection } from "../../../../hooks/useCollection";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import AdminProjectMatureTable from "./AdminProjectMatureTable";
import AdminProjecetIdeaTable from "./AdminProjecetIdeaTable";
import { projectProgress } from "../../../../shared/utils/ProjectProgress";

export default function AdminDetails() {
  let [showModal, setShowModal] = useState(false);
  const componentRef = useRef();

  const [projectPhase, setProjectPhase] = useState("all");
  const [projects, setProjects] = useState([]);

  const { document, isLoading } = useCollection("entrepreneur");

  // Sorting with percentage
  useEffect(() => {
    if (projectPhase === "all") {
      let filteredData = document
        .map((project) => ({
          ...project,
          percentage: projectProgress(project),
        }))
        .sort((a, b) => b.percentage - a.percentage);
      setProjects(filteredData);
    } else {
      let filteredProject = document
        ?.map((project) => ({
          ...project,
          percentage: projectProgress(project),
        }))
        ?.sort((a, b) => b.percentage - a.percentage)
        ?.filter((project) => project?.stage === projectPhase);
      setProjects(filteredProject);
    }
  }, [projectPhase, document]);

  return (
    <div className="relative md:w-full py-20 mx-4">
      <div className="relative max-w-5xl mx-auto bg-white p-4 md:p-10 rounded-md z-10">
        <div className="pb-6 flex justify-between">
          <h2 className="font-bold text-xl">Projects</h2>
          {/* <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={handlePrint}
          >
            <FaDownload />
            <p>Download All Data</p>
          </div> */}
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="px-4 py-2 bg-secondary text-white rounded-md"
            table="table-to-xls"
            filename="needle_applicant"
            sheet="tablexls"
            buttonText="Download as XLS"
          />
        </div>
        <div className="p-4" ref={componentRef}>
          <ProjectsNavbar
            setShowModal={setShowModal}
            setProjectPhase={setProjectPhase}
            projectPhase={projectPhase}
          />
          {projectPhase === "all" && (
            <ProjectsTable
              projects={projects}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
          {projectPhase === "Mature" && (
            <AdminProjectMatureTable
              projects={projects}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
          {projectPhase === "Idea" && (
            <AdminProjecetIdeaTable
              projects={projects}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </div>
      </div>
    </div>
  );
}
