import React, { useEffect } from "react";
import IdeaStageProgress from "./IdeaStageProgress";
import IdeaApplications from "./IdeaApplications";
import circle from "../../../assets/circle.png";

export default function IdeaStageDetails() {
  return (
    <div className="relative md:w-full py-20 mx-4">
      {/* Deadline finishing Greet */}
      <div className="max-w-5xl mx-auto bg-white p-10 rounded-md mb-8">
        <h1 className="text-center font-bold text-secondary">
          The idea-stage application submission is now closed!
        </h1>
      </div>

      {/* While application ongoing */}
      {/* <IdeaStageProgress /> */}
      {/* <IdeaApplications /> */}
      <img src={circle} className="absolute bottom-0 right-0 z-0" alt="" />
    </div>
  );
}
