import React from "react";
import IdeaStageLayout from "../../shared/IdeaStageLayout";
import CaseStudyDetails from "../caseStudy/Components/CaseStudyDetails";

export default function IdeaStageCaseStudy() {
  return (
    <IdeaStageLayout>
      <CaseStudyDetails />
    </IdeaStageLayout>
  );
}
