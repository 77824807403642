import React, { useState } from "react";
import Heading from "../../../shared/Heading";
import { motion } from "framer-motion";
import { processDataFirst, processDataSecond } from "../data";
import ProcessNavigation from "./ProcessNavigation";

export default function ProcessFirst() {
  const [category, setCategory] = useState("Idea Stage Solution");
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className="pt-20"
    >
      <Heading firstHeading="Our" secondHeading="Process" />
      <ProcessNavigation category={category} setCategory={setCategory} />
      <div className="bg bg-[#230A4F] mt-10 py-20 text-white">
        <div class="relative max-w-7xl px-6 mx-auto">
          {/* <div class=" absolute top-24 w-10/12 left-16 h-3 bg-white md:hidden"></div> */}
          <div class="absolute w-[1px] left-1/2 h-full  bg-white md:block z-0"></div>

          {category === "Idea Stage Solution" &&
            processDataSecond?.map((process, id) => {
              if (id % 2 === 0) {
                return (
                  <div className={`relative z-10 ${id !== 0 ? "pt-16" : 0}`}>
                    <div className="w-full mx-auto text-center ">
                      <h2 className="text-center bg-secondary px-4 py-2 inline-block rounded-md ">
                        {process?.title}
                      </h2>
                    </div>
                    <div className="grid gap-4 md:gap-20 grid-cols-1 md:grid-cols-2 mt-6 relative">
                      <div className="group bg-white text-black p-4 rounded-md z-20 hover:bg-secondary hover:text-white border transition-all duration-200 hover:border-white cursor-pointer">
                        <div class="absolute w-[20px] h-[20px] rounded-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:block group-hover:bg-secondary  -z-10 "></div>
                        <p>{process?.details}</p>
                        <p className=" text-md text-right text-secondary font-bold group-hover:text-white">
                          {process?.date}
                        </p>
                      </div>
                      <div></div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="pt-16 relative z-10">
                    <div className="w-full mx-auto text-center z-10">
                      <h2 className="text-center bg-secondary px-4 py-2 inline-block rounded-md">
                        {process?.title}
                      </h2>
                    </div>
                    <div className="grid gap-4 md:gap-20 grid-cols-1 md:grid-cols-2 mt-6 relative">
                      <div></div>
                      <div className="group bg-white text-black p-4 rounded-md z-20 hover:bg-secondary hover:text-white border transition-all duration-200 hover:border-white cursor-pointer">
                        <div class="absolute w-[20px] h-[20px] rounded-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:block group-hover:bg-secondary  -z-10"></div>
                        <p>{process?.details}</p>
                        <p className=" text-md text-right text-secondary font-bold group-hover:text-white">
                          {process?.date}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            })}

          {category === "Existing Solution" &&
            processDataFirst?.map((process, id) => {
              if (id % 2 === 0) {
                return (
                  <div className={`relative z-10 ${id !== 0 ? "pt-16" : 0}`}>
                    <div className="w-full mx-auto text-center ">
                      <h2 className="text-center bg-secondary px-4 py-2 inline-block rounded-md ">
                        {process?.title}
                      </h2>
                    </div>
                    <div className="grid gap-4 md:gap-20 grid-cols-1 md:grid-cols-2 mt-6 relative">
                      <div className="group bg-white text-black p-4 rounded-md z-20 hover:bg-secondary hover:text-white border transition-all duration-200 hover:border-white cursor-pointer">
                        <div class="absolute w-[20px] h-[20px] rounded-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white group-hover:bg-secondary md:block -z-10 "></div>
                        <p>{process?.details}</p>
                        <p className=" text-md text-right text-secondary font-bold group-hover:text-white">
                          {process?.date}
                        </p>
                      </div>
                      <div></div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="pt-16 relative z-10">
                    <div className="w-full mx-auto text-center z-10">
                      <h2 className="text-center bg-secondary px-4 py-2 inline-block rounded-md">
                        {process?.title}
                      </h2>
                    </div>
                    <div className="grid gap-4 md:gap-20 grid-cols-1 md:grid-cols-2 mt-6 relative">
                      <div></div>
                      <div className="group bg-white text-black p-4 rounded-md z-20 hover:bg-secondary hover:text-white border transition-all duration-200 hover:border-white cursor-pointer">
                        <div class="absolute w-[20px] h-[20px] rounded-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white md:block group-hover:bg-secondary  -z-10"></div>
                        <p>{process?.details}</p>
                        <p className=" text-md text-right text-secondary font-bold group-hover:text-white">
                          {process?.date}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </motion.div>
  );
}
