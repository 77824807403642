import React from "react";
import join1 from "../../../assets/join1.png";

export default function JoinPhaseone() {
  return (
    <div className="flex flex-col gap-4 justify-center items-center">
      <img src={join1} alt="" />
      <p className="text-center text-[16px]">
        Gain valuable insights and expertise through intensive two 5-day
        residential boot camps, covering customized curricula on business
        modeling, financial strategies, and circularity.
      </p>
    </div>
  );
}
