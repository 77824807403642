import React, { useState } from "react";
import Layout from "../../shared/Layout";
import ProcessFirst from "./Components/ProcessFirst";
import ScrollToTop from "../../shared/utils/ScrollToTop";
import ProcessNavigation from "./Components/ProcessNavigation";

export default function Process() {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <ProcessFirst />
      </Layout>
    </>
  );
}
