import React from "react";
import { FaCaretRight } from "react-icons/fa";
import Heading from "../../../shared/Heading";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Button from "../../../shared/UI/Button";

export default function Challenge() {
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className=" py-20 innovation bg-[#230A4F]"
    >
      <div className="max-w-7xl mx-auto text-black">
        <h2 className="text-2xl md:text-5xl text-center font-bold  pb-6 text-white">
          Needle Innovation 2024
        </h2>
        <div className="py-10 flex flex-col gap-2 items-center text-center text-white px-4">
          <p className="text-lg">
            An open call for new and existing impactful solutions to enhance the
            competitiveness of the Bangladesh RMG industry and the employability
            of women garment workers.
          </p>
          <p className="text-lg">
            Through this programme, you will receive customized curricula,
            coaching, mentoring, pollination grants, and partnership
            opportunities to build, launch, and strengthen your solutions in one
            of the six solution areas to make the RMG and support industry
            sustainable and circular.
          </p>
          <p className="text-lg">
            We believe that innovation is the key to answering the industry’s
            challenges in sustaining its competitiveness in the global market
            while ensuring improved work prospects and lives of its key driver -
            women garment workers!
          </p>
          <p className="text-lg">
            Hence, we need to work together to identify and amplify solutions
            that will help to build a plant positive, inclusive, and competitive
            future of work for women garment workers.
          </p>
          <p className="text-lg font-bold pt-4">Ready to take the challenge?</p>
        </div>

        <div className="flex justify-center">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdlPYzuoPTwpWM_gnv6sbnkHOqWtzLjlo9nROrHd-O8Jsagbw/viewform"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Participate</Button>
          </a>
        </div>
      </div>
    </motion.div>
  );
}
