import React, { useState } from "react";
import { Navigate } from "react-router-dom";

export default function AdminLogin() {
  let initialCredential = {
    email: "",
    password: "",
  };
  var main_credentials = JSON.parse(localStorage.getItem("needle_admin"));
  let [loginCredentails, setLoginCredentials] = useState({
    ...initialCredential,
  });

  let [error, setError] = useState("");

  const [user, setUser] = useState(false);

  const handleSubmit = (e) => {
    setError("");
    e.preventDefault();
    if (isUser(loginCredentails)) {
      localStorage.setItem("needle_admin_loggedIn", "true");
      setUser(true);
      // setLoginCredentials({ ...initialCredential })
    } else {
      setError("please provide admin credential");
      // setLoginCredentials({ ...initialCredential })
    }
  };

  const isUser = (data) => {
    for (let i = 0; i < main_credentials.length; i++) {
      if (
        main_credentials[i]?.email === data?.email &&
        main_credentials[i]?.password === data?.password
      ) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      {user ? (
        <Navigate to="/admin/projects" />
      ) : (
        <section className="h-screen flex flex-col justify-center items-center">
          <h2 style={{ marginBottom: "3rem", fontSize: "3rem" }}>
            Admin login credential
          </h2>
          <form
            onSubmit={handleSubmit}
            className="w-[400px] flex flex-col gap-2 bg-primary p-6 rounded-md"
          >
            <label>
              <p className="text-white">Admin Email</p>
              <input
                type="text"
                className="w-full p-2 rounded-md focus:outline-none"
                value={loginCredentails.email}
                onChange={(e) =>
                  setLoginCredentials({
                    ...loginCredentails,
                    email: e.target.value,
                  })
                }
              />
            </label>
            <label className="py-2">
              <p className="text-white">Admin password</p>
              <input
                type="password"
                className="w-full p-2 rounded-md focus:outline-none"
                value={loginCredentails.password}
                onChange={(e) =>
                  setLoginCredentials({
                    ...loginCredentails,
                    password: e.target.value,
                  })
                }
              />
            </label>
            <div>
              <input
                className="w-full bg-white text-black p-2 rounded-md  text-xl "
                type="Submit"
                value="login"
              />
            </div>
          </form>
          {error && <p className="text-red-500 font-bold">{error}</p>}
        </section>
      )}
    </>
  );
}
