import React from "react";
import {
  FaCaretRight,
  FaEnvelope,
  FaFacebookSquare,
  FaInstagram,
  FaLinkedinIn,
  FaLocationArrow,
  FaPhone,
  FaRegEnvelope,
} from "react-icons/fa";
import footer_img from "../../assets/footer_image.png";
import Needlelogo from "../../assets/red_logo2.png";
import { Link } from "react-router-dom";
import Button from "../UI/Button";
import BlueButton from "../UI/BlueButton";

export default function Footer() {
  let year = new Date().getFullYear();
  return (
    <div className="py-10 bg-secondary text-white relative">
      <div className="max-w-7xl mx-auto pb-6 px-0 flex justify-center md:justify-start items-center">
        <img
          loading="lazy"
          src={Needlelogo}
          alt="Needle"
          className="w-[100px] h-auto"
        />
      </div>
      <div className="max-w-7xl mx-auto flex gap-6 flex-col md:flex-row justify-between items-center ">
        {/* <div className="max-w-7xl mx-auto grid grid-cols-4"> */}
        <div className="md:w-[400px]">
          {/* <h1 className="font-bold text-xl">Needle</h1> */}
          <div className="text-center md:text-left px-2 md:px-0">
            <h2 className="font-bold text-2xl pb-4">
              Needle Innovation Challenge
            </h2>
            <p className=" pb-2">
              Needle Innovation Challenge is a part of Oporajita- Collective
              Impact on Future of Work in Bangladesh. Oporajita is a
              collaborative initiative among 14 organizations, initiated by H&M
              Foundation and supported by Sweden and The Asia Foundation to
              equip women garment workers in Bangladesh for a future defined by
              automation and digitalization.
            </p>
            <div className="flex itmes-center justify-center md:justify-start gap-2 pt-4">
              <a
                href="https://www.linkedin.com/company/needle-challenge/about/"
                target="_blank"
              >
                <FaLinkedinIn className="w-6 h-6" />
              </a>
              {/* <FaInstagram className="w-6 h-6" /> */}
              <a
                href="https://www.facebook.com/needlechallenge?mibextid=ZbWKwL"
                target="_blank"
              >
                <FaFacebookSquare className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/4 flex flex-col gap-4 px-6 md:px-0">
          <div className="flex items-center gap-4">
            <FaPhone />
            <p className="font-ruda">+88 01911-294278</p>
          </div>
          <div className="flex items-center gap-4">
            <FaEnvelope />
            <p className="font-ruda">info@buildbangladesh.org.bd</p>
          </div>
          <div className="flex items-center gap-4">
            <FaLocationArrow />
            <p className="font-ruda">
              Evergreen Plaza (first floor) 260/B, <br /> Tejgaon Industrial
              Area, Dhaka 1208
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/4 flex flex-col gap-4 px-6 md:px-0">
          <p>Join our newsletter</p>
          <div className="flex items-center gap-4">
            <input
              type="EMAIL"
              name="fEmail"
              id="fEmail"
              placeholder="Your Email"
              className="w-full bg-transparent border border-white rounded-tl-[15px] rounded-br-[15px] px-4 py-2 focus:outline-none text-white placeholder:text-white"
            />
          </div>
          <div className="w-full">
            <button className="w-full md:max-w-fit px-8 py-2 text-secondary bg-white rounded-tl-xl rounded-br-xl cursor-pointer border-2 border-white  text-[18px] shadow-btn btn flex items-center border-none ">
              Submit
              <FaCaretRight className="inine btn_arrow" />
            </button>
          </div>
        </div>
      </div>
      <p className="text-center pt-8 md:pt-20">Copyright © {year} | Needle</p>
      <p className="text-center pt-2">
        Developed by{" "}
        <a href="https://ongshak.com/" target="_blank" className="">
          Ongshak
        </a>
      </p>
    </div>
  );
}
