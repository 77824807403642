import React from "react";
import { motion } from "framer-motion";
import { solutionData } from "../../SolutionAreas/data";
import Button from "../../../shared/UI/Button";

export default function OurSolution({ setSolutionObj, setShowModal }) {
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <div className="bg solution py-20 px-4">
        <h1 className="text-center font-bold text-3xl pb-6 text-white">
          Our Solution Areas
        </h1>
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-10">
          {solutionData?.map((solution) => (
            <div
              key={solution.id}
              className="bg-white rounded-lg overflow-hidden text-center "
            >
              <div className="relative w-full">
                <img
                  // loading="lazy"
                  src={solution.img}
                  className="relative w-full h-auto "
                  alt={solution.title}
                />
                <div className="absolute bottom-[20px] left-0 text-center  w-full">
                  <p className="text-white text-[20px] text-center px-6 w-full">
                    {solution.title}
                  </p>
                </div>
              </div>
              <div className="flex  justify-center items-center py-8">
                <Button
                  onClick={() => {
                    setSolutionObj(solution);
                    setShowModal(true);
                  }}
                >
                  Read more
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}
