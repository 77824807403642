import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GithubAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { auth, db, timeStamp } from "../../firebase.config";
import { addDoc, collection } from "firebase/firestore";
import { useSelector } from "react-redux";

const initialState = {
  email: "",
  displayName: "",
  error: "",
  isLoading: true,
  isError: false,
};

export const createUser = createAsyncThunk(
  "auth/createUser",
  async ({ email, password, displayName, stage, neddUpdate }) => {
    await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(auth.currentUser, { displayName });
    const ref = collection(db, "entrepreneur");
    const createdAt = timeStamp.fromDate(new Date());
    let entrepreneurObject =
      stage === "Mature"
        ? {
            name: displayName,
            email,
            stage,
            neddUpdate,
            createdAt,
            basicInfo: {
              fullName: "",
              gender: "",
              phoneNumber: "",
              age: "",
              email: "",
              universityName: "",
              teamMember: "",
              aboutTeam: "",
              isParticipateInnovationChallenge: "",
            },
            applicantProfile: {
              enterpriseName: "",
              enterpriseFounded: "",
              enterpriseHeadquartered: "",
              enterpriseRegistered: "",
              enterpriseRegisteredYear: "",
              enterpriseLocation: "",
              enterpriseInvestment: "",
              role: "",
              enterprisePeople: "",
              aboutTeam: "",
              acceleratorsParticipate: "",
            },
            Solution: {
              focusArea: "",
              problemSolution: "",
              problemAndPotential: "",
              solution: "",
              stage: "",
              businessModel: "",
              revenueModel: "",
              competitiveness: "",
              keyPartner: "",
              partnerName: "",
              pitchDeck: "",
            },
            environmentalImpact: {
              workImpact: "",
              businessModel: [],
              environmentalGoal: [],
              challengeToSolve: "",
              socialImpact: "",
              SDGGoals: "",
              stakeholders: [],
              newsHeadline: "",
              enablesFuture: "",
            },
            availability: {
              attendBootcamp: "",
              commitment: "",
            },
          }
        : {
            name: displayName,
            email,
            stage,
            neddUpdate,
            createdAt,
            basicInfo: {
              fullName: "",
              gender: "",
              phoneNumber: "",
              age: "",
              email: "",
              universityName: "",
              teamMember: "",
              aboutTeam: "",
              isParticipateInnovationChallenge: "",
            },
            Solution: {
              focusArea: "",
              problemSolution: "",
              problemAndPotential: "",
              solution: "",
              stage: "",
              businessModel: "",
              revenueModel: "",
              competitiveness: "",
              keyPartner: "",
              partnerName: "",
              pitchDeck: "",
            },
            environmentalImpact: {
              workImpact: "",
              businessModel: [],
              environmentalGoal: [],
              challengeToSolve: "",
              socialImpact: "",
              SDGGoals: "",
              stakeholders: [],
            },
            availability: {
              attendBootcamp: "",
              commitment: "",
            },
          };
    await addDoc(ref, entrepreneurObject);
    return auth.currentUser;
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password }) => {
    await signInWithEmailAndPassword(auth, email, password);
    return auth.currentUser;
  }
);

const authSLice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.email = "";
      state.displayName = "";
    },
    setUser: (state, { payload }) => {
      state.email = payload.email;
      state.displayName = payload.displayName;
      state.isLoading = false;
    },
    toggleUser: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
        state.error = "";
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.error = "";
        state.email = action.payload.email;
        state.displayName = action.payload.displayName;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
        state.email = "";
        state.displayName = "";
      })
      // Login Case
      .addCase(loginUser.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
        state.error = "";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.error = "";
        state.email = action.payload.email;
        state.displayName = action.payload.displayName;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
        state.email = "";
        state.displayName = "";
      });
  },
});

export const authDetail = (state) => state.auth;

export default authSLice.reducer;
export const { logout, setUser, toggleUser } = authSLice.actions;
