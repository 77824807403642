import React, { useState } from "react";
import innovation1 from "../../../assets/innovation1.png";
import { FaCaretRight } from "react-icons/fa";
import { useCollection } from "../../../hooks/useCollection";

export default function InnovationChallenge() {
  const [numberOfCase, setNumberOfCase] = useState(2);
  const { document, isloading } = useCollection("case_study");
  return (
    <div className="max-w-5xl mx-auto bg-white p-4 md:p-10 rounded-md mb-8 relative z-10">
      <h1 className="font-extrabold pb-2 border-b-2 border-gray-200">
        INNOVATION <span className="text-secondary">CHALLENGE</span>
      </h1>

      <div className="pb-10">
        {!document?.length && (
          <p className="pt-8 text-center">
            Sorry, but there are currently no data to share at this time. Please
            check back later for any new updates.
          </p>
        )}
        {/* <p>Case study will be available soon!</p> */}
        {document?.slice(0, numberOfCase)?.map((caseStudy, id) => {
          if (id % 2 === 0) {
            return (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center  mt-20">
                <div>
                  <img
                    src={caseStudy?.coverImage}
                    alt="Innovation 1"
                    className="rounded-2xl border-4 border-black"
                  />
                </div>
                <div className="col-span-2">
                  <h2 className="text-2xl font-bold pb-4">
                    {caseStudy?.title}
                  </h2>
                  <p className="pb-2">{caseStudy?.descripion}</p>
                  <a href={caseStudy?.pdf} target="_blank">
                    <div className="flex items-center">
                      <p className="font-bold">DOWNLOAD THE CASE STUDY</p>
                      <FaCaretRight />
                    </div>
                  </a>
                </div>
              </div>
            );
          } else {
            return (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center mt-20">
                <div className="col-span-2">
                  <h2 className="text-2xl font-bold pb-4">
                    {caseStudy?.title}
                  </h2>
                  <p className="pb-2">{caseStudy?.descripion}</p>
                  <a href={caseStudy?.pdf} target="_blank">
                    <div className="flex items-center">
                      <p className="font-bold">DOWNLOAD THE CASE STUDY</p>
                      <FaCaretRight />
                    </div>
                  </a>
                </div>
                <div>
                  <img
                    src={caseStudy?.coverImage}
                    alt="Innovation 1"
                    className="rounded-2xl border-4 border-black"
                  />
                </div>
              </div>
            );
          }
        })}
        {document?.length > numberOfCase ? (
          <div className="text-right ml-auto">
            <div
              className="flex items-center justify-end mt-8 cursor-pointer"
              onClick={() => setNumberOfCase(numberOfCase + 2)}
            >
              <p className="font-bold">SHOW MORE</p>
              <FaCaretRight />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
