import React from "react";
import { FaCaretRight } from "react-icons/fa";
import { useCollection } from "../../hooks/useCollection";

export default function AllResources() {
  const { document } = useCollection("resources");
  return (
    <div className="max-w-5xl mx-auto bg-white p-4 md:p-10 rounded-md mb-8 relative z-10">
      <h1 className="font-extrabold pb-2 border-b-2 border-gray-200">
        PITCH <span className="text-secondary">RESOURCES</span>
      </h1>

      <div className="pb-10">
        {!document?.length && (
          <p className="pt-8 text-center">
            Sorry, but there are currently no data to share at this time. Please
            check back later for any new updates.
          </p>
        )}
        {document?.map((resource) => (
          <div className="pt-10">
            <h2 className=" text-xl font-bold inline-block pb-2 border-b border-gray-500 uppercase">
              {resource?.title}
            </h2>
            <p className="text-justify py-4">{resource?.descripion}</p>
            <a href={resource?.pdf} target="_blank">
              <div className="flex items-center">
                <p className="font-bold underline">DOWNLOAD</p>
                <FaCaretRight />
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
