import processIcon1 from "../../assets/processIcon1.png";
import processIcon2 from "../../assets/processIcon2.png";
import processIcon3 from "../../assets/processIcon3.png";
import processIcon4 from "../../assets/processIcon4.png";
import processIcon5 from "../../assets/processIcon5.png";
import processIcon6 from "../../assets/processIcon6.png";
import processIcon7 from "../../assets/processIcon7.png";

import idea1 from "../../assets/idea1.png";
import idea2 from "../../assets/idea2.png";
import idea3 from "../../assets/idea3.png";
import idea4 from "../../assets/idea4.png";
import idea5 from "../../assets/idea5.png";
import idea6 from "../../assets/idea6.png";
import idea7 from "../../assets/idea7.png";
import idea8 from "../../assets/idea8.png";
import idea9 from "../../assets/idea9.png";
import idea10 from "../../assets/idea10.png";
import idea11 from "../../assets/idea11.png";

export const processDataFirst = [
  {
    id: 1,
    icon: processIcon1,
    title: "Application",
    date: "April 2024 - May 2024",
    details:
      "Read the challenge materials, explore the opportunity, and submit your application on the application form provided on the website.",
  },
  {
    id: 2,
    icon: processIcon2,
    title: "Screening",
    date: "May 2024",
    details:
      "Startups/solutions are screened by a jury board of industry experts, and businesses are shortlisted based on their idea’s market attractiveness, impact potential, and feasibility.",
  },
  {
    id: 3,
    icon: processIcon3,
    title: "Announcement",
    date: "June 2024",
    details:
      "Names of the selected startups are announced, and we onboard the winning startups with us for a journey of growth and impact.",
  },
  {
    id: 4,
    icon: processIcon4,
    title: "2nd Residential Bootcamp",
    date: "June 2024",
    details:
      "Founders and Co-founders receive customized curricula led by industry experts to refine their business models and develop sustainable financial plans.",
  },
  {
    id: 5,
    icon: processIcon5,
    title: "Implementation Phase",
    date: "July 2024 – October 2024",
    details:
      "Following the 2nd Residential Bootcamp, innovators will submit proposals for funding for on ground implementation of their idea stage solutions.",
  },
  {
    id: 6,
    icon: processIcon6,
    title: "Demo Day",
    date: "November 2024 ",
    details:
      "Pitching night, bringing together innovators, RMG leaders, and investors to showcase the solutions.",
  },
  {
    id: 7,
    icon: processIcon7,
    title: "Scale Up",
    date: "November 2024",
    details:
      "Innovators are matched with industry leaders/investors for attracting funds and/or partnership opportunities. ",
  },
];
export const processDataSecond = [
  {
    id: 1,
    icon: idea1,
    title: "Application",
    date: "January 2024 – February 2024",
    details:
      "Read the challenge materials, explore the opportunity, and submit your application on the application form provided on the website.",
  },
  {
    id: 2,
    icon: idea2,
    title: "IDEATHON",
    date: "March 2024",
    details:
      "Shortlisted teams participate in a day-long IDEATHON event, where they receive in-person feedback and mentoring to refine their businesses and showcase their ideas in front of the jury board. ",
  },
  {
    id: 3,
    icon: idea3,
    title: "Screening",
    date: "March 2024",
    details:
      "Ideas are screened by a jury board of industry experts, and teams are shortlisted based on their idea’s market attractiveness, impact potential, and feasibility. ",
  },
  {
    id: 4,
    icon: idea4,
    title: "Announcement",
    date: "March 2024",
    details:
      "Names of the final round winners are announced, and we onboard the winning teams with us for a journey of growth and impact. ",
  },
  {
    id: 5,
    icon: idea5,
    title: "1st Residential Bootcamp",
    date: "March 2024",
    details:
      "The winning innovators attend the 1st Residential Bootcamp to focus on finetuning the ideas, resulting in the development of a minimum viable product. ",
  },
  {
    id: 6,
    icon: idea6,
    title: "MVP Phase",
    date: "April 2024 – June 2024",
    details:
      "Innovators receive grants to put their MVP to the test by prototyping their solutions and deepening their market analysis.",
  },
  {
    id: 7,
    icon: idea7,
    title: "2nd Residential Bootcamp",
    date: "June 2024 ",
    details:
      "Innovators receive customized curricula led by industry experts to refine their business models and develop sustainable financial plans.",
  },
  {
    id: 8,
    icon: idea8,
    title: "Implementation Phase",
    date: "July 2024 – October 2024",
    details:
      "Following the 2nd Residential Bootcamp, innovators will submit proposals for funding for on ground implementation of their idea stage solutions.",
  },
  {
    id: 9,
    icon: idea9,
    title: "Demo Day",
    date: "November 2024",
    details:
      "Pitching night, bringing together innovators, RMG leaders, and investors to showcase the solutions. ",
  },
  {
    id: 10,
    icon: idea10,
    title: "Scale Up",
    date: "November 2024",
    details:
      "Innovators are matched with industry leaders/investors for attracting funds and/or partnership opportunities.",
  },
];
