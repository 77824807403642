import React, { useEffect, useState } from "react";
import { useCollection } from "../../../hooks/useCollection";
import { authDetail } from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";

export default function MatureStartupProgrss() {
  const { email, error: authError } = useSelector(authDetail);
  const { document, isLoading: DocumentLoading } =
    useCollection("entrepreneur");
  const [user, setUser] = useState({});

  useEffect(() => {
    let filteredUser = document?.filter((user) => user?.email === email);
    setUser(filteredUser[0]);
  }, [document, email]);

  let [progress, setProgress] = useState(0);

  useEffect(() => {
    if (user?.availability?.attendBootcamp) {
      setProgress(Math.round((5 / 5) * 100));
      return;
    }
    if (
      user?.environmentalImpact?.environmentalGoal &&
      user?.environmentalImpact?.stakeholders?.length &&
      user?.environmentalImpact?.workImpact
    ) {
      setProgress(Math.round((4 / 5) * 100));
      return;
    }
    if (
      user?.Solution?.businessModel &&
      user?.Solution?.businessModel &&
      user?.Solution?.focusArea &&
      user?.Solution?.keyPartner &&
      user?.Solution?.pitchDeck &&
      user?.Solution?.problemSolution &&
      user?.Solution?.revenueModel &&
      user?.Solution?.solution &&
      user?.Solution?.stage
    ) {
      setProgress(Math.round((3 / 5) * 100));
      return;
    }
    if (
      user?.applicantProfile?.aboutTeam &&
      user?.applicantProfile?.acceleratorsParticipate &&
      user?.applicantProfile?.enterpriseFounded &&
      user?.applicantProfile?.enterpriseHeadquartered &&
      user?.applicantProfile?.enterpriseInvestment &&
      user?.applicantProfile?.enterpriseLocation &&
      user?.applicantProfile?.enterpriseName &&
      user?.applicantProfile?.enterprisePeople &&
      user?.applicantProfile?.enterpriseRegistered &&
      user?.applicantProfile?.role
    ) {
      setProgress(Math.round((2 / 5) * 100));
      return;
    }
    if (
      user?.basicInfo?.fullName &&
      user?.basicInfo?.age &&
      user?.basicInfo?.gender &&
      user?.basicInfo?.phoneNumber
    ) {
      setProgress(Math.round((1 / 5) * 100));
      return;
    }
  }, [user]);

  return (
    <div className="max-w-5xl mx-auto bg-white p-10 rounded-md mb-8">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="w-full md:w-2/3">
          <h2 className="font-bold pb-2">OVERALL APPLICATION PROGRESS</h2>
          <p className="font-ruda pb-2">
            Your progress will show here as you complete the application
            modules. Keep up the good work!
          </p>
          <p className="font-bold">DEADLINE : November 20, 2023</p>
        </div>
        <div>
          <div className="bg-[#EAEAEA] p-2 w-20 h-20 rounded-full">
            <div className="bg-white p-2 w-full h-full rounded-full flex justify-center items-center font-bold">
              {progress}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
